import { Delete, GetOne, GetSome, Post, PostFormData, PostOneNew, Put } from "@/legacy/ApiHttp";
import { AgentMassUpdateReq, ResCreateMulti, ResCreate, ResDelete, ResGetOne, ResGetSome, ResUpdate, TicketMassUpdateReq, UpsertImageReq, UserMassUpdateReq } from "@/legacy/ApiResponse";
import { Entities } from "@shared/Entities/Entities";
import { Agent, Attachment, Setting } from "@shared/Entities/EntityTypes";

// Auth calls
/** @deprecated */
export async function ApiGetMe(): Promise<ResGetOne<Agent>> {
	return GetOne<Agent>("agents/me");
}

// EntityViewer calls

/**
 * @deprecated
 */
export async function ApiCreateEntity<T>(entityType: Entities, data: T): Promise<ResCreate<T>> {
	const endpoint = getEntityEndpoint(entityType);

	return PostOneNew<T>(endpoint, data);
}

/**
 * @deprecated
 */
export async function ApiUpdateEntity<T>(entityType: Entities, id: number, data: any): Promise<ResUpdate<T>> {
	const endpoint = getEntityEndpoint(entityType);

	return Put<T>(endpoint + "/" + id, data);
}

/**
 * @deprecated
 */
export async function ApiDeleteEntity(entityType: Entities, id: number): Promise<ResDelete> {
	const endpoint = getEntityEndpoint(entityType);

	return Delete(endpoint + "/" + id);
}

/**
 * @deprecated
 */
export async function ApiDeleteEntities(entityType: Entities, ids: number[]): Promise<ResCreateMulti<null>> {
	const endpoint = getEntityEndpoint(entityType);

	return Put(endpoint + "/bulk_delete", ids);
}

// Typed calls

/** @deprecated */
export async function ApiMassUpdateAgents(req: AgentMassUpdateReq): Promise<ResCreateMulti<null>> {
	return Put("agents/mass_update", req);
}

/** @deprecated */
export async function ApiMassUpdateTickets(req: TicketMassUpdateReq): Promise<ResCreateMulti<null>> {
	return Put("tickets/mass_update", req);
}

/** @deprecated */
export async function ApiMassUpdateUsers(req: UserMassUpdateReq): Promise<ResCreateMulti<null>> {
	return Put("users/mass_update", req);
}

/** @deprecated */
export async function ApiUploadImageAttachment(dataBase64: string, mimeType: string): Promise<ResCreateMulti<string>> {
	const obj = { dataBase64: dataBase64, mimeType: mimeType };
	return Post<string>("attachments/image", [obj]);
}

/** @deprecated */
export async function ApiGetSettings(keyParentParam?: object): Promise<ResGetSome<Setting>> {
	return GetSome<Setting>("settings", keyParentParam);
}

/** @deprecated */
export async function ApiUpdateSettings(data: Setting[]): Promise<ResCreateMulti<Setting>> {
	return Post<Setting>("settings", data);
}

/** @deprecated */
export async function ApiUpsertSettingsLogo(fileName: string | undefined, dataBase64: string, mimeType: string): Promise<ResCreate<string>> {

	const req: UpsertImageReq = {
		fileName,
		dataBase64,
		mimeType
	};
	return Post<string>("settings/logo", [req], {}) as Promise<ResCreate<string>>;
}

/** @deprecated */
export async function ApiUpsertSettingsFavicon(fileName: string | undefined, dataBase64: string, mimeType: string): Promise<ResCreate<string>> {

	const req: UpsertImageReq = {
		fileName,
		dataBase64,
		mimeType
	};
	return Post<string>("settings/favicon", [req], {}) as Promise<ResCreate<string>>;
}

/** @deprecated */
export async function ApiUpsertSettingsBanner(fileName: string | undefined, dataBase64: string, mimeType: string): Promise<ResCreate<string>> {
	const req: UpsertImageReq = {
		fileName,
		dataBase64,
		mimeType
	};

	return Post<string>("settings/banner", [req], {}) as Promise<ResCreate<string>>;
}

function getEntityEndpoint(entityType: Entities): string {
	switch (entityType) {
		case Entities.ACTION:
			return "actions";
		case Entities.AUTOMATION:
			return "automations";
		case Entities.AGENT:
			return "agents";
		case Entities.CANNEDREPLY:
			return "cannedreplies";
		case Entities.CATEGORY:
			return "categories";
		case Entities.COMPANY:
			return "companies";
		case Entities.COMPANY_NOTE:
			return "company_notes";
		case Entities.CHANNELSEMAIL:
			return "channels_email";
		case Entities.EMAILNOTIFICATION:
			return "emailnotifications";
		case Entities.KB:
			return "kb";
		case Entities.KBARTICLE:
			return "kbarticles";
		case Entities.KBFOLDER:
			return "kbsections";
		case Entities.SLA:
			return "slas";
		case Entities.TEAM:
			return "teams";
		case Entities.TICKET:
			return "tickets";
		case Entities.USER:
			return "users";
		case Entities.VIEW:
			return "views";
		case Entities.ATTACHMENT:
			return "attachments";

		// Plugins

	}
}
