import { ReactNode, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BrushOutlined, CategoryOutlined, CodeOutlined, ContentCopyOutlined, ForwardToInboxOutlined, HeadsetMicOutlined, InboxOutlined, NotificationsOutlined, PeopleOutlined, PersonOutlineOutlined, PublicOutlined, ScheduleOutlined, SettingsSuggestOutlined, SmartToyOutlined, SystemUpdateAltOutlined, TimerOutlined } from "@mui/icons-material";
import { Button, Paper, Stack } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { ImportsModal } from "../../Components/Utility/Modals/ImportsModal";
import { useAppSelector } from "../../Store/hooks";
import { SettingInt } from "@shared/Enums/SettingEnums";

export default function ConfigMenu() {
	document.title = "Config | GoDesk";

	const [isOpen, setIsOpen] = useState(false);

	const { cache } = useCache();
	const godeskDbVersion = cache.getSettingInt(SettingInt.DB_VERSION);

	const isAdmin = useAppSelector(state => state.loggedInAgent.agent)?.isAdmin ?? false;

	return (
		<div className="max-w-3xl m-auto px-5 pb-10">
			<div className="text-2xl my-5 ml-2.5">Config</div>

			<Stack spacing={3}>
				<MenuGroup title="Manage">
					<MenuItem icon={<PersonOutlineOutlined fontSize="large" />} title="Agents" linkPath="/config/manage/agents" />
					<MenuItem icon={<ContentCopyOutlined fontSize="large" />} title="Canned Replies" linkPath="/config/manage/cannedreplies" />
					<MenuItem icon={<CategoryOutlined fontSize="large" />} title="Categories" linkPath="/config/manage/categories" />
					<MenuItem icon={<TimerOutlined fontSize="large" />} title="SLAs" linkPath="/config/manage/slas" />
					<MenuItem icon={<PeopleOutlined fontSize="large" />} title="Teams" linkPath="/config/manage/teams" />
					<MenuItem icon={<NotificationsOutlined fontSize="large" />} title="Notifications" linkPath="/config/notifications" />
				</MenuGroup>

				{isAdmin &&
					<>
						<MenuGroup title="Email">
							<MenuItem icon={<InboxOutlined fontSize="large" />} title="Mailboxes" linkPath="/config/manage/channels/email" />
							<MenuItem icon={<ForwardToInboxOutlined fontSize="large" />} title="Automated Emails" linkPath="/config/email/notifications" />
						</MenuGroup>

						<MenuGroup title="Settings">
							<MenuItem icon={<HeadsetMicOutlined fontSize="large" />} title="Help Desk" linkPath="/config/helpdesk" />
							<MenuItem icon={<BrushOutlined fontSize="large" />} title="Branding" linkPath="/config/branding" />
							<MenuItem icon={<PublicOutlined fontSize="large" />} title="CNAME" linkPath="/config/cname" />
							{/* <ConfigMenuItem icon={<MdAccountCircle />} title="Account" linkPath="/config/manage/account" />*/}
							{/* <ConfigMenuItem icon={<FaMoneyBillWave />} title="Billing" linkPath="/config/manage/billing" />*/}
							<MenuItem icon={<SettingsSuggestOutlined fontSize="large" />} title="Automations" linkPath="/config/automations" />
							<MenuItem icon={<ScheduleOutlined fontSize="large" />} title="Working Hours" linkPath="/config/workday" />
							<MenuItem icon={<SystemUpdateAltOutlined fontSize="large" />} title="Imports" onClick={() => setIsOpen(true)} />
							<MenuItem icon={<CodeOutlined fontSize="large" />} title="Api Docs" onClick={() => window.open("/api-docs", "_blank")} />
						</MenuGroup>

						<MenuGroup title="AI Tools">
							<MenuItem
								className="!bg-[#EDD7FA] !border-[#C523FE] !border !text-[#8C02CD] hover:!bg-[#dfb7f6]"
								icon={<SmartToyOutlined fontSize="large" color="inherit" />}
								title="Assistant"
								linkPath="/config/ai/assistant"
							/>
						</MenuGroup>
					</>}

				<Paper className="p-3">
					GoDesk Version: {godeskDbVersion}
				</Paper>
			</Stack>

			<ImportsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
}

interface MenuGroupProps {
	title: string;
	children?: ReactNode;
}

function MenuGroup(props: MenuGroupProps) {
	return (
		<Paper className="p-4">
			<div className="text-lg font-medium mb-3">{props.title}</div>
			<div className="flex flex-wrap gap-3">
				{props.children}
			</div>
		</Paper>
	);
}

interface MenuItemProps {
	icon: JSX.Element;
	title: string;
	linkPath?: string;
	onClick?(): void;
	className?: string;
}

function MenuItem(props: MenuItemProps) {
	const navigate = useNavigate();

	function onClick() {
		if (props.onClick != null) {
			props.onClick();
		} else if (props.linkPath != null) {
			navigate(props.linkPath);
		}
	}

	return (
		<Button
			variant="outlined"
			color="neutral"
			sx={{ borderColor: "neutral.main", fontWeight: "400", padding: 0 }}
			className={"w-28 h-28 text-center " + props.className}
			onClick={onClick}
		>
			<div>
				<div className="
				"
				>
					{props.icon}
				</div>
				<div className="">
					{props.title}
				</div>
			</div>
		</Button>
	);
}
