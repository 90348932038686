import { useNavigate } from "react-router-dom";
import { Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { useAgent } from "@/Hooks/useAgent";
import { DarkmodeToggle } from "@/Components/Header/AgentMenu/DarkmodeToggle";

export function AgentMenu() {
	const agent = useAgent();
	const navigate = useNavigate();

	const popupState = usePopupState({ variant: "popover" });

	function navigateAndClose(url: string) {
		navigate(url);
		popupState.close();
	}

	if (agent == null) {
		return;
	}

	return (
		<>
			<Tooltip title={agent.name}>
				<button {...bindTrigger(popupState)}>
					<AgentAvatar agent={agent} widthPx={40} />
				</button>
			</Tooltip>

			<Menu {...bindMenu(popupState)}>
				<MenuItem onClick={() => navigateAndClose("/config/manage/agents/" + agent.id)}>
					Profile settings
				</MenuItem>

				{process.env.NODE_ENV == "development" &&
					<>
						<Divider />

						<MenuItem onClick={() => navigateAndClose("/design")}>
							Design
						</MenuItem>

						<Divider />

						<DarkmodeToggle />
					</>}

				<Divider />

				<MenuItem onClick={() => navigateAndClose("/logout")}>
					Log out
				</MenuItem>
			</Menu>
		</>
	);
}
