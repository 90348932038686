/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * GoDesk API
 * Beta release of the API documentation. All endpoints and parameters are subject to change.
 * OpenAPI spec version: 0.1
 */
import {
	useMutation,
	useQuery
} from "@tanstack/react-query";
import type {
	DefinedInitialDataOptions,
	DefinedUseQueryResult,
	MutationFunction,
	QueryFunction,
	QueryKey,
	UndefinedInitialDataOptions,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult
} from "@tanstack/react-query";
import type {
	Action,
	ActivateCnameParams,
	Agent,
	AgentInvite,
	AgentInviteRedeem,
	AgentInviteReq,
	AgentMassUpdateReq,
	Attachment,
	AttachmentUpload,
	Automation,
	BillingStatus,
	Cache,
	CannedReply,
	Category,
	ChannelsEmail,
	CheckTokenIsValidParams,
	CollisionNote,
	Company,
	CompanyNote,
	CreatePasswordResetTokenParams,
	CreateUsersBody,
	Email,
	EmailCheckReq,
	EmailNotification,
	EntitySearchParams,
	GetAllActionsParams,
	GetAllAgentsParams,
	GetAllAttachmentsParams,
	GetAllAutomationsParams,
	GetAllCannedRepliesParams,
	GetAllCategoriesParams,
	GetAllCompaniesParams,
	GetAllCompanyNotesParams,
	GetAllEmailChannelsParams,
	GetAllEmailNotificationsParams,
	GetAllKBArticlesParams,
	GetAllKBSectionsParams,
	GetAllKnowledgeBasesParams,
	GetAllLayoutsParams,
	GetAllSLAsParams,
	GetAllSettingsParams,
	GetAllTeamsParams,
	GetAllTicketsParams,
	GetAllUsersParams,
	GetAllViewsParams,
	GetBusiestTeamReportParams,
	GetCnameChangeStatus200,
	GetDashboardReportParams,
	GetLoggedInUser200,
	GetMissedSearchResultsReport200,
	GetMissedSearchResultsReportParams,
	GetMyNotificationsParams,
	GetSettingsFromKeysParams,
	GetTicketsByCategoryReportParams,
	GetTicketsByPriorityReportParams,
	GetTicketsByStatusReportParams,
	GetTicketsPerCompanyReportParams,
	GetTicketsPerUserReportParams,
	GetTotalTicketRepliesReportParams,
	GlobalSearch,
	GlobalSearchParams,
	Kb,
	KbArticle,
	KbArticleContent,
	KbPublicSearchParams,
	KbSection,
	Layout,
	MergeTicketsReq,
	MetricEvent,
	MoveAllTicketsReq,
	MultiUserInvite,
	PasswordDTO,
	PayloadAction,
	PayloadAgent,
	PayloadAttachment,
	PayloadAutomation,
	PayloadBoolean,
	PayloadCannedReply,
	PayloadCategory,
	PayloadChannelsEmail,
	PayloadCollectionAction,
	PayloadCollectionAgent,
	PayloadCollectionAttachment,
	PayloadCollectionAutomation,
	PayloadCollectionCannedReply,
	PayloadCollectionCategory,
	PayloadCollectionChannelsEmail,
	PayloadCollectionCompany,
	PayloadCollectionCompanyNote,
	PayloadCollectionEmailNotification,
	PayloadCollectionFeedItem,
	PayloadCollectionKb,
	PayloadCollectionKbArticle,
	PayloadCollectionKbSection,
	PayloadCollectionLayout,
	PayloadCollectionNotification,
	PayloadCollectionSetting,
	PayloadCollectionSla,
	PayloadCollectionTeam,
	PayloadCollectionTicket,
	PayloadCollectionUser,
	PayloadCollectionView,
	PayloadCompany,
	PayloadCompanyNote,
	PayloadEmailNotification,
	PayloadGlobalSearch,
	PayloadKb,
	PayloadKbArticle,
	PayloadKbArticleContent,
	PayloadKbSection,
	PayloadSetting,
	PayloadString,
	PayloadTeam,
	PayloadTicket,
	PayloadUser,
	PayloadView,
	PayloadVoid,
	PortalEmailChangeRedemptionReq,
	PortalEmailUpdateReq,
	PushSubscriptionDTO,
	RedeemPasswordResetTokenParams,
	RemoveAllCollisionNotesParams,
	ReportDashboard,
	RevokeAgentInviteParams,
	SalablePlan,
	Setting,
	Sla,
	SseEmitter,
	Team,
	Ticket,
	TicketCounts,
	TicketExportReq,
	TicketList,
	TicketMassUpdateReq,
	TinymceImageUpload,
	UpdateUsersBody,
	UploadMultiAttachmentParams,
	UpsertImageReq,
	UserInvite,
	UserInviteRedeem,
	UserMassUpdateReq,
	View,
	VoteParams
} from "./genApi.schemas";
import { customInstance } from "./custom-instance";
import type { ErrorType, BodyType } from "./custom-instance";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
	T,
>() => T extends Y ? 1 : 2
	? A
	: B;

type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<
		{ [Q in P]: T[P] },
		{ -readonly [Q in P]: T[P] },
		P
	>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
	[P in keyof Writable<T>]: T[P] extends object
		? NonReadonly<NonNullable<T[P]>>
		: T[P];
} : DistributeReadOnlyOverUnions<T>;



/**
 * @summary Get all
 */
export const getAllActions = (
	params?: GetAllActionsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionAction>(
		{ url: "/api/actions",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllActionsQueryKey = (params?: GetAllActionsParams) => {
	return ["/api/actions", ...(params ? [params] : [])] as const;
};

    
export const getGetAllActionsQueryOptions = <TData = Awaited<ReturnType<typeof getAllActions>>, TError = ErrorType<unknown>>(params?: GetAllActionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllActionsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllActions>>> = ({ signal }) => getAllActions(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllActionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllActions>>>;
export type GetAllActionsQueryError = ErrorType<unknown>;


export function useGetAllActions<TData = Awaited<ReturnType<typeof getAllActions>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllActionsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllActions>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllActions<TData = Awaited<ReturnType<typeof getAllActions>>, TError = ErrorType<unknown>>(
	params?: GetAllActionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllActions>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllActions<TData = Awaited<ReturnType<typeof getAllActions>>, TError = ErrorType<unknown>>(
	params?: GetAllActionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllActions<TData = Awaited<ReturnType<typeof getAllActions>>, TError = ErrorType<unknown>>(
	params?: GetAllActionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllActions>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllActionsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsActions = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/actions", method: "HEAD", signal
		}
	);
};
  


export const getExistsActionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsActions>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsActions>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsActions>>, void> = () => {
          

		return existsActions();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsActionsMutationResult = NonNullable<Awaited<ReturnType<typeof existsActions>>>;
    
export type ExistsActionsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsActions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsActions>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsActions>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsActionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createActions = (
	action: BodyType<NonReadonly<Action>>
) => {
      
      
	return customInstance<PayloadAction>(
		{ url: "/api/actions",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: action
		}
	);
};
  


export const getCreateActionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createActions>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createActions>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createActions>>, { data: BodyType<NonReadonly<Action>> }> = (props) => {
		const { data } = props ?? {};

		return createActions(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateActionsMutationResult = NonNullable<Awaited<ReturnType<typeof createActions>>>;
export type CreateActionsMutationBody = BodyType<NonReadonly<Action>>;
export type CreateActionsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateActions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createActions>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createActions>>,
	TError,
	{ data: BodyType<NonReadonly<Action>> },
	TContext
> => {

	const mutationOptions = getCreateActionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteActions = (
	bulkDeleteActionsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/actions/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteActionsBody
		}
	);
};
  


export const getBulkDeleteActionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteActions>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteActions>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteActions>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteActions(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteActionsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteActions>>>;
export type BulkDeleteActionsMutationBody = BodyType<number[]>;
export type BulkDeleteActionsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteActions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteActions>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteActions>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteActionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteActions = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/actions/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteActionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteActions>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteActions>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteActions>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteActions(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteActionsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteActions>>>;
    
export type DeleteActionsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteActions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteActions>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteActions>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteActionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneActions = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadAction>(
		{ url: `/api/actions/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneActionsQueryKey = (id: number) => {
	return [`/api/actions/${id}`] as const;
};

    
export const getGetOneActionsQueryOptions = <TData = Awaited<ReturnType<typeof getOneActions>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneActionsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneActions>>> = ({ signal }) => getOneActions(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneActionsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneActions>>>;
export type GetOneActionsQueryError = ErrorType<unknown>;


export function useGetOneActions<TData = Awaited<ReturnType<typeof getOneActions>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneActions>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneActions<TData = Awaited<ReturnType<typeof getOneActions>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneActions>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneActions<TData = Awaited<ReturnType<typeof getOneActions>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneActions<TData = Awaited<ReturnType<typeof getOneActions>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneActions>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneActionsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateActions = (
	id: number,
	action: BodyType<NonReadonly<Action>>
) => {
      
      
	return customInstance<PayloadAction>(
		{ url: `/api/actions/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: action
		}
	);
};
  


export const getUpdateActionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateActions>>, TError, { id: number;data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateActions>>, TError, { id: number;data: BodyType<NonReadonly<Action>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateActions>>, { id: number;data: BodyType<NonReadonly<Action>> }> = (props) => {
		const { id, data } = props ?? {};

		return updateActions(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateActionsMutationResult = NonNullable<Awaited<ReturnType<typeof updateActions>>>;
export type UpdateActionsMutationBody = BodyType<NonReadonly<Action>>;
export type UpdateActionsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateActions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateActions>>, TError, { id: number;data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateActions>>,
	TError,
	{ id: number;data: BodyType<NonReadonly<Action>> },
	TContext
> => {

	const mutationOptions = getUpdateActionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get full action note
 */
export const getFullActionNote = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<string>(
		{ url: `/api/actions/${encodeURIComponent(String(id))}/full_note`, method: "GET", signal
		}
	);
};
  

export const getGetFullActionNoteQueryKey = (id: number) => {
	return [`/api/actions/${id}/full_note`] as const;
};

    
export const getGetFullActionNoteQueryOptions = <TData = Awaited<ReturnType<typeof getFullActionNote>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetFullActionNoteQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getFullActionNote>>> = ({ signal }) => getFullActionNote(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData> & { queryKey: QueryKey };
};

export type GetFullActionNoteQueryResult = NonNullable<Awaited<ReturnType<typeof getFullActionNote>>>;
export type GetFullActionNoteQueryError = ErrorType<unknown>;


export function useGetFullActionNote<TData = Awaited<ReturnType<typeof getFullActionNote>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getFullActionNote>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFullActionNote<TData = Awaited<ReturnType<typeof getFullActionNote>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getFullActionNote>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFullActionNote<TData = Awaited<ReturnType<typeof getFullActionNote>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get full action note
 */

export function useGetFullActionNote<TData = Awaited<ReturnType<typeof getFullActionNote>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFullActionNote>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetFullActionNoteQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Add collision note
 */
export const addCollisionNote = (
	collisionNote: BodyType<CollisionNote>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agent_collisions",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: collisionNote
		}
	);
};
  


export const getAddCollisionNoteMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof addCollisionNote>>, TError, { data: BodyType<CollisionNote> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof addCollisionNote>>, TError, { data: BodyType<CollisionNote> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCollisionNote>>, { data: BodyType<CollisionNote> }> = (props) => {
		const { data } = props ?? {};

		return addCollisionNote(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type AddCollisionNoteMutationResult = NonNullable<Awaited<ReturnType<typeof addCollisionNote>>>;
export type AddCollisionNoteMutationBody = BodyType<CollisionNote>;
export type AddCollisionNoteMutationError = ErrorType<unknown>;

/**
 * @summary Add collision note
 */
export const useAddCollisionNote = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof addCollisionNote>>, TError, { data: BodyType<CollisionNote> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof addCollisionNote>>,
	TError,
	{ data: BodyType<CollisionNote> },
	TContext
> => {

	const mutationOptions = getAddCollisionNoteMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * Removes all of an agent's collision notes for a specific entity. E.g. ticket #1.
 * @summary Remove all collision notes
 */
export const removeAllCollisionNotes = (
	entity: "TICKET",
	id: number,
	params?: RemoveAllCollisionNotesParams
) => {
      
      
	return customInstance<void>(
		{ url: `/api/agent_collisions/remove_all/${encodeURIComponent(String(entity))}/${encodeURIComponent(String(id))}`,
			method: "POST",
			params
		}
	);
};
  


export const getRemoveAllCollisionNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeAllCollisionNotes>>, TError, { entity: "TICKET";id: number;params?: RemoveAllCollisionNotesParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof removeAllCollisionNotes>>, TError, { entity: "TICKET";id: number;params?: RemoveAllCollisionNotesParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeAllCollisionNotes>>, { entity: "TICKET";id: number;params?: RemoveAllCollisionNotesParams }> = (props) => {
		const { entity, id, params } = props ?? {};

		return removeAllCollisionNotes(entity, id, params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RemoveAllCollisionNotesMutationResult = NonNullable<Awaited<ReturnType<typeof removeAllCollisionNotes>>>;
    
export type RemoveAllCollisionNotesMutationError = ErrorType<unknown>;

/**
 * @summary Remove all collision notes
 */
export const useRemoveAllCollisionNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeAllCollisionNotes>>, TError, { entity: "TICKET";id: number;params?: RemoveAllCollisionNotesParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof removeAllCollisionNotes>>,
	TError,
	{ entity: "TICKET";id: number;params?: RemoveAllCollisionNotesParams },
	TContext
> => {

	const mutationOptions = getRemoveAllCollisionNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * Gets every collision note for an entity type. E.g. tickets.
 * @summary Get all collision notes
 */
export const getAllCollisionNotes = (
	entity: "TICKET",
	signal?: AbortSignal
) => {
      
      
	return customInstance<CollisionNote[]>(
		{ url: `/api/agent_collisions/${encodeURIComponent(String(entity))}/all`, method: "GET", signal
		}
	);
};
  

export const getGetAllCollisionNotesQueryKey = (entity: "TICKET") => {
	return [`/api/agent_collisions/${entity}/all`] as const;
};

    
export const getGetAllCollisionNotesQueryOptions = <TData = Awaited<ReturnType<typeof getAllCollisionNotes>>, TError = ErrorType<unknown>>(entity: "TICKET", options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllCollisionNotesQueryKey(entity);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCollisionNotes>>> = ({ signal }) => getAllCollisionNotes(entity, signal);

      

	return { queryKey, queryFn, enabled: Boolean(entity), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllCollisionNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCollisionNotes>>>;
export type GetAllCollisionNotesQueryError = ErrorType<unknown>;


export function useGetAllCollisionNotes<TData = Awaited<ReturnType<typeof getAllCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET", options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCollisionNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCollisionNotes<TData = Awaited<ReturnType<typeof getAllCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET", options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCollisionNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCollisionNotes<TData = Awaited<ReturnType<typeof getAllCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET", options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all collision notes
 */

export function useGetAllCollisionNotes<TData = Awaited<ReturnType<typeof getAllCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET", options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCollisionNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllCollisionNotesQueryOptions(entity, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * Gets collision notes for a specific entity. E.g. ticket #1.
 * @summary Get entity collision notes
 */
export const getCollisionNotes = (
	entity: "TICKET",
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<CollisionNote[]>(
		{ url: `/api/agent_collisions/${encodeURIComponent(String(entity))}/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetCollisionNotesQueryKey = (entity: "TICKET",
	id: number) => {
	return [`/api/agent_collisions/${entity}/${id}`] as const;
};

    
export const getGetCollisionNotesQueryOptions = <TData = Awaited<ReturnType<typeof getCollisionNotes>>, TError = ErrorType<unknown>>(entity: "TICKET",
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCollisionNotesQueryKey(entity, id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollisionNotes>>> = ({ signal }) => getCollisionNotes(entity, id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(entity && id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData> & { queryKey: QueryKey };
};

export type GetCollisionNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getCollisionNotes>>>;
export type GetCollisionNotesQueryError = ErrorType<unknown>;


export function useGetCollisionNotes<TData = Awaited<ReturnType<typeof getCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET",
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCollisionNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCollisionNotes<TData = Awaited<ReturnType<typeof getCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET",
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCollisionNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCollisionNotes<TData = Awaited<ReturnType<typeof getCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET",
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get entity collision notes
 */

export function useGetCollisionNotes<TData = Awaited<ReturnType<typeof getCollisionNotes>>, TError = ErrorType<unknown>>(
	entity: "TICKET",
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollisionNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetCollisionNotesQueryOptions(entity, id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Revoke agent invite
 */
export const revokeAgentInvite = (
	params: RevokeAgentInviteParams
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agent_invites",
			method: "DELETE",
			params
		}
	);
};
  


export const getRevokeAgentInviteMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeAgentInvite>>, TError, { params: RevokeAgentInviteParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof revokeAgentInvite>>, TError, { params: RevokeAgentInviteParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeAgentInvite>>, { params: RevokeAgentInviteParams }> = (props) => {
		const { params } = props ?? {};

		return revokeAgentInvite(params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RevokeAgentInviteMutationResult = NonNullable<Awaited<ReturnType<typeof revokeAgentInvite>>>;
    
export type RevokeAgentInviteMutationError = ErrorType<unknown>;

/**
 * @summary Revoke agent invite
 */
export const useRevokeAgentInvite = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeAgentInvite>>, TError, { params: RevokeAgentInviteParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof revokeAgentInvite>>,
	TError,
	{ params: RevokeAgentInviteParams },
	TContext
> => {

	const mutationOptions = getRevokeAgentInviteMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Invite agents
 */
export const inviteAgents = (
	agentInviteReq: BodyType<AgentInviteReq[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agent_invites",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: agentInviteReq
		}
	);
};
  


export const getInviteAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteAgents>>, TError, { data: BodyType<AgentInviteReq[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof inviteAgents>>, TError, { data: BodyType<AgentInviteReq[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteAgents>>, { data: BodyType<AgentInviteReq[]> }> = (props) => {
		const { data } = props ?? {};

		return inviteAgents(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type InviteAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof inviteAgents>>>;
export type InviteAgentsMutationBody = BodyType<AgentInviteReq[]>;
export type InviteAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Invite agents
 */
export const useInviteAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteAgents>>, TError, { data: BodyType<AgentInviteReq[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof inviteAgents>>,
	TError,
	{ data: BodyType<AgentInviteReq[]> },
	TContext
> => {

	const mutationOptions = getInviteAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get pending agent invites
 */
export const getPendingAgentInvites = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<AgentInvite[]>(
		{ url: "/api/agent_invites/pending", method: "GET", signal
		}
	);
};
  

export const getGetPendingAgentInvitesQueryKey = () => {
	return ["/api/agent_invites/pending"] as const;
};

    
export const getGetPendingAgentInvitesQueryOptions = <TData = Awaited<ReturnType<typeof getPendingAgentInvites>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetPendingAgentInvitesQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getPendingAgentInvites>>> = ({ signal }) => getPendingAgentInvites(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData> & { queryKey: QueryKey };
};

export type GetPendingAgentInvitesQueryResult = NonNullable<Awaited<ReturnType<typeof getPendingAgentInvites>>>;
export type GetPendingAgentInvitesQueryError = ErrorType<unknown>;


export function useGetPendingAgentInvites<TData = Awaited<ReturnType<typeof getPendingAgentInvites>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getPendingAgentInvites>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPendingAgentInvites<TData = Awaited<ReturnType<typeof getPendingAgentInvites>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getPendingAgentInvites>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPendingAgentInvites<TData = Awaited<ReturnType<typeof getPendingAgentInvites>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get pending agent invites
 */

export function useGetPendingAgentInvites<TData = Awaited<ReturnType<typeof getPendingAgentInvites>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPendingAgentInvites>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetPendingAgentInvitesQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Redeem agent invite
 */
export const redeemAgentInvite = (
	agentInviteRedeem: BodyType<AgentInviteRedeem>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agent_invites/redeem",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: agentInviteRedeem
		}
	);
};
  


export const getRedeemAgentInviteMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof redeemAgentInvite>>, TError, { data: BodyType<AgentInviteRedeem> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof redeemAgentInvite>>, TError, { data: BodyType<AgentInviteRedeem> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof redeemAgentInvite>>, { data: BodyType<AgentInviteRedeem> }> = (props) => {
		const { data } = props ?? {};

		return redeemAgentInvite(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RedeemAgentInviteMutationResult = NonNullable<Awaited<ReturnType<typeof redeemAgentInvite>>>;
export type RedeemAgentInviteMutationBody = BodyType<AgentInviteRedeem>;
export type RedeemAgentInviteMutationError = ErrorType<unknown>;

/**
 * @summary Redeem agent invite
 */
export const useRedeemAgentInvite = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof redeemAgentInvite>>, TError, { data: BodyType<AgentInviteRedeem> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof redeemAgentInvite>>,
	TError,
	{ data: BodyType<AgentInviteRedeem> },
	TContext
> => {

	const mutationOptions = getRedeemAgentInviteMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllAgents = (
	params?: GetAllAgentsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionAgent>(
		{ url: "/api/agents",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllAgentsQueryKey = (params?: GetAllAgentsParams) => {
	return ["/api/agents", ...(params ? [params] : [])] as const;
};

    
export const getGetAllAgentsQueryOptions = <TData = Awaited<ReturnType<typeof getAllAgents>>, TError = ErrorType<unknown>>(params?: GetAllAgentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllAgentsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAgents>>> = ({ signal }) => getAllAgents(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllAgentsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAgents>>>;
export type GetAllAgentsQueryError = ErrorType<unknown>;


export function useGetAllAgents<TData = Awaited<ReturnType<typeof getAllAgents>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllAgentsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAgents>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAgents<TData = Awaited<ReturnType<typeof getAllAgents>>, TError = ErrorType<unknown>>(
	params?: GetAllAgentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAgents>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAgents<TData = Awaited<ReturnType<typeof getAllAgents>>, TError = ErrorType<unknown>>(
	params?: GetAllAgentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllAgents<TData = Awaited<ReturnType<typeof getAllAgents>>, TError = ErrorType<unknown>>(
	params?: GetAllAgentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAgents>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllAgentsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsAgents = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agents", method: "HEAD", signal
		}
	);
};
  


export const getExistsAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAgents>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsAgents>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsAgents>>, void> = () => {
          

		return existsAgents();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof existsAgents>>>;
    
export type ExistsAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAgents>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsAgents>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createAgents = (
	agent: BodyType<Agent>
) => {
      
      
	return customInstance<PayloadAgent>(
		{ url: "/api/agents",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: agent
		}
	);
};
  


export const getCreateAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAgents>>, TError, { data: BodyType<Agent> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createAgents>>, TError, { data: BodyType<Agent> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAgents>>, { data: BodyType<Agent> }> = (props) => {
		const { data } = props ?? {};

		return createAgents(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof createAgents>>>;
export type CreateAgentsMutationBody = BodyType<Agent>;
export type CreateAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAgents>>, TError, { data: BodyType<Agent> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createAgents>>,
	TError,
	{ data: BodyType<Agent> },
	TContext
> => {

	const mutationOptions = getCreateAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteAgents = (
	bulkDeleteAgentsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agents/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteAgentsBody
		}
	);
};
  


export const getBulkDeleteAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAgents>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAgents>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteAgents>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteAgents(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteAgents>>>;
export type BulkDeleteAgentsMutationBody = BodyType<number[]>;
export type BulkDeleteAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAgents>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteAgents>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get the agent you're authenticated as.
 */
export const getLoggedInAgent = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<Agent>(
		{ url: "/api/agents/logged_in_agent", method: "GET", signal
		}
	);
};
  

export const getGetLoggedInAgentQueryKey = () => {
	return ["/api/agents/logged_in_agent"] as const;
};

    
export const getGetLoggedInAgentQueryOptions = <TData = Awaited<ReturnType<typeof getLoggedInAgent>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetLoggedInAgentQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoggedInAgent>>> = ({ signal }) => getLoggedInAgent(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData> & { queryKey: QueryKey };
};

export type GetLoggedInAgentQueryResult = NonNullable<Awaited<ReturnType<typeof getLoggedInAgent>>>;
export type GetLoggedInAgentQueryError = ErrorType<unknown>;


export function useGetLoggedInAgent<TData = Awaited<ReturnType<typeof getLoggedInAgent>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getLoggedInAgent>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedInAgent<TData = Awaited<ReturnType<typeof getLoggedInAgent>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getLoggedInAgent>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedInAgent<TData = Awaited<ReturnType<typeof getLoggedInAgent>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get the agent you're authenticated as.
 */

export function useGetLoggedInAgent<TData = Awaited<ReturnType<typeof getLoggedInAgent>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInAgent>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetLoggedInAgentQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const massUpdateAgents = (
	agentMassUpdateReq: BodyType<AgentMassUpdateReq>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/agents/mass_update",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: agentMassUpdateReq
		}
	);
};
  


export const getMassUpdateAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateAgents>>, TError, { data: BodyType<AgentMassUpdateReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof massUpdateAgents>>, TError, { data: BodyType<AgentMassUpdateReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof massUpdateAgents>>, { data: BodyType<AgentMassUpdateReq> }> = (props) => {
		const { data } = props ?? {};

		return massUpdateAgents(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type MassUpdateAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof massUpdateAgents>>>;
export type MassUpdateAgentsMutationBody = BodyType<AgentMassUpdateReq>;
export type MassUpdateAgentsMutationError = ErrorType<unknown>;

export const useMassUpdateAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateAgents>>, TError, { data: BodyType<AgentMassUpdateReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof massUpdateAgents>>,
	TError,
	{ data: BodyType<AgentMassUpdateReq> },
	TContext
> => {

	const mutationOptions = getMassUpdateAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getMeAsAgentOld = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadAgent>(
		{ url: "/api/agents/me", method: "GET", signal
		}
	);
};
  

export const getGetMeAsAgentOldQueryKey = () => {
	return ["/api/agents/me"] as const;
};

    
export const getGetMeAsAgentOldQueryOptions = <TData = Awaited<ReturnType<typeof getMeAsAgentOld>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMeAsAgentOldQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMeAsAgentOld>>> = ({ signal }) => getMeAsAgentOld(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData> & { queryKey: QueryKey };
};

export type GetMeAsAgentOldQueryResult = NonNullable<Awaited<ReturnType<typeof getMeAsAgentOld>>>;
export type GetMeAsAgentOldQueryError = ErrorType<unknown>;


export function useGetMeAsAgentOld<TData = Awaited<ReturnType<typeof getMeAsAgentOld>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMeAsAgentOld>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMeAsAgentOld<TData = Awaited<ReturnType<typeof getMeAsAgentOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMeAsAgentOld>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMeAsAgentOld<TData = Awaited<ReturnType<typeof getMeAsAgentOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMeAsAgentOld<TData = Awaited<ReturnType<typeof getMeAsAgentOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsAgentOld>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetMeAsAgentOldQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Delete
 */
export const deleteAgents = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/agents/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAgents>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAgents>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAgents>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteAgents(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgents>>>;
    
export type DeleteAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAgents>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteAgents>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneAgents = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadAgent>(
		{ url: `/api/agents/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneAgentsQueryKey = (id: number) => {
	return [`/api/agents/${id}`] as const;
};

    
export const getGetOneAgentsQueryOptions = <TData = Awaited<ReturnType<typeof getOneAgents>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneAgentsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneAgents>>> = ({ signal }) => getOneAgents(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneAgentsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneAgents>>>;
export type GetOneAgentsQueryError = ErrorType<unknown>;


export function useGetOneAgents<TData = Awaited<ReturnType<typeof getOneAgents>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAgents>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAgents<TData = Awaited<ReturnType<typeof getOneAgents>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAgents>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAgents<TData = Awaited<ReturnType<typeof getOneAgents>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneAgents<TData = Awaited<ReturnType<typeof getOneAgents>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAgents>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneAgentsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateAgents = (
	id: number,
	agent: BodyType<Agent>
) => {
      
      
	return customInstance<PayloadAgent>(
		{ url: `/api/agents/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: agent
		}
	);
};
  


export const getUpdateAgentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAgents>>, TError, { id: number;data: BodyType<Agent> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateAgents>>, TError, { id: number;data: BodyType<Agent> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAgents>>, { id: number;data: BodyType<Agent> }> = (props) => {
		const { id, data } = props ?? {};

		return updateAgents(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateAgentsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAgents>>>;
export type UpdateAgentsMutationBody = BodyType<Agent>;
export type UpdateAgentsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateAgents = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAgents>>, TError, { id: number;data: BodyType<Agent> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateAgents>>,
	TError,
	{ id: number;data: BodyType<Agent> },
	TContext
> => {

	const mutationOptions = getUpdateAgentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllAttachments = (
	params?: GetAllAttachmentsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionAttachment>(
		{ url: "/api/attachments",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllAttachmentsQueryKey = (params?: GetAllAttachmentsParams) => {
	return ["/api/attachments", ...(params ? [params] : [])] as const;
};

    
export const getGetAllAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof getAllAttachments>>, TError = ErrorType<unknown>>(params?: GetAllAttachmentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllAttachmentsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAttachments>>> = ({ signal }) => getAllAttachments(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAttachments>>>;
export type GetAllAttachmentsQueryError = ErrorType<unknown>;


export function useGetAllAttachments<TData = Awaited<ReturnType<typeof getAllAttachments>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllAttachmentsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAttachments>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAttachments<TData = Awaited<ReturnType<typeof getAllAttachments>>, TError = ErrorType<unknown>>(
	params?: GetAllAttachmentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAttachments>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAttachments<TData = Awaited<ReturnType<typeof getAllAttachments>>, TError = ErrorType<unknown>>(
	params?: GetAllAttachmentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllAttachments<TData = Awaited<ReturnType<typeof getAllAttachments>>, TError = ErrorType<unknown>>(
	params?: GetAllAttachmentsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAttachments>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllAttachmentsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsAttachments = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/attachments", method: "HEAD", signal
		}
	);
};
  


export const getExistsAttachmentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAttachments>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsAttachments>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsAttachments>>, void> = () => {
          

		return existsAttachments();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof existsAttachments>>>;
    
export type ExistsAttachmentsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsAttachments = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAttachments>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsAttachments>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsAttachmentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createAttachments = (
	attachment: BodyType<Attachment>
) => {
      
      
	return customInstance<PayloadAttachment>(
		{ url: "/api/attachments",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: attachment
		}
	);
};
  


export const getCreateAttachmentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAttachments>>, TError, { data: BodyType<Attachment> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createAttachments>>, TError, { data: BodyType<Attachment> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAttachments>>, { data: BodyType<Attachment> }> = (props) => {
		const { data } = props ?? {};

		return createAttachments(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof createAttachments>>>;
export type CreateAttachmentsMutationBody = BodyType<Attachment>;
export type CreateAttachmentsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateAttachments = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAttachments>>, TError, { data: BodyType<Attachment> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createAttachments>>,
	TError,
	{ data: BodyType<Attachment> },
	TContext
> => {

	const mutationOptions = getCreateAttachmentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteAttachments = (
	bulkDeleteAttachmentsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/attachments/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteAttachmentsBody
		}
	);
};
  


export const getBulkDeleteAttachmentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAttachments>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAttachments>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteAttachments>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteAttachments(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteAttachments>>>;
export type BulkDeleteAttachmentsMutationBody = BodyType<number[]>;
export type BulkDeleteAttachmentsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteAttachments = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAttachments>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteAttachments>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteAttachmentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const postImage = (
	tinymceImageUpload: BodyType<TinymceImageUpload[]>
) => {
      
      
	return customInstance<PayloadString>(
		{ url: "/api/attachments/image",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: tinymceImageUpload
		}
	);
};
  


export const getPostImageMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof postImage>>, TError, { data: BodyType<TinymceImageUpload[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof postImage>>, TError, { data: BodyType<TinymceImageUpload[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof postImage>>, { data: BodyType<TinymceImageUpload[]> }> = (props) => {
		const { data } = props ?? {};

		return postImage(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type PostImageMutationResult = NonNullable<Awaited<ReturnType<typeof postImage>>>;
export type PostImageMutationBody = BodyType<TinymceImageUpload[]>;
export type PostImageMutationError = ErrorType<unknown>;

export const usePostImage = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof postImage>>, TError, { data: BodyType<TinymceImageUpload[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof postImage>>,
	TError,
	{ data: BodyType<TinymceImageUpload[]> },
	TContext
> => {

	const mutationOptions = getPostImageMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const uploadAttachment = (
	attachmentUpload: BodyType<AttachmentUpload>
) => {
      
      
	return customInstance<PayloadAttachment>(
		{ url: "/api/attachments/single",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: attachmentUpload
		}
	);
};
  


export const getUploadAttachmentMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadAttachment>>, TError, { data: BodyType<AttachmentUpload> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof uploadAttachment>>, TError, { data: BodyType<AttachmentUpload> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadAttachment>>, { data: BodyType<AttachmentUpload> }> = (props) => {
		const { data } = props ?? {};

		return uploadAttachment(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UploadAttachmentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadAttachment>>>;
export type UploadAttachmentMutationBody = BodyType<AttachmentUpload>;
export type UploadAttachmentMutationError = ErrorType<unknown>;

export const useUploadAttachment = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadAttachment>>, TError, { data: BodyType<AttachmentUpload> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof uploadAttachment>>,
	TError,
	{ data: BodyType<AttachmentUpload> },
	TContext
> => {

	const mutationOptions = getUploadAttachmentMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const uploadMultiAttachment = (
	params: UploadMultiAttachmentParams
) => {
      
      
	return customInstance<PayloadCollectionAttachment>(
		{ url: "/api/attachments/upload",
			method: "POST",
			params
		}
	);
};
  


export const getUploadMultiAttachmentMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadMultiAttachment>>, TError, { params: UploadMultiAttachmentParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof uploadMultiAttachment>>, TError, { params: UploadMultiAttachmentParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadMultiAttachment>>, { params: UploadMultiAttachmentParams }> = (props) => {
		const { params } = props ?? {};

		return uploadMultiAttachment(params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UploadMultiAttachmentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadMultiAttachment>>>;
    
export type UploadMultiAttachmentMutationError = ErrorType<unknown>;

export const useUploadMultiAttachment = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof uploadMultiAttachment>>, TError, { params: UploadMultiAttachmentParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof uploadMultiAttachment>>,
	TError,
	{ params: UploadMultiAttachmentParams },
	TContext
> => {

	const mutationOptions = getUploadMultiAttachmentMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteAttachments = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/attachments/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteAttachmentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAttachments>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAttachments>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAttachments>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteAttachments(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAttachments>>>;
    
export type DeleteAttachmentsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteAttachments = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAttachments>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteAttachments>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteAttachmentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneAttachments = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadAttachment>(
		{ url: `/api/attachments/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneAttachmentsQueryKey = (id: number) => {
	return [`/api/attachments/${id}`] as const;
};

    
export const getGetOneAttachmentsQueryOptions = <TData = Awaited<ReturnType<typeof getOneAttachments>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneAttachmentsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneAttachments>>> = ({ signal }) => getOneAttachments(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneAttachments>>>;
export type GetOneAttachmentsQueryError = ErrorType<unknown>;


export function useGetOneAttachments<TData = Awaited<ReturnType<typeof getOneAttachments>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAttachments>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAttachments<TData = Awaited<ReturnType<typeof getOneAttachments>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAttachments>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAttachments<TData = Awaited<ReturnType<typeof getOneAttachments>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneAttachments<TData = Awaited<ReturnType<typeof getOneAttachments>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAttachments>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneAttachmentsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateAttachments = (
	id: number,
	attachment: BodyType<Attachment>
) => {
      
      
	return customInstance<PayloadAttachment>(
		{ url: `/api/attachments/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: attachment
		}
	);
};
  


export const getUpdateAttachmentsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAttachments>>, TError, { id: number;data: BodyType<Attachment> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateAttachments>>, TError, { id: number;data: BodyType<Attachment> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAttachments>>, { id: number;data: BodyType<Attachment> }> = (props) => {
		const { id, data } = props ?? {};

		return updateAttachments(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateAttachmentsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAttachments>>>;
export type UpdateAttachmentsMutationBody = BodyType<Attachment>;
export type UpdateAttachmentsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateAttachments = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAttachments>>, TError, { id: number;data: BodyType<Attachment> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateAttachments>>,
	TError,
	{ id: number;data: BodyType<Attachment> },
	TContext
> => {

	const mutationOptions = getUpdateAttachmentsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllAutomations = (
	params?: GetAllAutomationsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionAutomation>(
		{ url: "/api/automations",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllAutomationsQueryKey = (params?: GetAllAutomationsParams) => {
	return ["/api/automations", ...(params ? [params] : [])] as const;
};

    
export const getGetAllAutomationsQueryOptions = <TData = Awaited<ReturnType<typeof getAllAutomations>>, TError = ErrorType<unknown>>(params?: GetAllAutomationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllAutomationsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAutomations>>> = ({ signal }) => getAllAutomations(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllAutomationsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAutomations>>>;
export type GetAllAutomationsQueryError = ErrorType<unknown>;


export function useGetAllAutomations<TData = Awaited<ReturnType<typeof getAllAutomations>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllAutomationsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAutomations>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAutomations<TData = Awaited<ReturnType<typeof getAllAutomations>>, TError = ErrorType<unknown>>(
	params?: GetAllAutomationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllAutomations>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllAutomations<TData = Awaited<ReturnType<typeof getAllAutomations>>, TError = ErrorType<unknown>>(
	params?: GetAllAutomationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllAutomations<TData = Awaited<ReturnType<typeof getAllAutomations>>, TError = ErrorType<unknown>>(
	params?: GetAllAutomationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllAutomations>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllAutomationsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsAutomations = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/automations", method: "HEAD", signal
		}
	);
};
  


export const getExistsAutomationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAutomations>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsAutomations>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsAutomations>>, void> = () => {
          

		return existsAutomations();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsAutomationsMutationResult = NonNullable<Awaited<ReturnType<typeof existsAutomations>>>;
    
export type ExistsAutomationsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsAutomations = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsAutomations>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsAutomations>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsAutomationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createAutomations = (
	automation: BodyType<Automation>
) => {
      
      
	return customInstance<PayloadAutomation>(
		{ url: "/api/automations",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: automation
		}
	);
};
  


export const getCreateAutomationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAutomations>>, TError, { data: BodyType<Automation> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createAutomations>>, TError, { data: BodyType<Automation> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAutomations>>, { data: BodyType<Automation> }> = (props) => {
		const { data } = props ?? {};

		return createAutomations(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateAutomationsMutationResult = NonNullable<Awaited<ReturnType<typeof createAutomations>>>;
export type CreateAutomationsMutationBody = BodyType<Automation>;
export type CreateAutomationsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateAutomations = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAutomations>>, TError, { data: BodyType<Automation> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createAutomations>>,
	TError,
	{ data: BodyType<Automation> },
	TContext
> => {

	const mutationOptions = getCreateAutomationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteAutomations = (
	bulkDeleteAutomationsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/automations/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteAutomationsBody
		}
	);
};
  


export const getBulkDeleteAutomationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAutomations>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAutomations>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteAutomations>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteAutomations(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteAutomationsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteAutomations>>>;
export type BulkDeleteAutomationsMutationBody = BodyType<number[]>;
export type BulkDeleteAutomationsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteAutomations = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteAutomations>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteAutomations>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteAutomationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteAutomations = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/automations/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteAutomationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAutomations>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAutomations>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAutomations>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteAutomations(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteAutomationsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAutomations>>>;
    
export type DeleteAutomationsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteAutomations = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAutomations>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteAutomations>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteAutomationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneAutomations = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadAutomation>(
		{ url: `/api/automations/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneAutomationsQueryKey = (id: number) => {
	return [`/api/automations/${id}`] as const;
};

    
export const getGetOneAutomationsQueryOptions = <TData = Awaited<ReturnType<typeof getOneAutomations>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneAutomationsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneAutomations>>> = ({ signal }) => getOneAutomations(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneAutomationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneAutomations>>>;
export type GetOneAutomationsQueryError = ErrorType<unknown>;


export function useGetOneAutomations<TData = Awaited<ReturnType<typeof getOneAutomations>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAutomations>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAutomations<TData = Awaited<ReturnType<typeof getOneAutomations>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneAutomations>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneAutomations<TData = Awaited<ReturnType<typeof getOneAutomations>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneAutomations<TData = Awaited<ReturnType<typeof getOneAutomations>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneAutomations>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneAutomationsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateAutomations = (
	id: number,
	automation: BodyType<Automation>
) => {
      
      
	return customInstance<PayloadAutomation>(
		{ url: `/api/automations/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: automation
		}
	);
};
  


export const getUpdateAutomationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAutomations>>, TError, { id: number;data: BodyType<Automation> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateAutomations>>, TError, { id: number;data: BodyType<Automation> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAutomations>>, { id: number;data: BodyType<Automation> }> = (props) => {
		const { id, data } = props ?? {};

		return updateAutomations(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateAutomationsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAutomations>>>;
export type UpdateAutomationsMutationBody = BodyType<Automation>;
export type UpdateAutomationsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateAutomations = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateAutomations>>, TError, { id: number;data: BodyType<Automation> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateAutomations>>,
	TError,
	{ id: number;data: BodyType<Automation> },
	TContext
> => {

	const mutationOptions = getUpdateAutomationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const cancelLicence = (
    
) => {
      
      
	return customInstance<void>(
		{ url: "/api/billing/cancel_licence", method: "POST"
		}
	);
};
  


export const getCancelLicenceMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelLicence>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof cancelLicence>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelLicence>>, void> = () => {
          

		return cancelLicence();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CancelLicenceMutationResult = NonNullable<Awaited<ReturnType<typeof cancelLicence>>>;
    
export type CancelLicenceMutationError = ErrorType<unknown>;

export const useCancelLicence = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelLicence>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof cancelLicence>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getCancelLicenceMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const changePlan = (
	salablePlan: BodyType<SalablePlan>
) => {
      
      
	return customInstance<SalablePlan>(
		{ url: "/api/billing/change_plan",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: salablePlan
		}
	);
};
  


export const getChangePlanMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof changePlan>>, TError, { data: BodyType<SalablePlan> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof changePlan>>, TError, { data: BodyType<SalablePlan> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePlan>>, { data: BodyType<SalablePlan> }> = (props) => {
		const { data } = props ?? {};

		return changePlan(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ChangePlanMutationResult = NonNullable<Awaited<ReturnType<typeof changePlan>>>;
export type ChangePlanMutationBody = BodyType<SalablePlan>;
export type ChangePlanMutationError = ErrorType<unknown>;

export const useChangePlan = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof changePlan>>, TError, { data: BodyType<SalablePlan> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof changePlan>>,
	TError,
	{ data: BodyType<SalablePlan> },
	TContext
> => {

	const mutationOptions = getChangePlanMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const checkoutLink = (
	salablePlan: BodyType<SalablePlan>
) => {
      
      
	return customInstance<string>(
		{ url: "/api/billing/checkout_link",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: salablePlan
		}
	);
};
  


export const getCheckoutLinkMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkoutLink>>, TError, { data: BodyType<SalablePlan> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof checkoutLink>>, TError, { data: BodyType<SalablePlan> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkoutLink>>, { data: BodyType<SalablePlan> }> = (props) => {
		const { data } = props ?? {};

		return checkoutLink(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CheckoutLinkMutationResult = NonNullable<Awaited<ReturnType<typeof checkoutLink>>>;
export type CheckoutLinkMutationBody = BodyType<SalablePlan>;
export type CheckoutLinkMutationError = ErrorType<unknown>;

export const useCheckoutLink = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkoutLink>>, TError, { data: BodyType<SalablePlan> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof checkoutLink>>,
	TError,
	{ data: BodyType<SalablePlan> },
	TContext
> => {

	const mutationOptions = getCheckoutLinkMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getBillingStatus = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<BillingStatus>(
		{ url: "/api/billing/get_billing_status", method: "GET", signal
		}
	);
};
  

export const getGetBillingStatusQueryKey = () => {
	return ["/api/billing/get_billing_status"] as const;
};

    
export const getGetBillingStatusQueryOptions = <TData = Awaited<ReturnType<typeof getBillingStatus>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetBillingStatusQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingStatus>>> = ({ signal }) => getBillingStatus(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData> & { queryKey: QueryKey };
};

export type GetBillingStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingStatus>>>;
export type GetBillingStatusQueryError = ErrorType<unknown>;


export function useGetBillingStatus<TData = Awaited<ReturnType<typeof getBillingStatus>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getBillingStatus>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBillingStatus<TData = Awaited<ReturnType<typeof getBillingStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getBillingStatus>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBillingStatus<TData = Awaited<ReturnType<typeof getBillingStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetBillingStatus<TData = Awaited<ReturnType<typeof getBillingStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBillingStatus>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetBillingStatusQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const updatePaymentLink = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<string>(
		{ url: "/api/billing/update_payment_link", method: "GET", signal
		}
	);
};
  

export const getUpdatePaymentLinkQueryKey = () => {
	return ["/api/billing/update_payment_link"] as const;
};

    
export const getUpdatePaymentLinkQueryOptions = <TData = Awaited<ReturnType<typeof updatePaymentLink>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUpdatePaymentLinkQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof updatePaymentLink>>> = ({ signal }) => updatePaymentLink(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData> & { queryKey: QueryKey };
};

export type UpdatePaymentLinkQueryResult = NonNullable<Awaited<ReturnType<typeof updatePaymentLink>>>;
export type UpdatePaymentLinkQueryError = ErrorType<unknown>;


export function useUpdatePaymentLink<TData = Awaited<ReturnType<typeof updatePaymentLink>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof updatePaymentLink>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUpdatePaymentLink<TData = Awaited<ReturnType<typeof updatePaymentLink>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof updatePaymentLink>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useUpdatePaymentLink<TData = Awaited<ReturnType<typeof updatePaymentLink>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useUpdatePaymentLink<TData = Awaited<ReturnType<typeof updatePaymentLink>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof updatePaymentLink>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getUpdatePaymentLinkQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Get cache
 */
export const getCache = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<Cache>(
		{ url: "/api/cache", method: "GET", signal
		}
	);
};
  

export const getGetCacheQueryKey = () => {
	return ["/api/cache"] as const;
};

    
export const getGetCacheQueryOptions = <TData = Awaited<ReturnType<typeof getCache>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCacheQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCache>>> = ({ signal }) => getCache(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData> & { queryKey: QueryKey };
};

export type GetCacheQueryResult = NonNullable<Awaited<ReturnType<typeof getCache>>>;
export type GetCacheQueryError = ErrorType<unknown>;


export function useGetCache<TData = Awaited<ReturnType<typeof getCache>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCache>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCache<TData = Awaited<ReturnType<typeof getCache>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCache>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCache<TData = Awaited<ReturnType<typeof getCache>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get cache
 */

export function useGetCache<TData = Awaited<ReturnType<typeof getCache>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCache>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetCacheQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Get all
 */
export const getAllCannedReplies = (
	params?: GetAllCannedRepliesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionCannedReply>(
		{ url: "/api/cannedreplies",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllCannedRepliesQueryKey = (params?: GetAllCannedRepliesParams) => {
	return ["/api/cannedreplies", ...(params ? [params] : [])] as const;
};

    
export const getGetAllCannedRepliesQueryOptions = <TData = Awaited<ReturnType<typeof getAllCannedReplies>>, TError = ErrorType<unknown>>(params?: GetAllCannedRepliesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllCannedRepliesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCannedReplies>>> = ({ signal }) => getAllCannedReplies(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllCannedRepliesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCannedReplies>>>;
export type GetAllCannedRepliesQueryError = ErrorType<unknown>;


export function useGetAllCannedReplies<TData = Awaited<ReturnType<typeof getAllCannedReplies>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllCannedRepliesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCannedReplies>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCannedReplies<TData = Awaited<ReturnType<typeof getAllCannedReplies>>, TError = ErrorType<unknown>>(
	params?: GetAllCannedRepliesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCannedReplies>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCannedReplies<TData = Awaited<ReturnType<typeof getAllCannedReplies>>, TError = ErrorType<unknown>>(
	params?: GetAllCannedRepliesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllCannedReplies<TData = Awaited<ReturnType<typeof getAllCannedReplies>>, TError = ErrorType<unknown>>(
	params?: GetAllCannedRepliesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCannedReplies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllCannedRepliesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsCannedReplies = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/cannedreplies", method: "HEAD", signal
		}
	);
};
  


export const getExistsCannedRepliesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCannedReplies>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsCannedReplies>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsCannedReplies>>, void> = () => {
          

		return existsCannedReplies();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsCannedRepliesMutationResult = NonNullable<Awaited<ReturnType<typeof existsCannedReplies>>>;
    
export type ExistsCannedRepliesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsCannedReplies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCannedReplies>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsCannedReplies>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsCannedRepliesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createCannedReplies = (
	cannedReply: BodyType<CannedReply>
) => {
      
      
	return customInstance<PayloadCannedReply>(
		{ url: "/api/cannedreplies",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: cannedReply
		}
	);
};
  


export const getCreateCannedRepliesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCannedReplies>>, TError, { data: BodyType<CannedReply> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createCannedReplies>>, TError, { data: BodyType<CannedReply> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCannedReplies>>, { data: BodyType<CannedReply> }> = (props) => {
		const { data } = props ?? {};

		return createCannedReplies(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateCannedRepliesMutationResult = NonNullable<Awaited<ReturnType<typeof createCannedReplies>>>;
export type CreateCannedRepliesMutationBody = BodyType<CannedReply>;
export type CreateCannedRepliesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateCannedReplies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCannedReplies>>, TError, { data: BodyType<CannedReply> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createCannedReplies>>,
	TError,
	{ data: BodyType<CannedReply> },
	TContext
> => {

	const mutationOptions = getCreateCannedRepliesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteCannedReplies = (
	bulkDeleteCannedRepliesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/cannedreplies/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteCannedRepliesBody
		}
	);
};
  


export const getBulkDeleteCannedRepliesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCannedReplies>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCannedReplies>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCannedReplies>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteCannedReplies(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteCannedRepliesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCannedReplies>>>;
export type BulkDeleteCannedRepliesMutationBody = BodyType<number[]>;
export type BulkDeleteCannedRepliesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteCannedReplies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCannedReplies>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteCannedReplies>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteCannedRepliesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteCannedReplies = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/cannedreplies/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteCannedRepliesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCannedReplies>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCannedReplies>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCannedReplies>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteCannedReplies(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteCannedRepliesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCannedReplies>>>;
    
export type DeleteCannedRepliesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteCannedReplies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCannedReplies>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteCannedReplies>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteCannedRepliesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneCannedReplies = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCannedReply>(
		{ url: `/api/cannedreplies/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneCannedRepliesQueryKey = (id: number) => {
	return [`/api/cannedreplies/${id}`] as const;
};

    
export const getGetOneCannedRepliesQueryOptions = <TData = Awaited<ReturnType<typeof getOneCannedReplies>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneCannedRepliesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneCannedReplies>>> = ({ signal }) => getOneCannedReplies(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneCannedRepliesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneCannedReplies>>>;
export type GetOneCannedRepliesQueryError = ErrorType<unknown>;


export function useGetOneCannedReplies<TData = Awaited<ReturnType<typeof getOneCannedReplies>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCannedReplies>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCannedReplies<TData = Awaited<ReturnType<typeof getOneCannedReplies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCannedReplies>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCannedReplies<TData = Awaited<ReturnType<typeof getOneCannedReplies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneCannedReplies<TData = Awaited<ReturnType<typeof getOneCannedReplies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCannedReplies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneCannedRepliesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateCannedReplies = (
	id: number,
	cannedReply: BodyType<CannedReply>
) => {
      
      
	return customInstance<PayloadCannedReply>(
		{ url: `/api/cannedreplies/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: cannedReply
		}
	);
};
  


export const getUpdateCannedRepliesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCannedReplies>>, TError, { id: number;data: BodyType<CannedReply> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateCannedReplies>>, TError, { id: number;data: BodyType<CannedReply> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCannedReplies>>, { id: number;data: BodyType<CannedReply> }> = (props) => {
		const { id, data } = props ?? {};

		return updateCannedReplies(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateCannedRepliesMutationResult = NonNullable<Awaited<ReturnType<typeof updateCannedReplies>>>;
export type UpdateCannedRepliesMutationBody = BodyType<CannedReply>;
export type UpdateCannedRepliesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateCannedReplies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCannedReplies>>, TError, { id: number;data: BodyType<CannedReply> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateCannedReplies>>,
	TError,
	{ id: number;data: BodyType<CannedReply> },
	TContext
> => {

	const mutationOptions = getUpdateCannedRepliesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllCategories = (
	params?: GetAllCategoriesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionCategory>(
		{ url: "/api/categories",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllCategoriesQueryKey = (params?: GetAllCategoriesParams) => {
	return ["/api/categories", ...(params ? [params] : [])] as const;
};

    
export const getGetAllCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getAllCategories>>, TError = ErrorType<unknown>>(params?: GetAllCategoriesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllCategoriesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCategories>>> = ({ signal }) => getAllCategories(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCategories>>>;
export type GetAllCategoriesQueryError = ErrorType<unknown>;


export function useGetAllCategories<TData = Awaited<ReturnType<typeof getAllCategories>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllCategoriesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCategories>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategories<TData = Awaited<ReturnType<typeof getAllCategories>>, TError = ErrorType<unknown>>(
	params?: GetAllCategoriesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCategories>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCategories<TData = Awaited<ReturnType<typeof getAllCategories>>, TError = ErrorType<unknown>>(
	params?: GetAllCategoriesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllCategories<TData = Awaited<ReturnType<typeof getAllCategories>>, TError = ErrorType<unknown>>(
	params?: GetAllCategoriesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCategories>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllCategoriesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsCategories = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/categories", method: "HEAD", signal
		}
	);
};
  


export const getExistsCategoriesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCategories>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsCategories>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsCategories>>, void> = () => {
          

		return existsCategories();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsCategoriesMutationResult = NonNullable<Awaited<ReturnType<typeof existsCategories>>>;
    
export type ExistsCategoriesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsCategories = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCategories>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsCategories>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsCategoriesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createCategories = (
	category: BodyType<Category>
) => {
      
      
	return customInstance<PayloadCategory>(
		{ url: "/api/categories",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: category
		}
	);
};
  


export const getCreateCategoriesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCategories>>, TError, { data: BodyType<Category> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createCategories>>, TError, { data: BodyType<Category> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCategories>>, { data: BodyType<Category> }> = (props) => {
		const { data } = props ?? {};

		return createCategories(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateCategoriesMutationResult = NonNullable<Awaited<ReturnType<typeof createCategories>>>;
export type CreateCategoriesMutationBody = BodyType<Category>;
export type CreateCategoriesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateCategories = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCategories>>, TError, { data: BodyType<Category> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createCategories>>,
	TError,
	{ data: BodyType<Category> },
	TContext
> => {

	const mutationOptions = getCreateCategoriesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteCategories = (
	bulkDeleteCategoriesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/categories/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteCategoriesBody
		}
	);
};
  


export const getBulkDeleteCategoriesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCategories>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCategories>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCategories>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteCategories(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteCategoriesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCategories>>>;
export type BulkDeleteCategoriesMutationBody = BodyType<number[]>;
export type BulkDeleteCategoriesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteCategories = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCategories>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteCategories>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteCategoriesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteCategories = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/categories/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteCategoriesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCategories>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCategories>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCategories>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteCategories(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteCategoriesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCategories>>>;
    
export type DeleteCategoriesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteCategories = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCategories>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteCategories>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteCategoriesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneCategories = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCategory>(
		{ url: `/api/categories/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneCategoriesQueryKey = (id: number) => {
	return [`/api/categories/${id}`] as const;
};

    
export const getGetOneCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getOneCategories>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneCategoriesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneCategories>>> = ({ signal }) => getOneCategories(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneCategories>>>;
export type GetOneCategoriesQueryError = ErrorType<unknown>;


export function useGetOneCategories<TData = Awaited<ReturnType<typeof getOneCategories>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCategories>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCategories<TData = Awaited<ReturnType<typeof getOneCategories>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCategories>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCategories<TData = Awaited<ReturnType<typeof getOneCategories>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneCategories<TData = Awaited<ReturnType<typeof getOneCategories>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCategories>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneCategoriesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateCategories = (
	id: number,
	category: BodyType<Category>
) => {
      
      
	return customInstance<PayloadCategory>(
		{ url: `/api/categories/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: category
		}
	);
};
  


export const getUpdateCategoriesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCategories>>, TError, { id: number;data: BodyType<Category> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateCategories>>, TError, { id: number;data: BodyType<Category> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCategories>>, { id: number;data: BodyType<Category> }> = (props) => {
		const { id, data } = props ?? {};

		return updateCategories(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateCategoriesMutationResult = NonNullable<Awaited<ReturnType<typeof updateCategories>>>;
export type UpdateCategoriesMutationBody = BodyType<Category>;
export type UpdateCategoriesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateCategories = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCategories>>, TError, { id: number;data: BodyType<Category> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateCategories>>,
	TError,
	{ id: number;data: BodyType<Category> },
	TContext
> => {

	const mutationOptions = getUpdateCategoriesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllEmailChannels = (
	params?: GetAllEmailChannelsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionChannelsEmail>(
		{ url: "/api/channels_email",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllEmailChannelsQueryKey = (params?: GetAllEmailChannelsParams) => {
	return ["/api/channels_email", ...(params ? [params] : [])] as const;
};

    
export const getGetAllEmailChannelsQueryOptions = <TData = Awaited<ReturnType<typeof getAllEmailChannels>>, TError = ErrorType<unknown>>(params?: GetAllEmailChannelsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllEmailChannelsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEmailChannels>>> = ({ signal }) => getAllEmailChannels(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllEmailChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEmailChannels>>>;
export type GetAllEmailChannelsQueryError = ErrorType<unknown>;


export function useGetAllEmailChannels<TData = Awaited<ReturnType<typeof getAllEmailChannels>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllEmailChannelsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllEmailChannels>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllEmailChannels<TData = Awaited<ReturnType<typeof getAllEmailChannels>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailChannelsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllEmailChannels>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllEmailChannels<TData = Awaited<ReturnType<typeof getAllEmailChannels>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailChannelsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllEmailChannels<TData = Awaited<ReturnType<typeof getAllEmailChannels>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailChannelsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailChannels>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllEmailChannelsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsEmailChannels = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/channels_email", method: "HEAD", signal
		}
	);
};
  


export const getExistsEmailChannelsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsEmailChannels>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsEmailChannels>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsEmailChannels>>, void> = () => {
          

		return existsEmailChannels();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsEmailChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof existsEmailChannels>>>;
    
export type ExistsEmailChannelsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsEmailChannels = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsEmailChannels>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsEmailChannels>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsEmailChannelsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createEmailChannels = (
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<PayloadChannelsEmail>(
		{ url: "/api/channels_email",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getCreateEmailChannelsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEmailChannels>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createEmailChannels>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEmailChannels>>, { data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { data } = props ?? {};

		return createEmailChannels(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateEmailChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof createEmailChannels>>>;
export type CreateEmailChannelsMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type CreateEmailChannelsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateEmailChannels = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEmailChannels>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createEmailChannels>>,
	TError,
	{ data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getCreateEmailChannelsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteEmailChannels = (
	bulkDeleteEmailChannelsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/channels_email/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteEmailChannelsBody
		}
	);
};
  


export const getBulkDeleteEmailChannelsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailChannels>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailChannels>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteEmailChannels>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteEmailChannels(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteEmailChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteEmailChannels>>>;
export type BulkDeleteEmailChannelsMutationBody = BodyType<number[]>;
export type BulkDeleteEmailChannelsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteEmailChannels = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailChannels>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteEmailChannels>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteEmailChannelsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Check email is free
 */
export const checkEmailIsFree = (
	emailCheckReq: BodyType<EmailCheckReq>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/channels_email/check_email_free",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: emailCheckReq
		}
	);
};
  


export const getCheckEmailIsFreeMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkEmailIsFree>>, TError, { data: BodyType<EmailCheckReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof checkEmailIsFree>>, TError, { data: BodyType<EmailCheckReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkEmailIsFree>>, { data: BodyType<EmailCheckReq> }> = (props) => {
		const { data } = props ?? {};

		return checkEmailIsFree(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CheckEmailIsFreeMutationResult = NonNullable<Awaited<ReturnType<typeof checkEmailIsFree>>>;
export type CheckEmailIsFreeMutationBody = BodyType<EmailCheckReq>;
export type CheckEmailIsFreeMutationError = ErrorType<unknown>;

/**
 * @summary Check email is free
 */
export const useCheckEmailIsFree = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkEmailIsFree>>, TError, { data: BodyType<EmailCheckReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof checkEmailIsFree>>,
	TError,
	{ data: BodyType<EmailCheckReq> },
	TContext
> => {

	const mutationOptions = getCheckEmailIsFreeMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Test IMAP creds
 */
export const testChannelImap = (
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<string>(
		{ url: "/api/channels_email/test_imap",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getTestChannelImapMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof testChannelImap>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof testChannelImap>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof testChannelImap>>, { data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { data } = props ?? {};

		return testChannelImap(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type TestChannelImapMutationResult = NonNullable<Awaited<ReturnType<typeof testChannelImap>>>;
export type TestChannelImapMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type TestChannelImapMutationError = ErrorType<unknown>;

/**
 * @summary Test IMAP creds
 */
export const useTestChannelImap = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof testChannelImap>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof testChannelImap>>,
	TError,
	{ data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getTestChannelImapMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Test SMTP creds
 */
export const testChannelSmtp = (
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<string>(
		{ url: "/api/channels_email/test_smtp",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getTestChannelSmtpMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof testChannelSmtp>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof testChannelSmtp>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof testChannelSmtp>>, { data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { data } = props ?? {};

		return testChannelSmtp(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type TestChannelSmtpMutationResult = NonNullable<Awaited<ReturnType<typeof testChannelSmtp>>>;
export type TestChannelSmtpMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type TestChannelSmtpMutationError = ErrorType<unknown>;

/**
 * @summary Test SMTP creds
 */
export const useTestChannelSmtp = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof testChannelSmtp>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof testChannelSmtp>>,
	TError,
	{ data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getTestChannelSmtpMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteEmailChannels = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/channels_email/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteEmailChannelsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEmailChannels>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteEmailChannels>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEmailChannels>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteEmailChannels(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteEmailChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEmailChannels>>>;
    
export type DeleteEmailChannelsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteEmailChannels = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEmailChannels>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteEmailChannels>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteEmailChannelsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneEmailChannels = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadChannelsEmail>(
		{ url: `/api/channels_email/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneEmailChannelsQueryKey = (id: number) => {
	return [`/api/channels_email/${id}`] as const;
};

    
export const getGetOneEmailChannelsQueryOptions = <TData = Awaited<ReturnType<typeof getOneEmailChannels>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneEmailChannelsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneEmailChannels>>> = ({ signal }) => getOneEmailChannels(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneEmailChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneEmailChannels>>>;
export type GetOneEmailChannelsQueryError = ErrorType<unknown>;


export function useGetOneEmailChannels<TData = Awaited<ReturnType<typeof getOneEmailChannels>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneEmailChannels>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneEmailChannels<TData = Awaited<ReturnType<typeof getOneEmailChannels>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneEmailChannels>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneEmailChannels<TData = Awaited<ReturnType<typeof getOneEmailChannels>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneEmailChannels<TData = Awaited<ReturnType<typeof getOneEmailChannels>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailChannels>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneEmailChannelsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateEmailChannels = (
	id: number,
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<PayloadChannelsEmail>(
		{ url: `/api/channels_email/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getUpdateEmailChannelsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmailChannels>>, TError, { id: number;data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateEmailChannels>>, TError, { id: number;data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmailChannels>>, { id: number;data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { id, data } = props ?? {};

		return updateEmailChannels(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateEmailChannelsMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmailChannels>>>;
export type UpdateEmailChannelsMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type UpdateEmailChannelsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateEmailChannels = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmailChannels>>, TError, { id: number;data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateEmailChannels>>,
	TError,
	{ id: number;data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getUpdateEmailChannelsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllCompanies = (
	params?: GetAllCompaniesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionCompany>(
		{ url: "/api/companies",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllCompaniesQueryKey = (params?: GetAllCompaniesParams) => {
	return ["/api/companies", ...(params ? [params] : [])] as const;
};

    
export const getGetAllCompaniesQueryOptions = <TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = ErrorType<unknown>>(params?: GetAllCompaniesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllCompaniesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCompanies>>> = ({ signal }) => getAllCompanies(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCompanies>>>;
export type GetAllCompaniesQueryError = ErrorType<unknown>;


export function useGetAllCompanies<TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllCompaniesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCompanies>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCompanies<TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = ErrorType<unknown>>(
	params?: GetAllCompaniesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCompanies>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCompanies<TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = ErrorType<unknown>>(
	params?: GetAllCompaniesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllCompanies<TData = Awaited<ReturnType<typeof getAllCompanies>>, TError = ErrorType<unknown>>(
	params?: GetAllCompaniesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllCompaniesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsCompanies = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/companies", method: "HEAD", signal
		}
	);
};
  


export const getExistsCompaniesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCompanies>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsCompanies>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsCompanies>>, void> = () => {
          

		return existsCompanies();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof existsCompanies>>>;
    
export type ExistsCompaniesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsCompanies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCompanies>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsCompanies>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsCompaniesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createCompanies = (
	company: BodyType<Company>
) => {
      
      
	return customInstance<PayloadCompany>(
		{ url: "/api/companies",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: company
		}
	);
};
  


export const getCreateCompaniesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCompanies>>, TError, { data: BodyType<Company> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createCompanies>>, TError, { data: BodyType<Company> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompanies>>, { data: BodyType<Company> }> = (props) => {
		const { data } = props ?? {};

		return createCompanies(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof createCompanies>>>;
export type CreateCompaniesMutationBody = BodyType<Company>;
export type CreateCompaniesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateCompanies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCompanies>>, TError, { data: BodyType<Company> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createCompanies>>,
	TError,
	{ data: BodyType<Company> },
	TContext
> => {

	const mutationOptions = getCreateCompaniesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteCompanies = (
	bulkDeleteCompaniesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/companies/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteCompaniesBody
		}
	);
};
  


export const getBulkDeleteCompaniesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanies>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanies>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCompanies>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteCompanies(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCompanies>>>;
export type BulkDeleteCompaniesMutationBody = BodyType<number[]>;
export type BulkDeleteCompaniesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteCompanies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanies>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteCompanies>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteCompaniesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteCompanies = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/companies/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteCompaniesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCompanies>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompanies>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanies>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteCompanies(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanies>>>;
    
export type DeleteCompaniesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteCompanies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCompanies>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteCompanies>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteCompaniesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneCompanies = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCompany>(
		{ url: `/api/companies/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneCompaniesQueryKey = (id: number) => {
	return [`/api/companies/${id}`] as const;
};

    
export const getGetOneCompaniesQueryOptions = <TData = Awaited<ReturnType<typeof getOneCompanies>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneCompaniesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneCompanies>>> = ({ signal }) => getOneCompanies(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneCompanies>>>;
export type GetOneCompaniesQueryError = ErrorType<unknown>;


export function useGetOneCompanies<TData = Awaited<ReturnType<typeof getOneCompanies>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCompanies>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCompanies<TData = Awaited<ReturnType<typeof getOneCompanies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCompanies>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCompanies<TData = Awaited<ReturnType<typeof getOneCompanies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneCompanies<TData = Awaited<ReturnType<typeof getOneCompanies>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanies>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneCompaniesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateCompanies = (
	id: number,
	company: BodyType<Company>
) => {
      
      
	return customInstance<PayloadCompany>(
		{ url: `/api/companies/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: company
		}
	);
};
  


export const getUpdateCompaniesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCompanies>>, TError, { id: number;data: BodyType<Company> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateCompanies>>, TError, { id: number;data: BodyType<Company> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompanies>>, { id: number;data: BodyType<Company> }> = (props) => {
		const { id, data } = props ?? {};

		return updateCompanies(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateCompaniesMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompanies>>>;
export type UpdateCompaniesMutationBody = BodyType<Company>;
export type UpdateCompaniesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateCompanies = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCompanies>>, TError, { id: number;data: BodyType<Company> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateCompanies>>,
	TError,
	{ id: number;data: BodyType<Company> },
	TContext
> => {

	const mutationOptions = getUpdateCompaniesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllCompanyNotes = (
	params?: GetAllCompanyNotesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionCompanyNote>(
		{ url: "/api/company_notes",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllCompanyNotesQueryKey = (params?: GetAllCompanyNotesParams) => {
	return ["/api/company_notes", ...(params ? [params] : [])] as const;
};

    
export const getGetAllCompanyNotesQueryOptions = <TData = Awaited<ReturnType<typeof getAllCompanyNotes>>, TError = ErrorType<unknown>>(params?: GetAllCompanyNotesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllCompanyNotesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCompanyNotes>>> = ({ signal }) => getAllCompanyNotes(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllCompanyNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCompanyNotes>>>;
export type GetAllCompanyNotesQueryError = ErrorType<unknown>;


export function useGetAllCompanyNotes<TData = Awaited<ReturnType<typeof getAllCompanyNotes>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllCompanyNotesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCompanyNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCompanyNotes<TData = Awaited<ReturnType<typeof getAllCompanyNotes>>, TError = ErrorType<unknown>>(
	params?: GetAllCompanyNotesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllCompanyNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllCompanyNotes<TData = Awaited<ReturnType<typeof getAllCompanyNotes>>, TError = ErrorType<unknown>>(
	params?: GetAllCompanyNotesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllCompanyNotes<TData = Awaited<ReturnType<typeof getAllCompanyNotes>>, TError = ErrorType<unknown>>(
	params?: GetAllCompanyNotesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllCompanyNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllCompanyNotesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsCompanyNotes = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/company_notes", method: "HEAD", signal
		}
	);
};
  


export const getExistsCompanyNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCompanyNotes>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsCompanyNotes>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsCompanyNotes>>, void> = () => {
          

		return existsCompanyNotes();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsCompanyNotesMutationResult = NonNullable<Awaited<ReturnType<typeof existsCompanyNotes>>>;
    
export type ExistsCompanyNotesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsCompanyNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsCompanyNotes>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsCompanyNotes>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsCompanyNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createCompanyNotes = (
	companyNote: BodyType<CompanyNote>
) => {
      
      
	return customInstance<PayloadCompanyNote>(
		{ url: "/api/company_notes",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: companyNote
		}
	);
};
  


export const getCreateCompanyNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCompanyNotes>>, TError, { data: BodyType<CompanyNote> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createCompanyNotes>>, TError, { data: BodyType<CompanyNote> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompanyNotes>>, { data: BodyType<CompanyNote> }> = (props) => {
		const { data } = props ?? {};

		return createCompanyNotes(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateCompanyNotesMutationResult = NonNullable<Awaited<ReturnType<typeof createCompanyNotes>>>;
export type CreateCompanyNotesMutationBody = BodyType<CompanyNote>;
export type CreateCompanyNotesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateCompanyNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCompanyNotes>>, TError, { data: BodyType<CompanyNote> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createCompanyNotes>>,
	TError,
	{ data: BodyType<CompanyNote> },
	TContext
> => {

	const mutationOptions = getCreateCompanyNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteCompanyNotes = (
	bulkDeleteCompanyNotesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/company_notes/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteCompanyNotesBody
		}
	);
};
  


export const getBulkDeleteCompanyNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteCompanyNotes(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteCompanyNotesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>>;
export type BulkDeleteCompanyNotesMutationBody = BodyType<number[]>;
export type BulkDeleteCompanyNotesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteCompanyNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteCompanyNotes>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteCompanyNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteCompanyNotes = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/company_notes/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteCompanyNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyNotes>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyNotes>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyNotes>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteCompanyNotes(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteCompanyNotesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyNotes>>>;
    
export type DeleteCompanyNotesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteCompanyNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteCompanyNotes>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteCompanyNotes>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteCompanyNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneCompanyNotes = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCompanyNote>(
		{ url: `/api/company_notes/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneCompanyNotesQueryKey = (id: number) => {
	return [`/api/company_notes/${id}`] as const;
};

    
export const getGetOneCompanyNotesQueryOptions = <TData = Awaited<ReturnType<typeof getOneCompanyNotes>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneCompanyNotesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneCompanyNotes>>> = ({ signal }) => getOneCompanyNotes(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneCompanyNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneCompanyNotes>>>;
export type GetOneCompanyNotesQueryError = ErrorType<unknown>;


export function useGetOneCompanyNotes<TData = Awaited<ReturnType<typeof getOneCompanyNotes>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCompanyNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCompanyNotes<TData = Awaited<ReturnType<typeof getOneCompanyNotes>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneCompanyNotes>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneCompanyNotes<TData = Awaited<ReturnType<typeof getOneCompanyNotes>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneCompanyNotes<TData = Awaited<ReturnType<typeof getOneCompanyNotes>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneCompanyNotes>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneCompanyNotesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateCompanyNotes = (
	id: number,
	companyNote: BodyType<CompanyNote>
) => {
      
      
	return customInstance<PayloadCompanyNote>(
		{ url: `/api/company_notes/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: companyNote
		}
	);
};
  


export const getUpdateCompanyNotesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCompanyNotes>>, TError, { id: number;data: BodyType<CompanyNote> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateCompanyNotes>>, TError, { id: number;data: BodyType<CompanyNote> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompanyNotes>>, { id: number;data: BodyType<CompanyNote> }> = (props) => {
		const { id, data } = props ?? {};

		return updateCompanyNotes(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateCompanyNotesMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompanyNotes>>>;
export type UpdateCompanyNotesMutationBody = BodyType<CompanyNote>;
export type UpdateCompanyNotesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateCompanyNotes = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateCompanyNotes>>, TError, { id: number;data: BodyType<CompanyNote> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateCompanyNotes>>,
	TError,
	{ id: number;data: BodyType<CompanyNote> },
	TContext
> => {

	const mutationOptions = getUpdateCompanyNotesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllEmailNotifications = (
	params?: GetAllEmailNotificationsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionEmailNotification>(
		{ url: "/api/emailnotifications",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllEmailNotificationsQueryKey = (params?: GetAllEmailNotificationsParams) => {
	return ["/api/emailnotifications", ...(params ? [params] : [])] as const;
};

    
export const getGetAllEmailNotificationsQueryOptions = <TData = Awaited<ReturnType<typeof getAllEmailNotifications>>, TError = ErrorType<unknown>>(params?: GetAllEmailNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllEmailNotificationsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEmailNotifications>>> = ({ signal }) => getAllEmailNotifications(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllEmailNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEmailNotifications>>>;
export type GetAllEmailNotificationsQueryError = ErrorType<unknown>;


export function useGetAllEmailNotifications<TData = Awaited<ReturnType<typeof getAllEmailNotifications>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllEmailNotificationsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllEmailNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllEmailNotifications<TData = Awaited<ReturnType<typeof getAllEmailNotifications>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllEmailNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllEmailNotifications<TData = Awaited<ReturnType<typeof getAllEmailNotifications>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllEmailNotifications<TData = Awaited<ReturnType<typeof getAllEmailNotifications>>, TError = ErrorType<unknown>>(
	params?: GetAllEmailNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllEmailNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllEmailNotificationsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsEmailNotifications = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/emailnotifications", method: "HEAD", signal
		}
	);
};
  


export const getExistsEmailNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsEmailNotifications>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsEmailNotifications>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsEmailNotifications>>, void> = () => {
          

		return existsEmailNotifications();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsEmailNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof existsEmailNotifications>>>;
    
export type ExistsEmailNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsEmailNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsEmailNotifications>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsEmailNotifications>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsEmailNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createEmailNotifications = (
	emailNotification: BodyType<EmailNotification>
) => {
      
      
	return customInstance<PayloadEmailNotification>(
		{ url: "/api/emailnotifications",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: emailNotification
		}
	);
};
  


export const getCreateEmailNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEmailNotifications>>, TError, { data: BodyType<EmailNotification> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createEmailNotifications>>, TError, { data: BodyType<EmailNotification> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEmailNotifications>>, { data: BodyType<EmailNotification> }> = (props) => {
		const { data } = props ?? {};

		return createEmailNotifications(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateEmailNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof createEmailNotifications>>>;
export type CreateEmailNotificationsMutationBody = BodyType<EmailNotification>;
export type CreateEmailNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateEmailNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEmailNotifications>>, TError, { data: BodyType<EmailNotification> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createEmailNotifications>>,
	TError,
	{ data: BodyType<EmailNotification> },
	TContext
> => {

	const mutationOptions = getCreateEmailNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteEmailNotifications = (
	bulkDeleteEmailNotificationsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/emailnotifications/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteEmailNotificationsBody
		}
	);
};
  


export const getBulkDeleteEmailNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteEmailNotifications(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteEmailNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>>;
export type BulkDeleteEmailNotificationsMutationBody = BodyType<number[]>;
export type BulkDeleteEmailNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteEmailNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteEmailNotifications>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteEmailNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteEmailNotifications = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/emailnotifications/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteEmailNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEmailNotifications>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteEmailNotifications>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEmailNotifications>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteEmailNotifications(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteEmailNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEmailNotifications>>>;
    
export type DeleteEmailNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteEmailNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEmailNotifications>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteEmailNotifications>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteEmailNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneEmailNotifications = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadEmailNotification>(
		{ url: `/api/emailnotifications/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneEmailNotificationsQueryKey = (id: number) => {
	return [`/api/emailnotifications/${id}`] as const;
};

    
export const getGetOneEmailNotificationsQueryOptions = <TData = Awaited<ReturnType<typeof getOneEmailNotifications>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneEmailNotificationsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneEmailNotifications>>> = ({ signal }) => getOneEmailNotifications(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneEmailNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneEmailNotifications>>>;
export type GetOneEmailNotificationsQueryError = ErrorType<unknown>;


export function useGetOneEmailNotifications<TData = Awaited<ReturnType<typeof getOneEmailNotifications>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneEmailNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneEmailNotifications<TData = Awaited<ReturnType<typeof getOneEmailNotifications>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneEmailNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneEmailNotifications<TData = Awaited<ReturnType<typeof getOneEmailNotifications>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneEmailNotifications<TData = Awaited<ReturnType<typeof getOneEmailNotifications>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneEmailNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneEmailNotificationsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateEmailNotifications = (
	id: number,
	emailNotification: BodyType<EmailNotification>
) => {
      
      
	return customInstance<PayloadEmailNotification>(
		{ url: `/api/emailnotifications/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: emailNotification
		}
	);
};
  


export const getUpdateEmailNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmailNotifications>>, TError, { id: number;data: BodyType<EmailNotification> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateEmailNotifications>>, TError, { id: number;data: BodyType<EmailNotification> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmailNotifications>>, { id: number;data: BodyType<EmailNotification> }> = (props) => {
		const { id, data } = props ?? {};

		return updateEmailNotifications(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateEmailNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmailNotifications>>>;
export type UpdateEmailNotificationsMutationBody = BodyType<EmailNotification>;
export type UpdateEmailNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateEmailNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmailNotifications>>, TError, { id: number;data: BodyType<EmailNotification> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateEmailNotifications>>,
	TError,
	{ id: number;data: BodyType<EmailNotification> },
	TContext
> => {

	const mutationOptions = getUpdateEmailNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const post1 = (
	email: BodyType<Email>
) => {
      
      
	return customInstance<PayloadVoid>(
		{ url: "/api/emails",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: email
		}
	);
};
  


export const getPost1MutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof post1>>, TError, { data: BodyType<Email> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof post1>>, TError, { data: BodyType<Email> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof post1>>, { data: BodyType<Email> }> = (props) => {
		const { data } = props ?? {};

		return post1(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type Post1MutationResult = NonNullable<Awaited<ReturnType<typeof post1>>>;
export type Post1MutationBody = BodyType<Email>;
export type Post1MutationError = ErrorType<unknown>;

export const usePost1 = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof post1>>, TError, { data: BodyType<Email> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof post1>>,
	TError,
	{ data: BodyType<Email> },
	TContext
> => {

	const mutationOptions = getPost1MutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getFeed = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionFeedItem>(
		{ url: "/api/feed", method: "GET", signal
		}
	);
};
  

export const getGetFeedQueryKey = () => {
	return ["/api/feed"] as const;
};

    
export const getGetFeedQueryOptions = <TData = Awaited<ReturnType<typeof getFeed>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetFeedQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeed>>> = ({ signal }) => getFeed(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData> & { queryKey: QueryKey };
};

export type GetFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getFeed>>>;
export type GetFeedQueryError = ErrorType<unknown>;


export function useGetFeed<TData = Awaited<ReturnType<typeof getFeed>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getFeed>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFeed<TData = Awaited<ReturnType<typeof getFeed>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getFeed>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFeed<TData = Awaited<ReturnType<typeof getFeed>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetFeed<TData = Awaited<ReturnType<typeof getFeed>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeed>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetFeedQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const draftKbArticleFromAction = (
	action: BodyType<NonReadonly<Action>>
) => {
      
      
	return customInstance<SseEmitter>(
		{ url: "/api/gpt/draft-kb-article-from-action",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: action
		}
	);
};
  


export const getDraftKbArticleFromActionMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof draftKbArticleFromAction>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof draftKbArticleFromAction>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof draftKbArticleFromAction>>, { data: BodyType<NonReadonly<Action>> }> = (props) => {
		const { data } = props ?? {};

		return draftKbArticleFromAction(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DraftKbArticleFromActionMutationResult = NonNullable<Awaited<ReturnType<typeof draftKbArticleFromAction>>>;
export type DraftKbArticleFromActionMutationBody = BodyType<NonReadonly<Action>>;
export type DraftKbArticleFromActionMutationError = ErrorType<unknown>;

export const useDraftKbArticleFromAction = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof draftKbArticleFromAction>>, TError, { data: BodyType<NonReadonly<Action>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof draftKbArticleFromAction>>,
	TError,
	{ data: BodyType<NonReadonly<Action>> },
	TContext
> => {

	const mutationOptions = getDraftKbArticleFromActionMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllKnowledgeBases = (
	params: GetAllKnowledgeBasesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionKb>(
		{ url: "/api/kb",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllKnowledgeBasesQueryKey = (params: GetAllKnowledgeBasesParams) => {
	return ["/api/kb", ...(params ? [params] : [])] as const;
};

    
export const getGetAllKnowledgeBasesQueryOptions = <TData = Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError = ErrorType<unknown>>(params: GetAllKnowledgeBasesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllKnowledgeBasesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllKnowledgeBases>>> = ({ signal }) => getAllKnowledgeBases(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllKnowledgeBasesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllKnowledgeBases>>>;
export type GetAllKnowledgeBasesQueryError = ErrorType<unknown>;


export function useGetAllKnowledgeBases<TData = Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError = ErrorType<unknown>>(
	params: GetAllKnowledgeBasesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKnowledgeBases>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKnowledgeBases<TData = Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError = ErrorType<unknown>>(
	params: GetAllKnowledgeBasesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKnowledgeBases>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKnowledgeBases<TData = Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError = ErrorType<unknown>>(
	params: GetAllKnowledgeBasesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllKnowledgeBases<TData = Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError = ErrorType<unknown>>(
	params: GetAllKnowledgeBasesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKnowledgeBases>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllKnowledgeBasesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsKnowledgeBases = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kb", method: "HEAD", signal
		}
	);
};
  


export const getExistsKnowledgeBasesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKnowledgeBases>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsKnowledgeBases>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsKnowledgeBases>>, void> = () => {
          

		return existsKnowledgeBases();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsKnowledgeBasesMutationResult = NonNullable<Awaited<ReturnType<typeof existsKnowledgeBases>>>;
    
export type ExistsKnowledgeBasesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsKnowledgeBases = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKnowledgeBases>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsKnowledgeBases>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsKnowledgeBasesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createKnowledgeBases = (
	kb: BodyType<NonReadonly<Kb>>
) => {
      
      
	return customInstance<PayloadKb>(
		{ url: "/api/kb",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: kb
		}
	);
};
  


export const getCreateKnowledgeBasesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKnowledgeBases>>, TError, { data: BodyType<NonReadonly<Kb>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createKnowledgeBases>>, TError, { data: BodyType<NonReadonly<Kb>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createKnowledgeBases>>, { data: BodyType<NonReadonly<Kb>> }> = (props) => {
		const { data } = props ?? {};

		return createKnowledgeBases(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateKnowledgeBasesMutationResult = NonNullable<Awaited<ReturnType<typeof createKnowledgeBases>>>;
export type CreateKnowledgeBasesMutationBody = BodyType<NonReadonly<Kb>>;
export type CreateKnowledgeBasesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateKnowledgeBases = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKnowledgeBases>>, TError, { data: BodyType<NonReadonly<Kb>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createKnowledgeBases>>,
	TError,
	{ data: BodyType<NonReadonly<Kb>> },
	TContext
> => {

	const mutationOptions = getCreateKnowledgeBasesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteKnowledgeBases = (
	bulkDeleteKnowledgeBasesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kb/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteKnowledgeBasesBody
		}
	);
};
  


export const getBulkDeleteKnowledgeBasesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteKnowledgeBases(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteKnowledgeBasesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>>;
export type BulkDeleteKnowledgeBasesMutationBody = BodyType<number[]>;
export type BulkDeleteKnowledgeBasesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteKnowledgeBases = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteKnowledgeBases>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteKnowledgeBasesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteKnowledgeBases = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/kb/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteKnowledgeBasesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKnowledgeBases>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteKnowledgeBases>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteKnowledgeBases>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteKnowledgeBases(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteKnowledgeBasesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteKnowledgeBases>>>;
    
export type DeleteKnowledgeBasesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteKnowledgeBases = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKnowledgeBases>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteKnowledgeBases>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteKnowledgeBasesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneKnowledgeBases = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadKb>(
		{ url: `/api/kb/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneKnowledgeBasesQueryKey = (id: number) => {
	return [`/api/kb/${id}`] as const;
};

    
export const getGetOneKnowledgeBasesQueryOptions = <TData = Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneKnowledgeBasesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneKnowledgeBases>>> = ({ signal }) => getOneKnowledgeBases(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneKnowledgeBasesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneKnowledgeBases>>>;
export type GetOneKnowledgeBasesQueryError = ErrorType<unknown>;


export function useGetOneKnowledgeBases<TData = Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKnowledgeBases>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKnowledgeBases<TData = Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKnowledgeBases>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKnowledgeBases<TData = Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneKnowledgeBases<TData = Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKnowledgeBases>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneKnowledgeBasesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateKnowledgeBases = (
	id: number,
	kb: BodyType<NonReadonly<Kb>>
) => {
      
      
	return customInstance<PayloadKb>(
		{ url: `/api/kb/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: kb
		}
	);
};
  


export const getUpdateKnowledgeBasesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKnowledgeBases>>, TError, { id: number;data: BodyType<NonReadonly<Kb>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateKnowledgeBases>>, TError, { id: number;data: BodyType<NonReadonly<Kb>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateKnowledgeBases>>, { id: number;data: BodyType<NonReadonly<Kb>> }> = (props) => {
		const { id, data } = props ?? {};

		return updateKnowledgeBases(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateKnowledgeBasesMutationResult = NonNullable<Awaited<ReturnType<typeof updateKnowledgeBases>>>;
export type UpdateKnowledgeBasesMutationBody = BodyType<NonReadonly<Kb>>;
export type UpdateKnowledgeBasesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateKnowledgeBases = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKnowledgeBases>>, TError, { id: number;data: BodyType<NonReadonly<Kb>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateKnowledgeBases>>,
	TError,
	{ id: number;data: BodyType<NonReadonly<Kb>> },
	TContext
> => {

	const mutationOptions = getUpdateKnowledgeBasesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const post = (
	kbArticleContent: BodyType<KbArticleContent>
) => {
      
      
	return customInstance<PayloadKbArticleContent>(
		{ url: "/api/kb_article_content",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: kbArticleContent
		}
	);
};
  


export const getPostMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof post>>, TError, { data: BodyType<KbArticleContent> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof post>>, TError, { data: BodyType<KbArticleContent> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof post>>, { data: BodyType<KbArticleContent> }> = (props) => {
		const { data } = props ?? {};

		return post(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type PostMutationResult = NonNullable<Awaited<ReturnType<typeof post>>>;
export type PostMutationBody = BodyType<KbArticleContent>;
export type PostMutationError = ErrorType<unknown>;

export const usePost = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof post>>, TError, { data: BodyType<KbArticleContent> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof post>>,
	TError,
	{ data: BodyType<KbArticleContent> },
	TContext
> => {

	const mutationOptions = getPostMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const _delete = (
	id: number,
	lang: string
) => {
      
      
	return customInstance<string>(
		{ url: `/api/kb_article_content/${encodeURIComponent(String(id))}/${encodeURIComponent(String(lang))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: number;lang: string }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: number;lang: string }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof _delete>>, { id: number;lang: string }> = (props) => {
		const { id, lang } = props ?? {};

		return _delete(id, lang);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type _DeleteMutationResult = NonNullable<Awaited<ReturnType<typeof _delete>>>;
    
export type _DeleteMutationError = ErrorType<unknown>;

export const useDelete = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof _delete>>, TError, { id: number;lang: string }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof _delete>>,
	TError,
	{ id: number;lang: string },
	TContext
> => {

	const mutationOptions = getDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getOne = (
	id: number,
	lang: string,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadKbArticleContent>(
		{ url: `/api/kb_article_content/${encodeURIComponent(String(id))}/${encodeURIComponent(String(lang))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneQueryKey = (id: number,
	lang: string) => {
	return [`/api/kb_article_content/${id}/${lang}`] as const;
};

    
export const getGetOneQueryOptions = <TData = Awaited<ReturnType<typeof getOne>>, TError = ErrorType<unknown>>(id: number,
	lang: string, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneQueryKey(id, lang);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOne>>> = ({ signal }) => getOne(id, lang, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id && lang), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneQueryResult = NonNullable<Awaited<ReturnType<typeof getOne>>>;
export type GetOneQueryError = ErrorType<unknown>;


export function useGetOne<TData = Awaited<ReturnType<typeof getOne>>, TError = ErrorType<unknown>>(
	id: number,
	lang: string, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOne>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOne<TData = Awaited<ReturnType<typeof getOne>>, TError = ErrorType<unknown>>(
	id: number,
	lang: string, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOne>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOne<TData = Awaited<ReturnType<typeof getOne>>, TError = ErrorType<unknown>>(
	id: number,
	lang: string, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetOne<TData = Awaited<ReturnType<typeof getOne>>, TError = ErrorType<unknown>>(
	id: number,
	lang: string, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOne>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneQueryOptions(id, lang, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const put = (
	id: number,
	lang: string,
	kbArticleContent: BodyType<KbArticleContent>
) => {
      
      
	return customInstance<PayloadKbArticleContent>(
		{ url: `/api/kb_article_content/${encodeURIComponent(String(id))}/${encodeURIComponent(String(lang))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: kbArticleContent
		}
	);
};
  


export const getPutMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof put>>, TError, { id: number;lang: string;data: BodyType<KbArticleContent> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof put>>, TError, { id: number;lang: string;data: BodyType<KbArticleContent> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof put>>, { id: number;lang: string;data: BodyType<KbArticleContent> }> = (props) => {
		const { id, lang, data } = props ?? {};

		return put(id, lang, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type PutMutationResult = NonNullable<Awaited<ReturnType<typeof put>>>;
export type PutMutationBody = BodyType<KbArticleContent>;
export type PutMutationError = ErrorType<unknown>;

export const usePut = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof put>>, TError, { id: number;lang: string;data: BodyType<KbArticleContent> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof put>>,
	TError,
	{ id: number;lang: string;data: BodyType<KbArticleContent> },
	TContext
> => {

	const mutationOptions = getPutMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllKBArticles = (
	params?: GetAllKBArticlesParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionKbArticle>(
		{ url: "/api/kbarticles",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllKBArticlesQueryKey = (params?: GetAllKBArticlesParams) => {
	return ["/api/kbarticles", ...(params ? [params] : [])] as const;
};

    
export const getGetAllKBArticlesQueryOptions = <TData = Awaited<ReturnType<typeof getAllKBArticles>>, TError = ErrorType<unknown>>(params?: GetAllKBArticlesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllKBArticlesQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllKBArticles>>> = ({ signal }) => getAllKBArticles(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllKBArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllKBArticles>>>;
export type GetAllKBArticlesQueryError = ErrorType<unknown>;


export function useGetAllKBArticles<TData = Awaited<ReturnType<typeof getAllKBArticles>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllKBArticlesParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKBArticles>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKBArticles<TData = Awaited<ReturnType<typeof getAllKBArticles>>, TError = ErrorType<unknown>>(
	params?: GetAllKBArticlesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKBArticles>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKBArticles<TData = Awaited<ReturnType<typeof getAllKBArticles>>, TError = ErrorType<unknown>>(
	params?: GetAllKBArticlesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllKBArticles<TData = Awaited<ReturnType<typeof getAllKBArticles>>, TError = ErrorType<unknown>>(
	params?: GetAllKBArticlesParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBArticles>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllKBArticlesQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsKBArticles = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kbarticles", method: "HEAD", signal
		}
	);
};
  


export const getExistsKBArticlesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKBArticles>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsKBArticles>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsKBArticles>>, void> = () => {
          

		return existsKBArticles();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsKBArticlesMutationResult = NonNullable<Awaited<ReturnType<typeof existsKBArticles>>>;
    
export type ExistsKBArticlesMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsKBArticles = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKBArticles>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsKBArticles>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsKBArticlesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createKBArticles = (
	kbArticle: BodyType<KbArticle>
) => {
      
      
	return customInstance<PayloadKbArticle>(
		{ url: "/api/kbarticles",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: kbArticle
		}
	);
};
  


export const getCreateKBArticlesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKBArticles>>, TError, { data: BodyType<KbArticle> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createKBArticles>>, TError, { data: BodyType<KbArticle> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createKBArticles>>, { data: BodyType<KbArticle> }> = (props) => {
		const { data } = props ?? {};

		return createKBArticles(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateKBArticlesMutationResult = NonNullable<Awaited<ReturnType<typeof createKBArticles>>>;
export type CreateKBArticlesMutationBody = BodyType<KbArticle>;
export type CreateKBArticlesMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateKBArticles = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKBArticles>>, TError, { data: BodyType<KbArticle> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createKBArticles>>,
	TError,
	{ data: BodyType<KbArticle> },
	TContext
> => {

	const mutationOptions = getCreateKBArticlesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteKBArticles = (
	bulkDeleteKBArticlesBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kbarticles/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteKBArticlesBody
		}
	);
};
  


export const getBulkDeleteKBArticlesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBArticles>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBArticles>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteKBArticles>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteKBArticles(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteKBArticlesMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteKBArticles>>>;
export type BulkDeleteKBArticlesMutationBody = BodyType<number[]>;
export type BulkDeleteKBArticlesMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteKBArticles = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBArticles>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteKBArticles>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteKBArticlesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteKBArticles = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/kbarticles/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteKBArticlesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKBArticles>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteKBArticles>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteKBArticles>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteKBArticles(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteKBArticlesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteKBArticles>>>;
    
export type DeleteKBArticlesMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteKBArticles = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKBArticles>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteKBArticles>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteKBArticlesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneKBArticles = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadKbArticle>(
		{ url: `/api/kbarticles/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneKBArticlesQueryKey = (id: number) => {
	return [`/api/kbarticles/${id}`] as const;
};

    
export const getGetOneKBArticlesQueryOptions = <TData = Awaited<ReturnType<typeof getOneKBArticles>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneKBArticlesQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneKBArticles>>> = ({ signal }) => getOneKBArticles(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneKBArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof getOneKBArticles>>>;
export type GetOneKBArticlesQueryError = ErrorType<unknown>;


export function useGetOneKBArticles<TData = Awaited<ReturnType<typeof getOneKBArticles>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKBArticles>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKBArticles<TData = Awaited<ReturnType<typeof getOneKBArticles>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKBArticles>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKBArticles<TData = Awaited<ReturnType<typeof getOneKBArticles>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneKBArticles<TData = Awaited<ReturnType<typeof getOneKBArticles>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBArticles>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneKBArticlesQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateKBArticles = (
	id: number,
	kbArticle: BodyType<KbArticle>
) => {
      
      
	return customInstance<PayloadKbArticle>(
		{ url: `/api/kbarticles/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: kbArticle
		}
	);
};
  


export const getUpdateKBArticlesMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKBArticles>>, TError, { id: number;data: BodyType<KbArticle> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateKBArticles>>, TError, { id: number;data: BodyType<KbArticle> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateKBArticles>>, { id: number;data: BodyType<KbArticle> }> = (props) => {
		const { id, data } = props ?? {};

		return updateKBArticles(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateKBArticlesMutationResult = NonNullable<Awaited<ReturnType<typeof updateKBArticles>>>;
export type UpdateKBArticlesMutationBody = BodyType<KbArticle>;
export type UpdateKBArticlesMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateKBArticles = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKBArticles>>, TError, { id: number;data: BodyType<KbArticle> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateKBArticles>>,
	TError,
	{ id: number;data: BodyType<KbArticle> },
	TContext
> => {

	const mutationOptions = getUpdateKBArticlesMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const vote = (
	id: number,
	params?: VoteParams
) => {
      
      
	return customInstance<void>(
		{ url: `/api/kbarticles/${encodeURIComponent(String(id))}/vote`,
			method: "POST",
			params
		}
	);
};
  


export const getVoteMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof vote>>, TError, { id: number;params?: VoteParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof vote>>, TError, { id: number;params?: VoteParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof vote>>, { id: number;params?: VoteParams }> = (props) => {
		const { id, params } = props ?? {};

		return vote(id, params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type VoteMutationResult = NonNullable<Awaited<ReturnType<typeof vote>>>;
    
export type VoteMutationError = ErrorType<unknown>;

export const useVote = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof vote>>, TError, { id: number;params?: VoteParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof vote>>,
	TError,
	{ id: number;params?: VoteParams },
	TContext
> => {

	const mutationOptions = getVoteMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllKBSections = (
	params?: GetAllKBSectionsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionKbSection>(
		{ url: "/api/kbsections",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllKBSectionsQueryKey = (params?: GetAllKBSectionsParams) => {
	return ["/api/kbsections", ...(params ? [params] : [])] as const;
};

    
export const getGetAllKBSectionsQueryOptions = <TData = Awaited<ReturnType<typeof getAllKBSections>>, TError = ErrorType<unknown>>(params?: GetAllKBSectionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllKBSectionsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllKBSections>>> = ({ signal }) => getAllKBSections(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllKBSectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllKBSections>>>;
export type GetAllKBSectionsQueryError = ErrorType<unknown>;


export function useGetAllKBSections<TData = Awaited<ReturnType<typeof getAllKBSections>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllKBSectionsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKBSections>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKBSections<TData = Awaited<ReturnType<typeof getAllKBSections>>, TError = ErrorType<unknown>>(
	params?: GetAllKBSectionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllKBSections>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllKBSections<TData = Awaited<ReturnType<typeof getAllKBSections>>, TError = ErrorType<unknown>>(
	params?: GetAllKBSectionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllKBSections<TData = Awaited<ReturnType<typeof getAllKBSections>>, TError = ErrorType<unknown>>(
	params?: GetAllKBSectionsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllKBSections>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllKBSectionsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsKBSections = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kbsections", method: "HEAD", signal
		}
	);
};
  


export const getExistsKBSectionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKBSections>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsKBSections>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsKBSections>>, void> = () => {
          

		return existsKBSections();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsKBSectionsMutationResult = NonNullable<Awaited<ReturnType<typeof existsKBSections>>>;
    
export type ExistsKBSectionsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsKBSections = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsKBSections>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsKBSections>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsKBSectionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createKBSections = (
	kbSection: BodyType<KbSection>
) => {
      
      
	return customInstance<PayloadKbSection>(
		{ url: "/api/kbsections",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: kbSection
		}
	);
};
  


export const getCreateKBSectionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKBSections>>, TError, { data: BodyType<KbSection> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createKBSections>>, TError, { data: BodyType<KbSection> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createKBSections>>, { data: BodyType<KbSection> }> = (props) => {
		const { data } = props ?? {};

		return createKBSections(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateKBSectionsMutationResult = NonNullable<Awaited<ReturnType<typeof createKBSections>>>;
export type CreateKBSectionsMutationBody = BodyType<KbSection>;
export type CreateKBSectionsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateKBSections = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createKBSections>>, TError, { data: BodyType<KbSection> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createKBSections>>,
	TError,
	{ data: BodyType<KbSection> },
	TContext
> => {

	const mutationOptions = getCreateKBSectionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteKBSections = (
	bulkDeleteKBSectionsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/kbsections/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteKBSectionsBody
		}
	);
};
  


export const getBulkDeleteKBSectionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBSections>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBSections>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteKBSections>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteKBSections(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteKBSectionsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteKBSections>>>;
export type BulkDeleteKBSectionsMutationBody = BodyType<number[]>;
export type BulkDeleteKBSectionsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteKBSections = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteKBSections>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteKBSections>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteKBSectionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteKBSections = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/kbsections/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteKBSectionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKBSections>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteKBSections>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteKBSections>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteKBSections(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteKBSectionsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteKBSections>>>;
    
export type DeleteKBSectionsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteKBSections = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteKBSections>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteKBSections>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteKBSectionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneKBSections = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadKbSection>(
		{ url: `/api/kbsections/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneKBSectionsQueryKey = (id: number) => {
	return [`/api/kbsections/${id}`] as const;
};

    
export const getGetOneKBSectionsQueryOptions = <TData = Awaited<ReturnType<typeof getOneKBSections>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneKBSectionsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneKBSections>>> = ({ signal }) => getOneKBSections(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneKBSectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneKBSections>>>;
export type GetOneKBSectionsQueryError = ErrorType<unknown>;


export function useGetOneKBSections<TData = Awaited<ReturnType<typeof getOneKBSections>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKBSections>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKBSections<TData = Awaited<ReturnType<typeof getOneKBSections>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneKBSections>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneKBSections<TData = Awaited<ReturnType<typeof getOneKBSections>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneKBSections<TData = Awaited<ReturnType<typeof getOneKBSections>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneKBSections>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneKBSectionsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateKBSections = (
	id: number,
	kbSection: BodyType<KbSection>
) => {
      
      
	return customInstance<PayloadKbSection>(
		{ url: `/api/kbsections/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: kbSection
		}
	);
};
  


export const getUpdateKBSectionsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKBSections>>, TError, { id: number;data: BodyType<KbSection> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateKBSections>>, TError, { id: number;data: BodyType<KbSection> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateKBSections>>, { id: number;data: BodyType<KbSection> }> = (props) => {
		const { id, data } = props ?? {};

		return updateKBSections(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateKBSectionsMutationResult = NonNullable<Awaited<ReturnType<typeof updateKBSections>>>;
export type UpdateKBSectionsMutationBody = BodyType<KbSection>;
export type UpdateKBSectionsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateKBSections = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateKBSections>>, TError, { id: number;data: BodyType<KbSection> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateKBSections>>,
	TError,
	{ id: number;data: BodyType<KbSection> },
	TContext
> => {

	const mutationOptions = getUpdateKBSectionsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllLayouts = (
	params?: GetAllLayoutsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionLayout>(
		{ url: "/api/layouts",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllLayoutsQueryKey = (params?: GetAllLayoutsParams) => {
	return ["/api/layouts", ...(params ? [params] : [])] as const;
};

    
export const getGetAllLayoutsQueryOptions = <TData = Awaited<ReturnType<typeof getAllLayouts>>, TError = ErrorType<unknown>>(params?: GetAllLayoutsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllLayoutsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllLayouts>>> = ({ signal }) => getAllLayouts(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllLayoutsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllLayouts>>>;
export type GetAllLayoutsQueryError = ErrorType<unknown>;


export function useGetAllLayouts<TData = Awaited<ReturnType<typeof getAllLayouts>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllLayoutsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllLayouts>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllLayouts<TData = Awaited<ReturnType<typeof getAllLayouts>>, TError = ErrorType<unknown>>(
	params?: GetAllLayoutsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllLayouts>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllLayouts<TData = Awaited<ReturnType<typeof getAllLayouts>>, TError = ErrorType<unknown>>(
	params?: GetAllLayoutsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllLayouts<TData = Awaited<ReturnType<typeof getAllLayouts>>, TError = ErrorType<unknown>>(
	params?: GetAllLayoutsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllLayouts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllLayoutsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsLayouts = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/layouts", method: "HEAD", signal
		}
	);
};
  


export const getExistsLayoutsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsLayouts>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsLayouts>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsLayouts>>, void> = () => {
          

		return existsLayouts();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsLayoutsMutationResult = NonNullable<Awaited<ReturnType<typeof existsLayouts>>>;
    
export type ExistsLayoutsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsLayouts = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsLayouts>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsLayouts>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsLayoutsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createLayouts = (
	layout: BodyType<Layout>
) => {
      
      
	return customInstance<Layout>(
		{ url: "/api/layouts",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: layout
		}
	);
};
  


export const getCreateLayoutsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createLayouts>>, TError, { data: BodyType<Layout> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createLayouts>>, TError, { data: BodyType<Layout> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLayouts>>, { data: BodyType<Layout> }> = (props) => {
		const { data } = props ?? {};

		return createLayouts(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateLayoutsMutationResult = NonNullable<Awaited<ReturnType<typeof createLayouts>>>;
export type CreateLayoutsMutationBody = BodyType<Layout>;
export type CreateLayoutsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateLayouts = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createLayouts>>, TError, { data: BodyType<Layout> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createLayouts>>,
	TError,
	{ data: BodyType<Layout> },
	TContext
> => {

	const mutationOptions = getCreateLayoutsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteLayouts = (
	bulkDeleteLayoutsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/layouts/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteLayoutsBody
		}
	);
};
  


export const getBulkDeleteLayoutsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteLayouts>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteLayouts>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteLayouts>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteLayouts(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteLayoutsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteLayouts>>>;
export type BulkDeleteLayoutsMutationBody = BodyType<number[]>;
export type BulkDeleteLayoutsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteLayouts = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteLayouts>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteLayouts>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteLayoutsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteLayouts = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/layouts/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteLayoutsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteLayouts>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteLayouts>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLayouts>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteLayouts(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteLayoutsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLayouts>>>;
    
export type DeleteLayoutsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteLayouts = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteLayouts>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteLayouts>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteLayoutsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneLayouts = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<Layout>(
		{ url: `/api/layouts/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneLayoutsQueryKey = (id: number) => {
	return [`/api/layouts/${id}`] as const;
};

    
export const getGetOneLayoutsQueryOptions = <TData = Awaited<ReturnType<typeof getOneLayouts>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneLayoutsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneLayouts>>> = ({ signal }) => getOneLayouts(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneLayoutsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneLayouts>>>;
export type GetOneLayoutsQueryError = ErrorType<unknown>;


export function useGetOneLayouts<TData = Awaited<ReturnType<typeof getOneLayouts>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneLayouts>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneLayouts<TData = Awaited<ReturnType<typeof getOneLayouts>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneLayouts>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneLayouts<TData = Awaited<ReturnType<typeof getOneLayouts>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneLayouts<TData = Awaited<ReturnType<typeof getOneLayouts>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneLayouts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneLayoutsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateLayouts = (
	id: number,
	layout: BodyType<Layout>
) => {
      
      
	return customInstance<Layout>(
		{ url: `/api/layouts/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: layout
		}
	);
};
  


export const getUpdateLayoutsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateLayouts>>, TError, { id: number;data: BodyType<Layout> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateLayouts>>, TError, { id: number;data: BodyType<Layout> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLayouts>>, { id: number;data: BodyType<Layout> }> = (props) => {
		const { id, data } = props ?? {};

		return updateLayouts(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateLayoutsMutationResult = NonNullable<Awaited<ReturnType<typeof updateLayouts>>>;
export type UpdateLayoutsMutationBody = BodyType<Layout>;
export type UpdateLayoutsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateLayouts = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateLayouts>>, TError, { id: number;data: BodyType<Layout> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateLayouts>>,
	TError,
	{ id: number;data: BodyType<Layout> },
	TContext
> => {

	const mutationOptions = getUpdateLayoutsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete all my notification
 */
export const deleteAllMyNotifications = (
    
) => {
      
      
	return customInstance<void>(
		{ url: "/api/notifications/all", method: "DELETE"
		}
	);
};
  


export const getDeleteAllMyNotificationsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAllMyNotifications>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAllMyNotifications>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAllMyNotifications>>, void> = () => {
          

		return deleteAllMyNotifications();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteAllMyNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAllMyNotifications>>>;
    
export type DeleteAllMyNotificationsMutationError = ErrorType<unknown>;

/**
 * @summary Delete all my notification
 */
export const useDeleteAllMyNotifications = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAllMyNotifications>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteAllMyNotifications>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getDeleteAllMyNotificationsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get my notifications
 */
export const getMyNotifications = (
	params?: GetMyNotificationsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionNotification>(
		{ url: "/api/notifications/mine",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetMyNotificationsQueryKey = (params?: GetMyNotificationsParams) => {
	return ["/api/notifications/mine", ...(params ? [params] : [])] as const;
};

    
export const getGetMyNotificationsQueryOptions = <TData = Awaited<ReturnType<typeof getMyNotifications>>, TError = ErrorType<unknown>>(params?: GetMyNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMyNotificationsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyNotifications>>> = ({ signal }) => getMyNotifications(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData> & { queryKey: QueryKey };
};

export type GetMyNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyNotifications>>>;
export type GetMyNotificationsQueryError = ErrorType<unknown>;


export function useGetMyNotifications<TData = Awaited<ReturnType<typeof getMyNotifications>>, TError = ErrorType<unknown>>(
	params: undefined | GetMyNotificationsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMyNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMyNotifications<TData = Awaited<ReturnType<typeof getMyNotifications>>, TError = ErrorType<unknown>>(
	params?: GetMyNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMyNotifications>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMyNotifications<TData = Awaited<ReturnType<typeof getMyNotifications>>, TError = ErrorType<unknown>>(
	params?: GetMyNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get my notifications
 */

export function useGetMyNotifications<TData = Awaited<ReturnType<typeof getMyNotifications>>, TError = ErrorType<unknown>>(
	params?: GetMyNotificationsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMyNotifications>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetMyNotificationsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Delete notification
 */
export const deleteNotification = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/notifications/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteNotificationMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotification>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteNotification(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotification>>>;
    
export type DeleteNotificationMutationError = ErrorType<unknown>;

/**
 * @summary Delete notification
 */
export const useDeleteNotification = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteNotification>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteNotificationMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create Google state code
 */
export const createGoogleStateCode = (
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<string>(
		{ url: "/api/oauthcode/google",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getCreateGoogleStateCodeMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGoogleStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createGoogleStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGoogleStateCode>>, { data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { data } = props ?? {};

		return createGoogleStateCode(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateGoogleStateCodeMutationResult = NonNullable<Awaited<ReturnType<typeof createGoogleStateCode>>>;
export type CreateGoogleStateCodeMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type CreateGoogleStateCodeMutationError = ErrorType<unknown>;

/**
 * @summary Create Google state code
 */
export const useCreateGoogleStateCode = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGoogleStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createGoogleStateCode>>,
	TError,
	{ data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getCreateGoogleStateCodeMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create Microsoft state code
 */
export const createMicrosoftStateCode = (
	channelsEmail: BodyType<NonReadonly<ChannelsEmail>>
) => {
      
      
	return customInstance<string>(
		{ url: "/api/oauthcode/microsoft",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: channelsEmail
		}
	);
};
  


export const getCreateMicrosoftStateCodeMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMicrosoftStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createMicrosoftStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMicrosoftStateCode>>, { data: BodyType<NonReadonly<ChannelsEmail>> }> = (props) => {
		const { data } = props ?? {};

		return createMicrosoftStateCode(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateMicrosoftStateCodeMutationResult = NonNullable<Awaited<ReturnType<typeof createMicrosoftStateCode>>>;
export type CreateMicrosoftStateCodeMutationBody = BodyType<NonReadonly<ChannelsEmail>>;
export type CreateMicrosoftStateCodeMutationError = ErrorType<unknown>;

/**
 * @summary Create Microsoft state code
 */
export const useCreateMicrosoftStateCode = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMicrosoftStateCode>>, TError, { data: BodyType<NonReadonly<ChannelsEmail>> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createMicrosoftStateCode>>,
	TError,
	{ data: BodyType<NonReadonly<ChannelsEmail>> },
	TContext
> => {

	const mutationOptions = getCreateMicrosoftStateCodeMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const checkTokenIsValid = (
	params: CheckTokenIsValidParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/password-reset",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getCheckTokenIsValidQueryKey = (params: CheckTokenIsValidParams) => {
	return ["/api/password-reset", ...(params ? [params] : [])] as const;
};

    
export const getCheckTokenIsValidQueryOptions = <TData = Awaited<ReturnType<typeof checkTokenIsValid>>, TError = ErrorType<unknown>>(params: CheckTokenIsValidParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getCheckTokenIsValidQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof checkTokenIsValid>>> = ({ signal }) => checkTokenIsValid(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData> & { queryKey: QueryKey };
};

export type CheckTokenIsValidQueryResult = NonNullable<Awaited<ReturnType<typeof checkTokenIsValid>>>;
export type CheckTokenIsValidQueryError = ErrorType<unknown>;


export function useCheckTokenIsValid<TData = Awaited<ReturnType<typeof checkTokenIsValid>>, TError = ErrorType<unknown>>(
	params: CheckTokenIsValidParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof checkTokenIsValid>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckTokenIsValid<TData = Awaited<ReturnType<typeof checkTokenIsValid>>, TError = ErrorType<unknown>>(
	params: CheckTokenIsValidParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof checkTokenIsValid>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useCheckTokenIsValid<TData = Awaited<ReturnType<typeof checkTokenIsValid>>, TError = ErrorType<unknown>>(
	params: CheckTokenIsValidParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useCheckTokenIsValid<TData = Awaited<ReturnType<typeof checkTokenIsValid>>, TError = ErrorType<unknown>>(
	params: CheckTokenIsValidParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof checkTokenIsValid>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getCheckTokenIsValidQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const createPasswordResetToken = (
	params: CreatePasswordResetTokenParams
) => {
      
      
	return customInstance<void>(
		{ url: "/api/password-reset",
			method: "POST",
			params
		}
	);
};
  


export const getCreatePasswordResetTokenMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPasswordResetToken>>, TError, { params: CreatePasswordResetTokenParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createPasswordResetToken>>, TError, { params: CreatePasswordResetTokenParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPasswordResetToken>>, { params: CreatePasswordResetTokenParams }> = (props) => {
		const { params } = props ?? {};

		return createPasswordResetToken(params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreatePasswordResetTokenMutationResult = NonNullable<Awaited<ReturnType<typeof createPasswordResetToken>>>;
    
export type CreatePasswordResetTokenMutationError = ErrorType<unknown>;

export const useCreatePasswordResetToken = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPasswordResetToken>>, TError, { params: CreatePasswordResetTokenParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createPasswordResetToken>>,
	TError,
	{ params: CreatePasswordResetTokenParams },
	TContext
> => {

	const mutationOptions = getCreatePasswordResetTokenMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const redeemPasswordResetToken = (
	passwordDTO: BodyType<PasswordDTO>,
	params: RedeemPasswordResetTokenParams
) => {
      
      
	return customInstance<void>(
		{ url: "/api/password-reset/update",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: passwordDTO,
			params
		}
	);
};
  


export const getRedeemPasswordResetTokenMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof redeemPasswordResetToken>>, TError, { data: BodyType<PasswordDTO>;params: RedeemPasswordResetTokenParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof redeemPasswordResetToken>>, TError, { data: BodyType<PasswordDTO>;params: RedeemPasswordResetTokenParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof redeemPasswordResetToken>>, { data: BodyType<PasswordDTO>;params: RedeemPasswordResetTokenParams }> = (props) => {
		const { data, params } = props ?? {};

		return redeemPasswordResetToken(data, params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RedeemPasswordResetTokenMutationResult = NonNullable<Awaited<ReturnType<typeof redeemPasswordResetToken>>>;
export type RedeemPasswordResetTokenMutationBody = BodyType<PasswordDTO>;
export type RedeemPasswordResetTokenMutationError = ErrorType<unknown>;

export const useRedeemPasswordResetToken = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof redeemPasswordResetToken>>, TError, { data: BodyType<PasswordDTO>;params: RedeemPasswordResetTokenParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof redeemPasswordResetToken>>,
	TError,
	{ data: BodyType<PasswordDTO>;params: RedeemPasswordResetTokenParams },
	TContext
> => {

	const mutationOptions = getRedeemPasswordResetTokenMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const sendUpdateEmail = (
	portalEmailUpdateReq: BodyType<PortalEmailUpdateReq>
) => {
      
      
	return customInstance<PayloadBoolean>(
		{ url: "/api/portal_email_update",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: portalEmailUpdateReq
		}
	);
};
  


export const getSendUpdateEmailMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendUpdateEmail>>, TError, { data: BodyType<PortalEmailUpdateReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof sendUpdateEmail>>, TError, { data: BodyType<PortalEmailUpdateReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendUpdateEmail>>, { data: BodyType<PortalEmailUpdateReq> }> = (props) => {
		const { data } = props ?? {};

		return sendUpdateEmail(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type SendUpdateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sendUpdateEmail>>>;
export type SendUpdateEmailMutationBody = BodyType<PortalEmailUpdateReq>;
export type SendUpdateEmailMutationError = ErrorType<unknown>;

export const useSendUpdateEmail = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof sendUpdateEmail>>, TError, { data: BodyType<PortalEmailUpdateReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof sendUpdateEmail>>,
	TError,
	{ data: BodyType<PortalEmailUpdateReq> },
	TContext
> => {

	const mutationOptions = getSendUpdateEmailMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const updateEmail = (
	portalEmailChangeRedemptionReq: BodyType<PortalEmailChangeRedemptionReq>
) => {
      
      
	return customInstance<PayloadBoolean>(
		{ url: "/api/portal_email_update/change_email",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: portalEmailChangeRedemptionReq
		}
	);
};
  


export const getUpdateEmailMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmail>>, TError, { data: BodyType<PortalEmailChangeRedemptionReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateEmail>>, TError, { data: BodyType<PortalEmailChangeRedemptionReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmail>>, { data: BodyType<PortalEmailChangeRedemptionReq> }> = (props) => {
		const { data } = props ?? {};

		return updateEmail(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmail>>>;
export type UpdateEmailMutationBody = BodyType<PortalEmailChangeRedemptionReq>;
export type UpdateEmailMutationError = ErrorType<unknown>;

export const useUpdateEmail = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateEmail>>, TError, { data: BodyType<PortalEmailChangeRedemptionReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateEmail>>,
	TError,
	{ data: BodyType<PortalEmailChangeRedemptionReq> },
	TContext
> => {

	const mutationOptions = getUpdateEmailMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get public key
 */
export const getPushNotificationPublicKey = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<string>(
		{ url: "/api/push_notifications/public_key", method: "GET", signal
		}
	);
};
  

export const getGetPushNotificationPublicKeyQueryKey = () => {
	return ["/api/push_notifications/public_key"] as const;
};

    
export const getGetPushNotificationPublicKeyQueryOptions = <TData = Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetPushNotificationPublicKeyQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getPushNotificationPublicKey>>> = ({ signal }) => getPushNotificationPublicKey(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData> & { queryKey: QueryKey };
};

export type GetPushNotificationPublicKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getPushNotificationPublicKey>>>;
export type GetPushNotificationPublicKeyQueryError = ErrorType<unknown>;


export function useGetPushNotificationPublicKey<TData = Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getPushNotificationPublicKey>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPushNotificationPublicKey<TData = Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getPushNotificationPublicKey>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPushNotificationPublicKey<TData = Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get public key
 */

export function useGetPushNotificationPublicKey<TData = Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPushNotificationPublicKey>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetPushNotificationPublicKeyQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Create push subscription
 */
export const createPushNotificationSubscription = (
	pushSubscriptionDTO: BodyType<PushSubscriptionDTO>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/push_notifications/subscribe",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: pushSubscriptionDTO
		}
	);
};
  


export const getCreatePushNotificationSubscriptionMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPushNotificationSubscription>>, TError, { data: BodyType<PushSubscriptionDTO> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createPushNotificationSubscription>>, TError, { data: BodyType<PushSubscriptionDTO> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPushNotificationSubscription>>, { data: BodyType<PushSubscriptionDTO> }> = (props) => {
		const { data } = props ?? {};

		return createPushNotificationSubscription(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreatePushNotificationSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof createPushNotificationSubscription>>>;
export type CreatePushNotificationSubscriptionMutationBody = BodyType<PushSubscriptionDTO>;
export type CreatePushNotificationSubscriptionMutationError = ErrorType<unknown>;

/**
 * @summary Create push subscription
 */
export const useCreatePushNotificationSubscription = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPushNotificationSubscription>>, TError, { data: BodyType<PushSubscriptionDTO> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createPushNotificationSubscription>>,
	TError,
	{ data: BodyType<PushSubscriptionDTO> },
	TContext
> => {

	const mutationOptions = getCreatePushNotificationSubscriptionMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Remove my push subscription
 */
export const removePushNotificationSubscription = (
    
) => {
      
      
	return customInstance<void>(
		{ url: "/api/push_notifications/unsubscribeEndpoint", method: "POST"
		}
	);
};
  


export const getRemovePushNotificationSubscriptionMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removePushNotificationSubscription>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof removePushNotificationSubscription>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof removePushNotificationSubscription>>, void> = () => {
          

		return removePushNotificationSubscription();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RemovePushNotificationSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof removePushNotificationSubscription>>>;
    
export type RemovePushNotificationSubscriptionMutationError = ErrorType<unknown>;

/**
 * @summary Remove my push subscription
 */
export const useRemovePushNotificationSubscription = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removePushNotificationSubscription>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof removePushNotificationSubscription>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getRemovePushNotificationSubscriptionMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getBusiestTeamReport = (
	params: GetBusiestTeamReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/busiest_team",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetBusiestTeamReportQueryKey = (params: GetBusiestTeamReportParams) => {
	return ["/api/reports/busiest_team", ...(params ? [params] : [])] as const;
};

    
export const getGetBusiestTeamReportQueryOptions = <TData = Awaited<ReturnType<typeof getBusiestTeamReport>>, TError = ErrorType<unknown>>(params: GetBusiestTeamReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetBusiestTeamReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusiestTeamReport>>> = ({ signal }) => getBusiestTeamReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetBusiestTeamReportQueryResult = NonNullable<Awaited<ReturnType<typeof getBusiestTeamReport>>>;
export type GetBusiestTeamReportQueryError = ErrorType<unknown>;


export function useGetBusiestTeamReport<TData = Awaited<ReturnType<typeof getBusiestTeamReport>>, TError = ErrorType<unknown>>(
	params: GetBusiestTeamReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getBusiestTeamReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBusiestTeamReport<TData = Awaited<ReturnType<typeof getBusiestTeamReport>>, TError = ErrorType<unknown>>(
	params: GetBusiestTeamReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getBusiestTeamReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBusiestTeamReport<TData = Awaited<ReturnType<typeof getBusiestTeamReport>>, TError = ErrorType<unknown>>(
	params: GetBusiestTeamReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetBusiestTeamReport<TData = Awaited<ReturnType<typeof getBusiestTeamReport>>, TError = ErrorType<unknown>>(
	params: GetBusiestTeamReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBusiestTeamReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetBusiestTeamReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getDashboardReport = (
	params: GetDashboardReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<ReportDashboard>(
		{ url: "/api/reports/dashboard",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetDashboardReportQueryKey = (params: GetDashboardReportParams) => {
	return ["/api/reports/dashboard", ...(params ? [params] : [])] as const;
};

    
export const getGetDashboardReportQueryOptions = <TData = Awaited<ReturnType<typeof getDashboardReport>>, TError = ErrorType<unknown>>(params: GetDashboardReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetDashboardReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardReport>>> = ({ signal }) => getDashboardReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetDashboardReportQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardReport>>>;
export type GetDashboardReportQueryError = ErrorType<unknown>;


export function useGetDashboardReport<TData = Awaited<ReturnType<typeof getDashboardReport>>, TError = ErrorType<unknown>>(
	params: GetDashboardReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getDashboardReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDashboardReport<TData = Awaited<ReturnType<typeof getDashboardReport>>, TError = ErrorType<unknown>>(
	params: GetDashboardReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getDashboardReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetDashboardReport<TData = Awaited<ReturnType<typeof getDashboardReport>>, TError = ErrorType<unknown>>(
	params: GetDashboardReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetDashboardReport<TData = Awaited<ReturnType<typeof getDashboardReport>>, TError = ErrorType<unknown>>(
	params: GetDashboardReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboardReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetDashboardReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getMissedSearchResultsReport = (
	params: GetMissedSearchResultsReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<GetMissedSearchResultsReport200>(
		{ url: "/api/reports/missed_search_results",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetMissedSearchResultsReportQueryKey = (params: GetMissedSearchResultsReportParams) => {
	return ["/api/reports/missed_search_results", ...(params ? [params] : [])] as const;
};

    
export const getGetMissedSearchResultsReportQueryOptions = <TData = Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError = ErrorType<unknown>>(params: GetMissedSearchResultsReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMissedSearchResultsReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMissedSearchResultsReport>>> = ({ signal }) => getMissedSearchResultsReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetMissedSearchResultsReportQueryResult = NonNullable<Awaited<ReturnType<typeof getMissedSearchResultsReport>>>;
export type GetMissedSearchResultsReportQueryError = ErrorType<unknown>;


export function useGetMissedSearchResultsReport<TData = Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError = ErrorType<unknown>>(
	params: GetMissedSearchResultsReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMissedSearchResultsReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMissedSearchResultsReport<TData = Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError = ErrorType<unknown>>(
	params: GetMissedSearchResultsReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMissedSearchResultsReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMissedSearchResultsReport<TData = Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError = ErrorType<unknown>>(
	params: GetMissedSearchResultsReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMissedSearchResultsReport<TData = Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError = ErrorType<unknown>>(
	params: GetMissedSearchResultsReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMissedSearchResultsReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetMissedSearchResultsReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTicketsByCategoryReport = (
	params: GetTicketsByCategoryReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/tickets_by_category",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTicketsByCategoryReportQueryKey = (params: GetTicketsByCategoryReportParams) => {
	return ["/api/reports/tickets_by_category", ...(params ? [params] : [])] as const;
};

    
export const getGetTicketsByCategoryReportQueryOptions = <TData = Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError = ErrorType<unknown>>(params: GetTicketsByCategoryReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketsByCategoryReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketsByCategoryReport>>> = ({ signal }) => getTicketsByCategoryReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketsByCategoryReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketsByCategoryReport>>>;
export type GetTicketsByCategoryReportQueryError = ErrorType<unknown>;


export function useGetTicketsByCategoryReport<TData = Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByCategoryReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByCategoryReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByCategoryReport<TData = Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByCategoryReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByCategoryReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByCategoryReport<TData = Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByCategoryReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketsByCategoryReport<TData = Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByCategoryReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByCategoryReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketsByCategoryReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTicketsByPriorityReport = (
	params: GetTicketsByPriorityReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/tickets_by_priority",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTicketsByPriorityReportQueryKey = (params: GetTicketsByPriorityReportParams) => {
	return ["/api/reports/tickets_by_priority", ...(params ? [params] : [])] as const;
};

    
export const getGetTicketsByPriorityReportQueryOptions = <TData = Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError = ErrorType<unknown>>(params: GetTicketsByPriorityReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketsByPriorityReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketsByPriorityReport>>> = ({ signal }) => getTicketsByPriorityReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketsByPriorityReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketsByPriorityReport>>>;
export type GetTicketsByPriorityReportQueryError = ErrorType<unknown>;


export function useGetTicketsByPriorityReport<TData = Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByPriorityReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByPriorityReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByPriorityReport<TData = Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByPriorityReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByPriorityReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByPriorityReport<TData = Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByPriorityReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketsByPriorityReport<TData = Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByPriorityReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByPriorityReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketsByPriorityReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTicketsByStatusReport = (
	params: GetTicketsByStatusReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/tickets_by_status",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTicketsByStatusReportQueryKey = (params: GetTicketsByStatusReportParams) => {
	return ["/api/reports/tickets_by_status", ...(params ? [params] : [])] as const;
};

    
export const getGetTicketsByStatusReportQueryOptions = <TData = Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError = ErrorType<unknown>>(params: GetTicketsByStatusReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketsByStatusReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketsByStatusReport>>> = ({ signal }) => getTicketsByStatusReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketsByStatusReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketsByStatusReport>>>;
export type GetTicketsByStatusReportQueryError = ErrorType<unknown>;


export function useGetTicketsByStatusReport<TData = Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByStatusReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByStatusReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByStatusReport<TData = Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByStatusReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsByStatusReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsByStatusReport<TData = Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByStatusReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketsByStatusReport<TData = Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsByStatusReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsByStatusReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketsByStatusReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTicketsPerCompanyReport = (
	params: GetTicketsPerCompanyReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/tickets_per_company",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTicketsPerCompanyReportQueryKey = (params: GetTicketsPerCompanyReportParams) => {
	return ["/api/reports/tickets_per_company", ...(params ? [params] : [])] as const;
};

    
export const getGetTicketsPerCompanyReportQueryOptions = <TData = Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError = ErrorType<unknown>>(params: GetTicketsPerCompanyReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketsPerCompanyReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>> = ({ signal }) => getTicketsPerCompanyReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketsPerCompanyReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>>;
export type GetTicketsPerCompanyReportQueryError = ErrorType<unknown>;


export function useGetTicketsPerCompanyReport<TData = Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerCompanyReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsPerCompanyReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsPerCompanyReport<TData = Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerCompanyReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsPerCompanyReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsPerCompanyReport<TData = Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerCompanyReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketsPerCompanyReport<TData = Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerCompanyReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerCompanyReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketsPerCompanyReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTicketsPerUserReport = (
	params: GetTicketsPerUserReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/tickets_per_user",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTicketsPerUserReportQueryKey = (params: GetTicketsPerUserReportParams) => {
	return ["/api/reports/tickets_per_user", ...(params ? [params] : [])] as const;
};

    
export const getGetTicketsPerUserReportQueryOptions = <TData = Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError = ErrorType<unknown>>(params: GetTicketsPerUserReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketsPerUserReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketsPerUserReport>>> = ({ signal }) => getTicketsPerUserReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketsPerUserReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketsPerUserReport>>>;
export type GetTicketsPerUserReportQueryError = ErrorType<unknown>;


export function useGetTicketsPerUserReport<TData = Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerUserReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsPerUserReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsPerUserReport<TData = Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerUserReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketsPerUserReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketsPerUserReport<TData = Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerUserReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketsPerUserReport<TData = Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError = ErrorType<unknown>>(
	params: GetTicketsPerUserReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketsPerUserReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketsPerUserReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTotalTicketRepliesReport = (
	params: GetTotalTicketRepliesReportParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/total_ticket_replies",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetTotalTicketRepliesReportQueryKey = (params: GetTotalTicketRepliesReportParams) => {
	return ["/api/reports/total_ticket_replies", ...(params ? [params] : [])] as const;
};

    
export const getGetTotalTicketRepliesReportQueryOptions = <TData = Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError = ErrorType<unknown>>(params: GetTotalTicketRepliesReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTotalTicketRepliesReportQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>> = ({ signal }) => getTotalTicketRepliesReport(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTotalTicketRepliesReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>>;
export type GetTotalTicketRepliesReportQueryError = ErrorType<unknown>;


export function useGetTotalTicketRepliesReport<TData = Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError = ErrorType<unknown>>(
	params: GetTotalTicketRepliesReportParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTotalTicketRepliesReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTotalTicketRepliesReport<TData = Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError = ErrorType<unknown>>(
	params: GetTotalTicketRepliesReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTotalTicketRepliesReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTotalTicketRepliesReport<TData = Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError = ErrorType<unknown>>(
	params: GetTotalTicketRepliesReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTotalTicketRepliesReport<TData = Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError = ErrorType<unknown>>(
	params: GetTotalTicketRepliesReportParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalTicketRepliesReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTotalTicketRepliesReportQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const getTotalUsersReport = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<number[][]>(
		{ url: "/api/reports/total_users", method: "GET", signal
		}
	);
};
  

export const getGetTotalUsersReportQueryKey = () => {
	return ["/api/reports/total_users"] as const;
};

    
export const getGetTotalUsersReportQueryOptions = <TData = Awaited<ReturnType<typeof getTotalUsersReport>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTotalUsersReportQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTotalUsersReport>>> = ({ signal }) => getTotalUsersReport(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTotalUsersReportQueryResult = NonNullable<Awaited<ReturnType<typeof getTotalUsersReport>>>;
export type GetTotalUsersReportQueryError = ErrorType<unknown>;


export function useGetTotalUsersReport<TData = Awaited<ReturnType<typeof getTotalUsersReport>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTotalUsersReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTotalUsersReport<TData = Awaited<ReturnType<typeof getTotalUsersReport>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTotalUsersReport>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTotalUsersReport<TData = Awaited<ReturnType<typeof getTotalUsersReport>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTotalUsersReport<TData = Awaited<ReturnType<typeof getTotalUsersReport>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalUsersReport>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTotalUsersReportQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const entitySearch = (
	params: EntitySearchParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadGlobalSearch>(
		{ url: "/api/search",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getEntitySearchQueryKey = (params: EntitySearchParams) => {
	return ["/api/search", ...(params ? [params] : [])] as const;
};

    
export const getEntitySearchQueryOptions = <TData = Awaited<ReturnType<typeof entitySearch>>, TError = ErrorType<unknown>>(params: EntitySearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getEntitySearchQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof entitySearch>>> = ({ signal }) => entitySearch(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData> & { queryKey: QueryKey };
};

export type EntitySearchQueryResult = NonNullable<Awaited<ReturnType<typeof entitySearch>>>;
export type EntitySearchQueryError = ErrorType<unknown>;


export function useEntitySearch<TData = Awaited<ReturnType<typeof entitySearch>>, TError = ErrorType<unknown>>(
	params: EntitySearchParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof entitySearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useEntitySearch<TData = Awaited<ReturnType<typeof entitySearch>>, TError = ErrorType<unknown>>(
	params: EntitySearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof entitySearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useEntitySearch<TData = Awaited<ReturnType<typeof entitySearch>>, TError = ErrorType<unknown>>(
	params: EntitySearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useEntitySearch<TData = Awaited<ReturnType<typeof entitySearch>>, TError = ErrorType<unknown>>(
	params: EntitySearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof entitySearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getEntitySearchQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const globalSearch = (
	params: GlobalSearchParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<GlobalSearch>(
		{ url: "/api/search/global",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGlobalSearchQueryKey = (params: GlobalSearchParams) => {
	return ["/api/search/global", ...(params ? [params] : [])] as const;
};

    
export const getGlobalSearchQueryOptions = <TData = Awaited<ReturnType<typeof globalSearch>>, TError = ErrorType<unknown>>(params: GlobalSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGlobalSearchQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof globalSearch>>> = ({ signal }) => globalSearch(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData> & { queryKey: QueryKey };
};

export type GlobalSearchQueryResult = NonNullable<Awaited<ReturnType<typeof globalSearch>>>;
export type GlobalSearchQueryError = ErrorType<unknown>;


export function useGlobalSearch<TData = Awaited<ReturnType<typeof globalSearch>>, TError = ErrorType<unknown>>(
	params: GlobalSearchParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof globalSearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGlobalSearch<TData = Awaited<ReturnType<typeof globalSearch>>, TError = ErrorType<unknown>>(
	params: GlobalSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof globalSearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGlobalSearch<TData = Awaited<ReturnType<typeof globalSearch>>, TError = ErrorType<unknown>>(
	params: GlobalSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGlobalSearch<TData = Awaited<ReturnType<typeof globalSearch>>, TError = ErrorType<unknown>>(
	params: GlobalSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof globalSearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGlobalSearchQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const kbPublicSearch = (
	params: KbPublicSearchParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadGlobalSearch>(
		{ url: "/api/search/kbarticle",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getKbPublicSearchQueryKey = (params: KbPublicSearchParams) => {
	return ["/api/search/kbarticle", ...(params ? [params] : [])] as const;
};

    
export const getKbPublicSearchQueryOptions = <TData = Awaited<ReturnType<typeof kbPublicSearch>>, TError = ErrorType<unknown>>(params: KbPublicSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getKbPublicSearchQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof kbPublicSearch>>> = ({ signal }) => kbPublicSearch(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData> & { queryKey: QueryKey };
};

export type KbPublicSearchQueryResult = NonNullable<Awaited<ReturnType<typeof kbPublicSearch>>>;
export type KbPublicSearchQueryError = ErrorType<unknown>;


export function useKbPublicSearch<TData = Awaited<ReturnType<typeof kbPublicSearch>>, TError = ErrorType<unknown>>(
	params: KbPublicSearchParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof kbPublicSearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useKbPublicSearch<TData = Awaited<ReturnType<typeof kbPublicSearch>>, TError = ErrorType<unknown>>(
	params: KbPublicSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof kbPublicSearch>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useKbPublicSearch<TData = Awaited<ReturnType<typeof kbPublicSearch>>, TError = ErrorType<unknown>>(
	params: KbPublicSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useKbPublicSearch<TData = Awaited<ReturnType<typeof kbPublicSearch>>, TError = ErrorType<unknown>>(
	params: KbPublicSearchParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof kbPublicSearch>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getKbPublicSearchQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Get all
 */
export const getAllSettings = (
	params?: GetAllSettingsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionSetting>(
		{ url: "/api/settings",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllSettingsQueryKey = (params?: GetAllSettingsParams) => {
	return ["/api/settings", ...(params ? [params] : [])] as const;
};

    
export const getGetAllSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getAllSettings>>, TError = ErrorType<unknown>>(params?: GetAllSettingsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllSettingsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSettings>>> = ({ signal }) => getAllSettings(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSettings>>>;
export type GetAllSettingsQueryError = ErrorType<unknown>;


export function useGetAllSettings<TData = Awaited<ReturnType<typeof getAllSettings>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllSettingsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllSettings>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllSettings<TData = Awaited<ReturnType<typeof getAllSettings>>, TError = ErrorType<unknown>>(
	params?: GetAllSettingsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllSettings>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllSettings<TData = Awaited<ReturnType<typeof getAllSettings>>, TError = ErrorType<unknown>>(
	params?: GetAllSettingsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllSettings<TData = Awaited<ReturnType<typeof getAllSettings>>, TError = ErrorType<unknown>>(
	params?: GetAllSettingsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSettings>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllSettingsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsSettings = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings", method: "HEAD", signal
		}
	);
};
  


export const getExistsSettingsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsSettings>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsSettings>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsSettings>>, void> = () => {
          

		return existsSettings();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof existsSettings>>>;
    
export type ExistsSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsSettings = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsSettings>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsSettings>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsSettingsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const updateSettings1 = (
	setting: BodyType<Setting[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings",
			method: "PATCH",
			headers: { "Content-Type": "application/json" },
			data: setting
		}
	);
};
  


export const getUpdateSettings1MutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSettings1>>, TError, { data: BodyType<Setting[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateSettings1>>, TError, { data: BodyType<Setting[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSettings1>>, { data: BodyType<Setting[]> }> = (props) => {
		const { data } = props ?? {};

		return updateSettings1(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateSettings1MutationResult = NonNullable<Awaited<ReturnType<typeof updateSettings1>>>;
export type UpdateSettings1MutationBody = BodyType<Setting[]>;
export type UpdateSettings1MutationError = ErrorType<unknown>;

export const useUpdateSettings1 = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSettings1>>, TError, { data: BodyType<Setting[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateSettings1>>,
	TError,
	{ data: BodyType<Setting[]> },
	TContext
> => {

	const mutationOptions = getUpdateSettings1MutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createSettings = (
	setting: BodyType<Setting>
) => {
      
      
	return customInstance<PayloadSetting>(
		{ url: "/api/settings",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: setting
		}
	);
};
  


export const getCreateSettingsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createSettings>>, TError, { data: BodyType<Setting> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createSettings>>, TError, { data: BodyType<Setting> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSettings>>, { data: BodyType<Setting> }> = (props) => {
		const { data } = props ?? {};

		return createSettings(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof createSettings>>>;
export type CreateSettingsMutationBody = BodyType<Setting>;
export type CreateSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateSettings = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createSettings>>, TError, { data: BodyType<Setting> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createSettings>>,
	TError,
	{ data: BodyType<Setting> },
	TContext
> => {

	const mutationOptions = getCreateSettingsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get the API's version number
 */
export const getApiVersion = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<number>(
		{ url: "/api/settings/api-version", method: "GET", signal
		}
	);
};
  

export const getGetApiVersionQueryKey = () => {
	return ["/api/settings/api-version"] as const;
};

    
export const getGetApiVersionQueryOptions = <TData = Awaited<ReturnType<typeof getApiVersion>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetApiVersionQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiVersion>>> = ({ signal }) => getApiVersion(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData> & { queryKey: QueryKey };
};

export type GetApiVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiVersion>>>;
export type GetApiVersionQueryError = ErrorType<unknown>;


export function useGetApiVersion<TData = Awaited<ReturnType<typeof getApiVersion>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getApiVersion>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiVersion<TData = Awaited<ReturnType<typeof getApiVersion>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getApiVersion>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetApiVersion<TData = Awaited<ReturnType<typeof getApiVersion>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get the API's version number
 */

export function useGetApiVersion<TData = Awaited<ReturnType<typeof getApiVersion>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiVersion>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetApiVersionQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const upsertBanner = (
	upsertImageReq: BodyType<UpsertImageReq[]>
) => {
      
      
	return customInstance<PayloadString>(
		{ url: "/api/settings/banner",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: upsertImageReq
		}
	);
};
  


export const getUpsertBannerMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertBanner>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof upsertBanner>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertBanner>>, { data: BodyType<UpsertImageReq[]> }> = (props) => {
		const { data } = props ?? {};

		return upsertBanner(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpsertBannerMutationResult = NonNullable<Awaited<ReturnType<typeof upsertBanner>>>;
export type UpsertBannerMutationBody = BodyType<UpsertImageReq[]>;
export type UpsertBannerMutationError = ErrorType<unknown>;

export const useUpsertBanner = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertBanner>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof upsertBanner>>,
	TError,
	{ data: BodyType<UpsertImageReq[]> },
	TContext
> => {

	const mutationOptions = getUpsertBannerMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteSettings = (
	bulkDeleteSettingsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteSettingsBody
		}
	);
};
  


export const getBulkDeleteSettingsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSettings>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSettings>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteSettings>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteSettings(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteSettings>>>;
export type BulkDeleteSettingsMutationBody = BodyType<number[]>;
export type BulkDeleteSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteSettings = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSettings>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteSettings>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteSettingsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Set a CNAME
 */
export const activateCname = (
	params: ActivateCnameParams
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings/cname",
			method: "POST",
			params
		}
	);
};
  


export const getActivateCnameMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof activateCname>>, TError, { params: ActivateCnameParams }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof activateCname>>, TError, { params: ActivateCnameParams }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateCname>>, { params: ActivateCnameParams }> = (props) => {
		const { params } = props ?? {};

		return activateCname(params);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ActivateCnameMutationResult = NonNullable<Awaited<ReturnType<typeof activateCname>>>;
    
export type ActivateCnameMutationError = ErrorType<unknown>;

/**
 * @summary Set a CNAME
 */
export const useActivateCname = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof activateCname>>, TError, { params: ActivateCnameParams }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof activateCname>>,
	TError,
	{ params: ActivateCnameParams },
	TContext
> => {

	const mutationOptions = getActivateCnameMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Check the status of a CNAME change
 */
export const getCnameChangeStatus = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<GetCnameChangeStatus200>(
		{ url: "/api/settings/cname-status", method: "GET", signal
		}
	);
};
  

export const getGetCnameChangeStatusQueryKey = () => {
	return ["/api/settings/cname-status"] as const;
};

    
export const getGetCnameChangeStatusQueryOptions = <TData = Awaited<ReturnType<typeof getCnameChangeStatus>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetCnameChangeStatusQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getCnameChangeStatus>>> = ({ signal }) => getCnameChangeStatus(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData> & { queryKey: QueryKey };
};

export type GetCnameChangeStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getCnameChangeStatus>>>;
export type GetCnameChangeStatusQueryError = ErrorType<unknown>;


export function useGetCnameChangeStatus<TData = Awaited<ReturnType<typeof getCnameChangeStatus>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCnameChangeStatus>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCnameChangeStatus<TData = Awaited<ReturnType<typeof getCnameChangeStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getCnameChangeStatus>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCnameChangeStatus<TData = Awaited<ReturnType<typeof getCnameChangeStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Check the status of a CNAME change
 */

export function useGetCnameChangeStatus<TData = Awaited<ReturnType<typeof getCnameChangeStatus>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCnameChangeStatus>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetCnameChangeStatusQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const deleteDemoData = (
    
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings/demodata", method: "DELETE"
		}
	);
};
  


export const getDeleteDemoDataMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteDemoData>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteDemoData>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDemoData>>, void> = () => {
          

		return deleteDemoData();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteDemoDataMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDemoData>>>;
    
export type DeleteDemoDataMutationError = ErrorType<unknown>;

export const useDeleteDemoData = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteDemoData>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteDemoData>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getDeleteDemoDataMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const upsertFavicon = (
	upsertImageReq: BodyType<UpsertImageReq[]>
) => {
      
      
	return customInstance<PayloadString>(
		{ url: "/api/settings/favicon",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: upsertImageReq
		}
	);
};
  


export const getUpsertFaviconMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertFavicon>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof upsertFavicon>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertFavicon>>, { data: BodyType<UpsertImageReq[]> }> = (props) => {
		const { data } = props ?? {};

		return upsertFavicon(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpsertFaviconMutationResult = NonNullable<Awaited<ReturnType<typeof upsertFavicon>>>;
export type UpsertFaviconMutationBody = BodyType<UpsertImageReq[]>;
export type UpsertFaviconMutationError = ErrorType<unknown>;

export const useUpsertFavicon = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertFavicon>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof upsertFavicon>>,
	TError,
	{ data: BodyType<UpsertImageReq[]> },
	TContext
> => {

	const mutationOptions = getUpsertFaviconMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getSettingsFromKeys = (
	params: GetSettingsFromKeysParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<Setting[]>(
		{ url: "/api/settings/from_keys",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetSettingsFromKeysQueryKey = (params: GetSettingsFromKeysParams) => {
	return ["/api/settings/from_keys", ...(params ? [params] : [])] as const;
};

    
export const getGetSettingsFromKeysQueryOptions = <TData = Awaited<ReturnType<typeof getSettingsFromKeys>>, TError = ErrorType<unknown>>(params: GetSettingsFromKeysParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetSettingsFromKeysQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettingsFromKeys>>> = ({ signal }) => getSettingsFromKeys(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData> & { queryKey: QueryKey };
};

export type GetSettingsFromKeysQueryResult = NonNullable<Awaited<ReturnType<typeof getSettingsFromKeys>>>;
export type GetSettingsFromKeysQueryError = ErrorType<unknown>;


export function useGetSettingsFromKeys<TData = Awaited<ReturnType<typeof getSettingsFromKeys>>, TError = ErrorType<unknown>>(
	params: GetSettingsFromKeysParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getSettingsFromKeys>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSettingsFromKeys<TData = Awaited<ReturnType<typeof getSettingsFromKeys>>, TError = ErrorType<unknown>>(
	params: GetSettingsFromKeysParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getSettingsFromKeys>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSettingsFromKeys<TData = Awaited<ReturnType<typeof getSettingsFromKeys>>, TError = ErrorType<unknown>>(
	params: GetSettingsFromKeysParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetSettingsFromKeys<TData = Awaited<ReturnType<typeof getSettingsFromKeys>>, TError = ErrorType<unknown>>(
	params: GetSettingsFromKeysParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSettingsFromKeys>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetSettingsFromKeysQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const upsertImage = (
	upsertImageReq: BodyType<UpsertImageReq[]>
) => {
      
      
	return customInstance<PayloadString>(
		{ url: "/api/settings/logo",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: upsertImageReq
		}
	);
};
  


export const getUpsertImageMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertImage>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof upsertImage>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertImage>>, { data: BodyType<UpsertImageReq[]> }> = (props) => {
		const { data } = props ?? {};

		return upsertImage(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpsertImageMutationResult = NonNullable<Awaited<ReturnType<typeof upsertImage>>>;
export type UpsertImageMutationBody = BodyType<UpsertImageReq[]>;
export type UpsertImageMutationError = ErrorType<unknown>;

export const useUpsertImage = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof upsertImage>>, TError, { data: BodyType<UpsertImageReq[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof upsertImage>>,
	TError,
	{ data: BodyType<UpsertImageReq[]> },
	TContext
> => {

	const mutationOptions = getUpsertImageMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Remove a CNAME
 */
export const removeCname = (
    
) => {
      
      
	return customInstance<void>(
		{ url: "/api/settings/remove-cname", method: "POST"
		}
	);
};
  


export const getRemoveCnameMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeCname>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof removeCname>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeCname>>, void> = () => {
          

		return removeCname();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type RemoveCnameMutationResult = NonNullable<Awaited<ReturnType<typeof removeCname>>>;
    
export type RemoveCnameMutationError = ErrorType<unknown>;

/**
 * @summary Remove a CNAME
 */
export const useRemoveCname = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof removeCname>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof removeCname>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getRemoveCnameMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteSettings = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/settings/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteSettingsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteSettings>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteSettings>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSettings>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteSettings(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSettings>>>;
    
export type DeleteSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteSettings = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteSettings>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteSettings>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteSettingsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneSettings = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadSetting>(
		{ url: `/api/settings/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneSettingsQueryKey = (id: number) => {
	return [`/api/settings/${id}`] as const;
};

    
export const getGetOneSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getOneSettings>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneSettingsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneSettings>>> = ({ signal }) => getOneSettings(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneSettings>>>;
export type GetOneSettingsQueryError = ErrorType<unknown>;


export function useGetOneSettings<TData = Awaited<ReturnType<typeof getOneSettings>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneSettings>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneSettings<TData = Awaited<ReturnType<typeof getOneSettings>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneSettings>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneSettings<TData = Awaited<ReturnType<typeof getOneSettings>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneSettings<TData = Awaited<ReturnType<typeof getOneSettings>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSettings>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneSettingsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateSettings = (
	id: number,
	setting: BodyType<Setting>
) => {
      
      
	return customInstance<PayloadSetting>(
		{ url: `/api/settings/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: setting
		}
	);
};
  


export const getUpdateSettingsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSettings>>, TError, { id: number;data: BodyType<Setting> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateSettings>>, TError, { id: number;data: BodyType<Setting> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSettings>>, { id: number;data: BodyType<Setting> }> = (props) => {
		const { id, data } = props ?? {};

		return updateSettings(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateSettings>>>;
export type UpdateSettingsMutationBody = BodyType<Setting>;
export type UpdateSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateSettings = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSettings>>, TError, { id: number;data: BodyType<Setting> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateSettings>>,
	TError,
	{ id: number;data: BodyType<Setting> },
	TContext
> => {

	const mutationOptions = getUpdateSettingsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllSLAs = (
	params?: GetAllSLAsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionSla>(
		{ url: "/api/slas",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllSLAsQueryKey = (params?: GetAllSLAsParams) => {
	return ["/api/slas", ...(params ? [params] : [])] as const;
};

    
export const getGetAllSLAsQueryOptions = <TData = Awaited<ReturnType<typeof getAllSLAs>>, TError = ErrorType<unknown>>(params?: GetAllSLAsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllSLAsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSLAs>>> = ({ signal }) => getAllSLAs(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllSLAsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSLAs>>>;
export type GetAllSLAsQueryError = ErrorType<unknown>;


export function useGetAllSLAs<TData = Awaited<ReturnType<typeof getAllSLAs>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllSLAsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllSLAs>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllSLAs<TData = Awaited<ReturnType<typeof getAllSLAs>>, TError = ErrorType<unknown>>(
	params?: GetAllSLAsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllSLAs>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllSLAs<TData = Awaited<ReturnType<typeof getAllSLAs>>, TError = ErrorType<unknown>>(
	params?: GetAllSLAsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllSLAs<TData = Awaited<ReturnType<typeof getAllSLAs>>, TError = ErrorType<unknown>>(
	params?: GetAllSLAsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllSLAs>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllSLAsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsSLAs = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/slas", method: "HEAD", signal
		}
	);
};
  


export const getExistsSLAsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsSLAs>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsSLAs>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsSLAs>>, void> = () => {
          

		return existsSLAs();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsSLAsMutationResult = NonNullable<Awaited<ReturnType<typeof existsSLAs>>>;
    
export type ExistsSLAsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsSLAs = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsSLAs>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsSLAs>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsSLAsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createSLAs = (
	sla: BodyType<Sla>
) => {
      
      
	return customInstance<Sla>(
		{ url: "/api/slas",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: sla
		}
	);
};
  


export const getCreateSLAsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createSLAs>>, TError, { data: BodyType<Sla> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createSLAs>>, TError, { data: BodyType<Sla> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSLAs>>, { data: BodyType<Sla> }> = (props) => {
		const { data } = props ?? {};

		return createSLAs(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateSLAsMutationResult = NonNullable<Awaited<ReturnType<typeof createSLAs>>>;
export type CreateSLAsMutationBody = BodyType<Sla>;
export type CreateSLAsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateSLAs = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createSLAs>>, TError, { data: BodyType<Sla> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createSLAs>>,
	TError,
	{ data: BodyType<Sla> },
	TContext
> => {

	const mutationOptions = getCreateSLAsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteSLAs = (
	bulkDeleteSLAsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/slas/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteSLAsBody
		}
	);
};
  


export const getBulkDeleteSLAsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSLAs>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSLAs>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteSLAs>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteSLAs(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteSLAsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteSLAs>>>;
export type BulkDeleteSLAsMutationBody = BodyType<number[]>;
export type BulkDeleteSLAsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteSLAs = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteSLAs>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteSLAs>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteSLAsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteSLAs = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/slas/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteSLAsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteSLAs>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteSLAs>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSLAs>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteSLAs(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteSLAsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSLAs>>>;
    
export type DeleteSLAsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteSLAs = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteSLAs>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteSLAs>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteSLAsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneSLAs = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<Sla>(
		{ url: `/api/slas/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneSLAsQueryKey = (id: number) => {
	return [`/api/slas/${id}`] as const;
};

    
export const getGetOneSLAsQueryOptions = <TData = Awaited<ReturnType<typeof getOneSLAs>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneSLAsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneSLAs>>> = ({ signal }) => getOneSLAs(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneSLAsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneSLAs>>>;
export type GetOneSLAsQueryError = ErrorType<unknown>;


export function useGetOneSLAs<TData = Awaited<ReturnType<typeof getOneSLAs>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneSLAs>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneSLAs<TData = Awaited<ReturnType<typeof getOneSLAs>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneSLAs>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneSLAs<TData = Awaited<ReturnType<typeof getOneSLAs>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneSLAs<TData = Awaited<ReturnType<typeof getOneSLAs>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneSLAs>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneSLAsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateSLAs = (
	id: number,
	sla: BodyType<Sla>
) => {
      
      
	return customInstance<Sla>(
		{ url: `/api/slas/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: sla
		}
	);
};
  


export const getUpdateSLAsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSLAs>>, TError, { id: number;data: BodyType<Sla> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateSLAs>>, TError, { id: number;data: BodyType<Sla> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSLAs>>, { id: number;data: BodyType<Sla> }> = (props) => {
		const { id, data } = props ?? {};

		return updateSLAs(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateSLAsMutationResult = NonNullable<Awaited<ReturnType<typeof updateSLAs>>>;
export type UpdateSLAsMutationBody = BodyType<Sla>;
export type UpdateSLAsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateSLAs = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateSLAs>>, TError, { id: number;data: BodyType<Sla> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateSLAs>>,
	TError,
	{ id: number;data: BodyType<Sla> },
	TContext
> => {

	const mutationOptions = getUpdateSLAsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllTeams = (
	params?: GetAllTeamsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionTeam>(
		{ url: "/api/teams",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllTeamsQueryKey = (params?: GetAllTeamsParams) => {
	return ["/api/teams", ...(params ? [params] : [])] as const;
};

    
export const getGetAllTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getAllTeams>>, TError = ErrorType<unknown>>(params?: GetAllTeamsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllTeamsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTeams>>> = ({ signal }) => getAllTeams(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTeams>>>;
export type GetAllTeamsQueryError = ErrorType<unknown>;


export function useGetAllTeams<TData = Awaited<ReturnType<typeof getAllTeams>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllTeamsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllTeams>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllTeams<TData = Awaited<ReturnType<typeof getAllTeams>>, TError = ErrorType<unknown>>(
	params?: GetAllTeamsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllTeams>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllTeams<TData = Awaited<ReturnType<typeof getAllTeams>>, TError = ErrorType<unknown>>(
	params?: GetAllTeamsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllTeams<TData = Awaited<ReturnType<typeof getAllTeams>>, TError = ErrorType<unknown>>(
	params?: GetAllTeamsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllTeamsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsTeams = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/teams", method: "HEAD", signal
		}
	);
};
  


export const getExistsTeamsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsTeams>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsTeams>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsTeams>>, void> = () => {
          

		return existsTeams();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof existsTeams>>>;
    
export type ExistsTeamsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsTeams = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsTeams>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsTeams>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsTeamsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createTeams = (
	team: BodyType<Team>
) => {
      
      
	return customInstance<PayloadTeam>(
		{ url: "/api/teams",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: team
		}
	);
};
  


export const getCreateTeamsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTeams>>, TError, { data: BodyType<Team> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createTeams>>, TError, { data: BodyType<Team> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTeams>>, { data: BodyType<Team> }> = (props) => {
		const { data } = props ?? {};

		return createTeams(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof createTeams>>>;
export type CreateTeamsMutationBody = BodyType<Team>;
export type CreateTeamsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateTeams = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTeams>>, TError, { data: BodyType<Team> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createTeams>>,
	TError,
	{ data: BodyType<Team> },
	TContext
> => {

	const mutationOptions = getCreateTeamsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteTeams = (
	bulkDeleteTeamsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/teams/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteTeamsBody
		}
	);
};
  


export const getBulkDeleteTeamsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTeams>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTeams>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteTeams>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteTeams(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteTeams>>>;
export type BulkDeleteTeamsMutationBody = BodyType<number[]>;
export type BulkDeleteTeamsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteTeams = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTeams>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteTeams>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteTeamsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteTeams = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/teams/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteTeamsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeams>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTeams>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTeams>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteTeams(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTeams>>>;
    
export type DeleteTeamsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteTeams = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeams>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteTeams>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteTeamsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneTeams = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadTeam>(
		{ url: `/api/teams/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneTeamsQueryKey = (id: number) => {
	return [`/api/teams/${id}`] as const;
};

    
export const getGetOneTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getOneTeams>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneTeamsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneTeams>>> = ({ signal }) => getOneTeams(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneTeams>>>;
export type GetOneTeamsQueryError = ErrorType<unknown>;


export function useGetOneTeams<TData = Awaited<ReturnType<typeof getOneTeams>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneTeams>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneTeams<TData = Awaited<ReturnType<typeof getOneTeams>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneTeams>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneTeams<TData = Awaited<ReturnType<typeof getOneTeams>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneTeams<TData = Awaited<ReturnType<typeof getOneTeams>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTeams>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneTeamsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateTeams = (
	id: number,
	team: BodyType<Team>
) => {
      
      
	return customInstance<PayloadTeam>(
		{ url: `/api/teams/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: team
		}
	);
};
  


export const getUpdateTeamsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateTeams>>, TError, { id: number;data: BodyType<Team> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateTeams>>, TError, { id: number;data: BodyType<Team> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTeams>>, { id: number;data: BodyType<Team> }> = (props) => {
		const { id, data } = props ?? {};

		return updateTeams(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateTeamsMutationResult = NonNullable<Awaited<ReturnType<typeof updateTeams>>>;
export type UpdateTeamsMutationBody = BodyType<Team>;
export type UpdateTeamsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateTeams = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateTeams>>, TError, { id: number;data: BodyType<Team> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateTeams>>,
	TError,
	{ id: number;data: BodyType<Team> },
	TContext
> => {

	const mutationOptions = getUpdateTeamsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Move all tickets
 */
export const moveAllTickets = (
	id: number,
	moveAllTicketsReq: BodyType<MoveAllTicketsReq>
) => {
      
      
	return customInstance<void>(
		{ url: `/api/teams/${encodeURIComponent(String(id))}/move_all_tickets`,
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: moveAllTicketsReq
		}
	);
};
  


export const getMoveAllTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof moveAllTickets>>, TError, { id: number;data: BodyType<MoveAllTicketsReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof moveAllTickets>>, TError, { id: number;data: BodyType<MoveAllTicketsReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveAllTickets>>, { id: number;data: BodyType<MoveAllTicketsReq> }> = (props) => {
		const { id, data } = props ?? {};

		return moveAllTickets(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type MoveAllTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof moveAllTickets>>>;
export type MoveAllTicketsMutationBody = BodyType<MoveAllTicketsReq>;
export type MoveAllTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Move all tickets
 */
export const useMoveAllTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof moveAllTickets>>, TError, { id: number;data: BodyType<MoveAllTicketsReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof moveAllTickets>>,
	TError,
	{ id: number;data: BodyType<MoveAllTicketsReq> },
	TContext
> => {

	const mutationOptions = getMoveAllTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const createEvent = (
	metricEvent: BodyType<MetricEvent>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/tele/agent",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: metricEvent
		}
	);
};
  


export const getCreateEventMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEvent>>, TError, { data: BodyType<MetricEvent> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createEvent>>, TError, { data: BodyType<MetricEvent> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEvent>>, { data: BodyType<MetricEvent> }> = (props) => {
		const { data } = props ?? {};

		return createEvent(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateEventMutationResult = NonNullable<Awaited<ReturnType<typeof createEvent>>>;
export type CreateEventMutationBody = BodyType<MetricEvent>;
export type CreateEventMutationError = ErrorType<unknown>;

export const useCreateEvent = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createEvent>>, TError, { data: BodyType<MetricEvent> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createEvent>>,
	TError,
	{ data: BodyType<MetricEvent> },
	TContext
> => {

	const mutationOptions = getCreateEventMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getTicketCounts = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<TicketCounts>(
		{ url: "/api/ticketcounts", method: "GET", signal
		}
	);
};
  

export const getGetTicketCountsQueryKey = () => {
	return ["/api/ticketcounts"] as const;
};

    
export const getGetTicketCountsQueryOptions = <TData = Awaited<ReturnType<typeof getTicketCounts>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetTicketCountsQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketCounts>>> = ({ signal }) => getTicketCounts(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData> & { queryKey: QueryKey };
};

export type GetTicketCountsQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketCounts>>>;
export type GetTicketCountsQueryError = ErrorType<unknown>;


export function useGetTicketCounts<TData = Awaited<ReturnType<typeof getTicketCounts>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketCounts>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketCounts<TData = Awaited<ReturnType<typeof getTicketCounts>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getTicketCounts>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTicketCounts<TData = Awaited<ReturnType<typeof getTicketCounts>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTicketCounts<TData = Awaited<ReturnType<typeof getTicketCounts>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketCounts>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetTicketCountsQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Get all
 */
export const getAllTickets = (
	params?: GetAllTicketsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionTicket>(
		{ url: "/api/tickets",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllTicketsQueryKey = (params?: GetAllTicketsParams) => {
	return ["/api/tickets", ...(params ? [params] : [])] as const;
};

    
export const getGetAllTicketsQueryOptions = <TData = Awaited<ReturnType<typeof getAllTickets>>, TError = ErrorType<unknown>>(params?: GetAllTicketsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllTicketsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTickets>>> = ({ signal }) => getAllTickets(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllTicketsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTickets>>>;
export type GetAllTicketsQueryError = ErrorType<unknown>;


export function useGetAllTickets<TData = Awaited<ReturnType<typeof getAllTickets>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllTicketsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllTickets>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllTickets<TData = Awaited<ReturnType<typeof getAllTickets>>, TError = ErrorType<unknown>>(
	params?: GetAllTicketsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllTickets>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllTickets<TData = Awaited<ReturnType<typeof getAllTickets>>, TError = ErrorType<unknown>>(
	params?: GetAllTicketsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllTickets<TData = Awaited<ReturnType<typeof getAllTickets>>, TError = ErrorType<unknown>>(
	params?: GetAllTicketsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTickets>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllTicketsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsTickets = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/tickets", method: "HEAD", signal
		}
	);
};
  


export const getExistsTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsTickets>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsTickets>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsTickets>>, void> = () => {
          

		return existsTickets();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof existsTickets>>>;
    
export type ExistsTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsTickets>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsTickets>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createTickets = (
	ticket: BodyType<Ticket>
) => {
      
      
	return customInstance<PayloadTicket>(
		{ url: "/api/tickets",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: ticket
		}
	);
};
  


export const getCreateTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTickets>>, TError, { data: BodyType<Ticket> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createTickets>>, TError, { data: BodyType<Ticket> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTickets>>, { data: BodyType<Ticket> }> = (props) => {
		const { data } = props ?? {};

		return createTickets(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof createTickets>>>;
export type CreateTicketsMutationBody = BodyType<Ticket>;
export type CreateTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTickets>>, TError, { data: BodyType<Ticket> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createTickets>>,
	TError,
	{ data: BodyType<Ticket> },
	TContext
> => {

	const mutationOptions = getCreateTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteTickets = (
	bulkDeleteTicketsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/tickets/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteTicketsBody
		}
	);
};
  


export const getBulkDeleteTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTickets>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTickets>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteTickets>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteTickets(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteTickets>>>;
export type BulkDeleteTicketsMutationBody = BodyType<number[]>;
export type BulkDeleteTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteTickets>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteTickets>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const exportTickets = (
	ticketExportReq: BodyType<TicketExportReq>
) => {
      
      
	return customInstance<TicketList[]>(
		{ url: "/api/tickets/export",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: ticketExportReq
		}
	);
};
  


export const getExportTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof exportTickets>>, TError, { data: BodyType<TicketExportReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof exportTickets>>, TError, { data: BodyType<TicketExportReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportTickets>>, { data: BodyType<TicketExportReq> }> = (props) => {
		const { data } = props ?? {};

		return exportTickets(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExportTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof exportTickets>>>;
export type ExportTicketsMutationBody = BodyType<TicketExportReq>;
export type ExportTicketsMutationError = ErrorType<unknown>;

export const useExportTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof exportTickets>>, TError, { data: BodyType<TicketExportReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof exportTickets>>,
	TError,
	{ data: BodyType<TicketExportReq> },
	TContext
> => {

	const mutationOptions = getExportTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const massUpdateTickets = (
	ticketMassUpdateReq: BodyType<TicketMassUpdateReq>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/tickets/mass_update",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: ticketMassUpdateReq
		}
	);
};
  


export const getMassUpdateTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateTickets>>, TError, { data: BodyType<TicketMassUpdateReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof massUpdateTickets>>, TError, { data: BodyType<TicketMassUpdateReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof massUpdateTickets>>, { data: BodyType<TicketMassUpdateReq> }> = (props) => {
		const { data } = props ?? {};

		return massUpdateTickets(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type MassUpdateTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof massUpdateTickets>>>;
export type MassUpdateTicketsMutationBody = BodyType<TicketMassUpdateReq>;
export type MassUpdateTicketsMutationError = ErrorType<unknown>;

export const useMassUpdateTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateTickets>>, TError, { data: BodyType<TicketMassUpdateReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof massUpdateTickets>>,
	TError,
	{ data: BodyType<TicketMassUpdateReq> },
	TContext
> => {

	const mutationOptions = getMassUpdateTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Merge tickets
 */
export const mergeTickets = (
	mergeTicketsReq: BodyType<MergeTicketsReq>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/tickets/merge",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: mergeTicketsReq
		}
	);
};
  


export const getMergeTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof mergeTickets>>, TError, { data: BodyType<MergeTicketsReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof mergeTickets>>, TError, { data: BodyType<MergeTicketsReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof mergeTickets>>, { data: BodyType<MergeTicketsReq> }> = (props) => {
		const { data } = props ?? {};

		return mergeTickets(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type MergeTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof mergeTickets>>>;
export type MergeTicketsMutationBody = BodyType<MergeTicketsReq>;
export type MergeTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Merge tickets
 */
export const useMergeTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof mergeTickets>>, TError, { data: BodyType<MergeTicketsReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof mergeTickets>>,
	TError,
	{ data: BodyType<MergeTicketsReq> },
	TContext
> => {

	const mutationOptions = getMergeTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteTickets = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/tickets/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTickets>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTickets>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTickets>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteTickets(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTickets>>>;
    
export type DeleteTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTickets>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteTickets>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneTickets = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadTicket>(
		{ url: `/api/tickets/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneTicketsQueryKey = (id: number) => {
	return [`/api/tickets/${id}`] as const;
};

    
export const getGetOneTicketsQueryOptions = <TData = Awaited<ReturnType<typeof getOneTickets>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneTicketsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneTickets>>> = ({ signal }) => getOneTickets(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneTicketsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneTickets>>>;
export type GetOneTicketsQueryError = ErrorType<unknown>;


export function useGetOneTickets<TData = Awaited<ReturnType<typeof getOneTickets>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneTickets>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneTickets<TData = Awaited<ReturnType<typeof getOneTickets>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneTickets>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneTickets<TData = Awaited<ReturnType<typeof getOneTickets>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneTickets<TData = Awaited<ReturnType<typeof getOneTickets>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneTickets>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneTicketsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateTickets = (
	id: number,
	ticket: BodyType<Ticket>
) => {
      
      
	return customInstance<PayloadTicket>(
		{ url: `/api/tickets/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: ticket
		}
	);
};
  


export const getUpdateTicketsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateTickets>>, TError, { id: number;data: BodyType<Ticket> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateTickets>>, TError, { id: number;data: BodyType<Ticket> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTickets>>, { id: number;data: BodyType<Ticket> }> = (props) => {
		const { id, data } = props ?? {};

		return updateTickets(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof updateTickets>>>;
export type UpdateTicketsMutationBody = BodyType<Ticket>;
export type UpdateTicketsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateTickets = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateTickets>>, TError, { id: number;data: BodyType<Ticket> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateTickets>>,
	TError,
	{ id: number;data: BodyType<Ticket> },
	TContext
> => {

	const mutationOptions = getUpdateTicketsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Invite user
 */
export const inviteNewUser = (
	userInvite: BodyType<UserInvite>
) => {
      
      
	return customInstance<PayloadBoolean>(
		{ url: "/api/user_invites/create_account",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: userInvite
		}
	);
};
  


export const getInviteNewUserMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteNewUser>>, TError, { data: BodyType<UserInvite> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof inviteNewUser>>, TError, { data: BodyType<UserInvite> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteNewUser>>, { data: BodyType<UserInvite> }> = (props) => {
		const { data } = props ?? {};

		return inviteNewUser(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type InviteNewUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteNewUser>>>;
export type InviteNewUserMutationBody = BodyType<UserInvite>;
export type InviteNewUserMutationError = ErrorType<unknown>;

/**
 * @summary Invite user
 */
export const useInviteNewUser = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteNewUser>>, TError, { data: BodyType<UserInvite> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof inviteNewUser>>,
	TError,
	{ data: BodyType<UserInvite> },
	TContext
> => {

	const mutationOptions = getInviteNewUserMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Redeem agent invite
 */
export const inviteExistingUser = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/user_invites/invite_user/${encodeURIComponent(String(id))}`, method: "POST"
		}
	);
};
  


export const getInviteExistingUserMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteExistingUser>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof inviteExistingUser>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteExistingUser>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return inviteExistingUser(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type InviteExistingUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteExistingUser>>>;
    
export type InviteExistingUserMutationError = ErrorType<unknown>;

/**
 * @summary Redeem agent invite
 */
export const useInviteExistingUser = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteExistingUser>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof inviteExistingUser>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getInviteExistingUserMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Invite users
 */
export const inviteUsers = (
	multiUserInvite: BodyType<MultiUserInvite>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/user_invites/multi_user_invite",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: multiUserInvite
		}
	);
};
  


export const getInviteUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteUsers>>, TError, { data: BodyType<MultiUserInvite> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof inviteUsers>>, TError, { data: BodyType<MultiUserInvite> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUsers>>, { data: BodyType<MultiUserInvite> }> = (props) => {
		const { data } = props ?? {};

		return inviteUsers(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type InviteUsersMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUsers>>>;
export type InviteUsersMutationBody = BodyType<MultiUserInvite>;
export type InviteUsersMutationError = ErrorType<unknown>;

/**
 * @summary Invite users
 */
export const useInviteUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof inviteUsers>>, TError, { data: BodyType<MultiUserInvite> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof inviteUsers>>,
	TError,
	{ data: BodyType<MultiUserInvite> },
	TContext
> => {

	const mutationOptions = getInviteUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Redeem user invite
 */
export const userInviteRedeem = (
	userInviteRedeem: BodyType<UserInviteRedeem>
) => {
      
      
	return customInstance<PayloadBoolean>(
		{ url: "/api/user_invites/redeem",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: userInviteRedeem
		}
	);
};
  


export const getUserInviteRedeemMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof userInviteRedeem>>, TError, { data: BodyType<UserInviteRedeem> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof userInviteRedeem>>, TError, { data: BodyType<UserInviteRedeem> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof userInviteRedeem>>, { data: BodyType<UserInviteRedeem> }> = (props) => {
		const { data } = props ?? {};

		return userInviteRedeem(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UserInviteRedeemMutationResult = NonNullable<Awaited<ReturnType<typeof userInviteRedeem>>>;
export type UserInviteRedeemMutationBody = BodyType<UserInviteRedeem>;
export type UserInviteRedeemMutationError = ErrorType<unknown>;

/**
 * @summary Redeem user invite
 */
export const useUserInviteRedeem = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof userInviteRedeem>>, TError, { data: BodyType<UserInviteRedeem> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof userInviteRedeem>>,
	TError,
	{ data: BodyType<UserInviteRedeem> },
	TContext
> => {

	const mutationOptions = getUserInviteRedeemMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllUsers = (
	params?: GetAllUsersParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionUser>(
		{ url: "/api/users",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams) => {
	return ["/api/users", ...(params ? [params] : [])] as const;
};

    
export const getGetAllUsersQueryOptions = <TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<unknown>>(params?: GetAllUsersParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUsers>>> = ({ signal }) => getAllUsers(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUsers>>>;
export type GetAllUsersQueryError = ErrorType<unknown>;


export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllUsersParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllUsers>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<unknown>>(
	params?: GetAllUsersParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllUsers>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<unknown>>(
	params?: GetAllUsersParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllUsers<TData = Awaited<ReturnType<typeof getAllUsers>>, TError = ErrorType<unknown>>(
	params?: GetAllUsersParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllUsersQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsUsers = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/users", method: "HEAD", signal
		}
	);
};
  


export const getExistsUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsUsers>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsUsers>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsUsers>>, void> = () => {
          

		return existsUsers();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsUsersMutationResult = NonNullable<Awaited<ReturnType<typeof existsUsers>>>;
    
export type ExistsUsersMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsUsers>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsUsers>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createUsers = (
	createUsersBody: BodyType<CreateUsersBody>
) => {
      
      
	return customInstance<PayloadUser>(
		{ url: "/api/users",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: createUsersBody
		}
	);
};
  


export const getCreateUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createUsers>>, TError, { data: BodyType<CreateUsersBody> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createUsers>>, TError, { data: BodyType<CreateUsersBody> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUsers>>, { data: BodyType<CreateUsersBody> }> = (props) => {
		const { data } = props ?? {};

		return createUsers(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateUsersMutationResult = NonNullable<Awaited<ReturnType<typeof createUsers>>>;
export type CreateUsersMutationBody = BodyType<CreateUsersBody>;
export type CreateUsersMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createUsers>>, TError, { data: BodyType<CreateUsersBody> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createUsers>>,
	TError,
	{ data: BodyType<CreateUsersBody> },
	TContext
> => {

	const mutationOptions = getCreateUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteUsers = (
	bulkDeleteUsersBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/users/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteUsersBody
		}
	);
};
  


export const getBulkDeleteUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteUsers>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteUsers>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteUsers>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteUsers(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteUsersMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteUsers>>>;
export type BulkDeleteUsersMutationBody = BodyType<number[]>;
export type BulkDeleteUsersMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteUsers>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteUsers>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getLoggedInUser = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<GetLoggedInUser200>(
		{ url: "/api/users/logged_in_user", method: "GET", signal
		}
	);
};
  

export const getGetLoggedInUserQueryKey = () => {
	return ["/api/users/logged_in_user"] as const;
};

    
export const getGetLoggedInUserQueryOptions = <TData = Awaited<ReturnType<typeof getLoggedInUser>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetLoggedInUserQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoggedInUser>>> = ({ signal }) => getLoggedInUser(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData> & { queryKey: QueryKey };
};

export type GetLoggedInUserQueryResult = NonNullable<Awaited<ReturnType<typeof getLoggedInUser>>>;
export type GetLoggedInUserQueryError = ErrorType<unknown>;


export function useGetLoggedInUser<TData = Awaited<ReturnType<typeof getLoggedInUser>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getLoggedInUser>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedInUser<TData = Awaited<ReturnType<typeof getLoggedInUser>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getLoggedInUser>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLoggedInUser<TData = Awaited<ReturnType<typeof getLoggedInUser>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetLoggedInUser<TData = Awaited<ReturnType<typeof getLoggedInUser>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUser>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetLoggedInUserQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



export const massUpdateUsers = (
	userMassUpdateReq: BodyType<UserMassUpdateReq>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/users/mass_update",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: userMassUpdateReq
		}
	);
};
  


export const getMassUpdateUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateUsers>>, TError, { data: BodyType<UserMassUpdateReq> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof massUpdateUsers>>, TError, { data: BodyType<UserMassUpdateReq> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof massUpdateUsers>>, { data: BodyType<UserMassUpdateReq> }> = (props) => {
		const { data } = props ?? {};

		return massUpdateUsers(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type MassUpdateUsersMutationResult = NonNullable<Awaited<ReturnType<typeof massUpdateUsers>>>;
export type MassUpdateUsersMutationBody = BodyType<UserMassUpdateReq>;
export type MassUpdateUsersMutationError = ErrorType<unknown>;

export const useMassUpdateUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof massUpdateUsers>>, TError, { data: BodyType<UserMassUpdateReq> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof massUpdateUsers>>,
	TError,
	{ data: BodyType<UserMassUpdateReq> },
	TContext
> => {

	const mutationOptions = getMassUpdateUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
export const getMeAsUserOld = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadUser>(
		{ url: "/api/users/me", method: "GET", signal
		}
	);
};
  

export const getGetMeAsUserOldQueryKey = () => {
	return ["/api/users/me"] as const;
};

    
export const getGetMeAsUserOldQueryOptions = <TData = Awaited<ReturnType<typeof getMeAsUserOld>>, TError = ErrorType<unknown>>(options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetMeAsUserOldQueryKey();

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMeAsUserOld>>> = ({ signal }) => getMeAsUserOld(signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData> & { queryKey: QueryKey };
};

export type GetMeAsUserOldQueryResult = NonNullable<Awaited<ReturnType<typeof getMeAsUserOld>>>;
export type GetMeAsUserOldQueryError = ErrorType<unknown>;


export function useGetMeAsUserOld<TData = Awaited<ReturnType<typeof getMeAsUserOld>>, TError = ErrorType<unknown>>(
	options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMeAsUserOld>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMeAsUserOld<TData = Awaited<ReturnType<typeof getMeAsUserOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getMeAsUserOld>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMeAsUserOld<TData = Awaited<ReturnType<typeof getMeAsUserOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMeAsUserOld<TData = Awaited<ReturnType<typeof getMeAsUserOld>>, TError = ErrorType<unknown>>(
	options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMeAsUserOld>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetMeAsUserOldQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Delete
 */
export const deleteUsers = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/users/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUsers>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUsers>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUsers>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteUsers(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteUsersMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUsers>>>;
    
export type DeleteUsersMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUsers>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteUsers>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneUsers = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadUser>(
		{ url: `/api/users/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneUsersQueryKey = (id: number) => {
	return [`/api/users/${id}`] as const;
};

    
export const getGetOneUsersQueryOptions = <TData = Awaited<ReturnType<typeof getOneUsers>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneUsersQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneUsers>>> = ({ signal }) => getOneUsers(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getOneUsers>>>;
export type GetOneUsersQueryError = ErrorType<unknown>;


export function useGetOneUsers<TData = Awaited<ReturnType<typeof getOneUsers>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneUsers>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneUsers<TData = Awaited<ReturnType<typeof getOneUsers>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneUsers>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneUsers<TData = Awaited<ReturnType<typeof getOneUsers>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneUsers<TData = Awaited<ReturnType<typeof getOneUsers>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneUsers>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneUsersQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateUsers = (
	id: number,
	updateUsersBody: BodyType<UpdateUsersBody>
) => {
      
      
	return customInstance<PayloadUser>(
		{ url: `/api/users/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: updateUsersBody
		}
	);
};
  


export const getUpdateUsersMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUsers>>, TError, { id: number;data: BodyType<UpdateUsersBody> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateUsers>>, TError, { id: number;data: BodyType<UpdateUsersBody> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUsers>>, { id: number;data: BodyType<UpdateUsersBody> }> = (props) => {
		const { id, data } = props ?? {};

		return updateUsers(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateUsersMutationResult = NonNullable<Awaited<ReturnType<typeof updateUsers>>>;
export type UpdateUsersMutationBody = BodyType<UpdateUsersBody>;
export type UpdateUsersMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateUsers = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateUsers>>, TError, { id: number;data: BodyType<UpdateUsersBody> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateUsers>>,
	TError,
	{ id: number;data: BodyType<UpdateUsersBody> },
	TContext
> => {

	const mutationOptions = getUpdateUsersMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get all
 */
export const getAllViews = (
	params?: GetAllViewsParams,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadCollectionView>(
		{ url: "/api/views",
			method: "GET",
			params,
			signal
		}
	);
};
  

export const getGetAllViewsQueryKey = (params?: GetAllViewsParams) => {
	return ["/api/views", ...(params ? [params] : [])] as const;
};

    
export const getGetAllViewsQueryOptions = <TData = Awaited<ReturnType<typeof getAllViews>>, TError = ErrorType<unknown>>(params?: GetAllViewsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAllViewsQueryKey(params);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllViews>>> = ({ signal }) => getAllViews(params, signal);

      

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData> & { queryKey: QueryKey };
};

export type GetAllViewsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllViews>>>;
export type GetAllViewsQueryError = ErrorType<unknown>;


export function useGetAllViews<TData = Awaited<ReturnType<typeof getAllViews>>, TError = ErrorType<unknown>>(
	params: undefined | GetAllViewsParams, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllViews>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllViews<TData = Awaited<ReturnType<typeof getAllViews>>, TError = ErrorType<unknown>>(
	params?: GetAllViewsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getAllViews>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetAllViews<TData = Awaited<ReturnType<typeof getAllViews>>, TError = ErrorType<unknown>>(
	params?: GetAllViewsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all
 */

export function useGetAllViews<TData = Awaited<ReturnType<typeof getAllViews>>, TError = ErrorType<unknown>>(
	params?: GetAllViewsParams, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllViews>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetAllViewsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Check if exists
 */
export const existsViews = (
    
	signal?: AbortSignal
) => {
      
      
	return customInstance<void>(
		{ url: "/api/views", method: "HEAD", signal
		}
	);
};
  


export const getExistsViewsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsViews>>, TError, void, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof existsViews>>, TError, void, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof existsViews>>, void> = () => {
          

		return existsViews();
	};
        


	return { mutationFn, ...mutationOptions };
};

export type ExistsViewsMutationResult = NonNullable<Awaited<ReturnType<typeof existsViews>>>;
    
export type ExistsViewsMutationError = ErrorType<unknown>;

/**
 * @summary Check if exists
 */
export const useExistsViews = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof existsViews>>, TError, void, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof existsViews>>,
	TError,
	void,
	TContext
> => {

	const mutationOptions = getExistsViewsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Create
 */
export const createViews = (
	view: BodyType<View>
) => {
      
      
	return customInstance<PayloadView>(
		{ url: "/api/views",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			data: view
		}
	);
};
  


export const getCreateViewsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createViews>>, TError, { data: BodyType<View> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof createViews>>, TError, { data: BodyType<View> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof createViews>>, { data: BodyType<View> }> = (props) => {
		const { data } = props ?? {};

		return createViews(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type CreateViewsMutationResult = NonNullable<Awaited<ReturnType<typeof createViews>>>;
export type CreateViewsMutationBody = BodyType<View>;
export type CreateViewsMutationError = ErrorType<unknown>;

/**
 * @summary Create
 */
export const useCreateViews = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof createViews>>, TError, { data: BodyType<View> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof createViews>>,
	TError,
	{ data: BodyType<View> },
	TContext
> => {

	const mutationOptions = getCreateViewsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Bulk delete
 */
export const bulkDeleteViews = (
	bulkDeleteViewsBody: BodyType<number[]>
) => {
      
      
	return customInstance<void>(
		{ url: "/api/views/bulk_delete",
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: bulkDeleteViewsBody
		}
	);
};
  


export const getBulkDeleteViewsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteViews>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteViews>>, TError, { data: BodyType<number[]> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteViews>>, { data: BodyType<number[]> }> = (props) => {
		const { data } = props ?? {};

		return bulkDeleteViews(data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type BulkDeleteViewsMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteViews>>>;
export type BulkDeleteViewsMutationBody = BodyType<number[]>;
export type BulkDeleteViewsMutationError = ErrorType<unknown>;

/**
 * @summary Bulk delete
 */
export const useBulkDeleteViews = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteViews>>, TError, { data: BodyType<number[]> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof bulkDeleteViews>>,
	TError,
	{ data: BodyType<number[]> },
	TContext
> => {

	const mutationOptions = getBulkDeleteViewsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Delete
 */
export const deleteViews = (
	id: number
) => {
      
      
	return customInstance<void>(
		{ url: `/api/views/${encodeURIComponent(String(id))}`, method: "DELETE"
		}
	);
};
  


export const getDeleteViewsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteViews>>, TError, { id: number }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof deleteViews>>, TError, { id: number }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteViews>>, { id: number }> = (props) => {
		const { id } = props ?? {};

		return deleteViews(id);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type DeleteViewsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteViews>>>;
    
export type DeleteViewsMutationError = ErrorType<unknown>;

/**
 * @summary Delete
 */
export const useDeleteViews = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteViews>>, TError, { id: number }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof deleteViews>>,
	TError,
	{ id: number },
	TContext
> => {

	const mutationOptions = getDeleteViewsMutationOptions(options);

	return useMutation(mutationOptions);
};
    
/**
 * @summary Get one
 */
export const getOneViews = (
	id: number,
	signal?: AbortSignal
) => {
      
      
	return customInstance<PayloadView>(
		{ url: `/api/views/${encodeURIComponent(String(id))}`, method: "GET", signal
		}
	);
};
  

export const getGetOneViewsQueryKey = (id: number) => {
	return [`/api/views/${id}`] as const;
};

    
export const getGetOneViewsQueryOptions = <TData = Awaited<ReturnType<typeof getOneViews>>, TError = ErrorType<unknown>>(id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData>> }
) => {

	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetOneViewsQueryKey(id);

  

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneViews>>> = ({ signal }) => getOneViews(id, signal);

      

	return { queryKey, queryFn, enabled: Boolean(id), ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData> & { queryKey: QueryKey };
};

export type GetOneViewsQueryResult = NonNullable<Awaited<ReturnType<typeof getOneViews>>>;
export type GetOneViewsQueryError = ErrorType<unknown>;


export function useGetOneViews<TData = Awaited<ReturnType<typeof getOneViews>>, TError = ErrorType<unknown>>(
	id: number, options: { query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData>> & Pick<
		DefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneViews>>,
			TError,
			TData
		>, "initialData"
	>; }

): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneViews<TData = Awaited<ReturnType<typeof getOneViews>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData>> & Pick<
		UndefinedInitialDataOptions<
			Awaited<ReturnType<typeof getOneViews>>,
			TError,
			TData
		>, "initialData"
	>; }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetOneViews<TData = Awaited<ReturnType<typeof getOneViews>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get one
 */

export function useGetOneViews<TData = Awaited<ReturnType<typeof getOneViews>>, TError = ErrorType<unknown>>(
	id: number, options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOneViews>>, TError, TData>> }

): UseQueryResult<TData, TError> & { queryKey: QueryKey } {

	const queryOptions = getGetOneViewsQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
}



/**
 * @summary Update
 */
export const updateViews = (
	id: number,
	view: BodyType<View>
) => {
      
      
	return customInstance<PayloadView>(
		{ url: `/api/views/${encodeURIComponent(String(id))}`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			data: view
		}
	);
};
  


export const getUpdateViewsMutationOptions = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateViews>>, TError, { id: number;data: BodyType<View> }, TContext> }
): UseMutationOptions<Awaited<ReturnType<typeof updateViews>>, TError, { id: number;data: BodyType<View> }, TContext> => {
	const { mutation: mutationOptions } = options ?? {};
      


	const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateViews>>, { id: number;data: BodyType<View> }> = (props) => {
		const { id, data } = props ?? {};

		return updateViews(id, data);
	};
        


	return { mutationFn, ...mutationOptions };
};

export type UpdateViewsMutationResult = NonNullable<Awaited<ReturnType<typeof updateViews>>>;
export type UpdateViewsMutationBody = BodyType<View>;
export type UpdateViewsMutationError = ErrorType<unknown>;

/**
 * @summary Update
 */
export const useUpdateViews = <TError = ErrorType<unknown>,
	TContext = unknown>(options?: { mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateViews>>, TError, { id: number;data: BodyType<View> }, TContext> }
): UseMutationResult<
	Awaited<ReturnType<typeof updateViews>>,
	TError,
	{ id: number;data: BodyType<View> },
	TContext
> => {

	const mutationOptions = getUpdateViewsMutationOptions(options);

	return useMutation(mutationOptions);
};
