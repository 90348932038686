import { TextField, TextFieldProps } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { Except } from "type-fest";

type W_TextFieldProps<T extends FieldValues> = Except<TextFieldProps, "name"> & {
	control: Control<T>;
	name: FieldPath<T>;
};

export function W_TextField<T extends FieldValues>(props: W_TextFieldProps<T>) {
	const rules: RegisterOptions<T> = {}; // https://react-hook-form.com/api/useform/register

	if (props.required) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.name}
			rules={rules}
			render={({
				field: { onChange, onBlur, value },
				fieldState: { error },
			}) =>
				<TextField
					{...props}
					onChange={onChange}
					value={value}
					error={error != null}
					onBlur={newValue => {
						onBlur();

						// Use the trimmed value.
						onChange(newValue);
					}}
					helperText={error?.message != null ? error?.message : props.helperText}
				/>
			}
		/>
	);
}
