import { CollisionNote } from "../../../agent-app/src/Api/genApi.schemas";
import { EmailSecurity, Pagination, AgentStatus, AutoAssignMethod, CustomFieldEntity, CustomFieldInputType } from "shared/src/Enums/Enums";

// Frontend Model Interfaces

export enum AutomationActionType {
	SET_TEAM = "SET_TEAM",
	SET_AGENT = "SET_AGENT",
	SET_PRIORITY = "SET_PRIORITY",
	SET_STATUS = "SET_STATUS"
}

// TODO: Shorten this to what is actually allowed.
export type AutomationOperator = "eql" | "neql" | "grt" | "les" | "con" | "ncon" | "reg" | "null";

export enum AutomationConditionType {
	DESCRIPTION = "DESCRIPTION",
	NOTE = "NOTE",
	USER = "USER",
	AGENT = "AGENT",
	PRIORITY = "PRIORITY",
	COMPANY = "COMPANY",
	CATEGORY = "CATEGORY"
}

// API Model Interfaces

export interface Action {
	id: number;
	ticketId: number;
	statusId?: number;
	/** True if there is a full note that could be requested. */
	hasExpandedNote?: boolean;
	/** This is the message without any replies or quoted messages. */
	noteBody?: string;
	userId?: number;
	agentId?: number;
	emailSubject?: string;
	sendToUser?: boolean;
	attachments?: Attachment[];
	customCreated?: string;
	isPublic: boolean;
}

export interface Agent extends User {
	status?: AgentStatus;
	jobTitle?: string;
	teams?: AgentTeams[];
	teamIds?: number[];
	isAdmin?: boolean;
	signature?: string;
	prefs: AgentPrefs;
}

interface AgentPrefs {
	hideSidebar?: boolean;
	emailGreeting?: string;

	/** If the agent should be notified for all new user created tickets. */
	enableEmailNotifications?: boolean;
	/** If the agent should be notified if a ticket is assigned to them. */
	enableTicketAssignedNotifications?: boolean;
}

export interface AgentTeams {
	agentId: number;
	teamId: number;
}

export interface Attachment {
	id: number;
	actionId?: number;
	fileNameOriginal?: string;
	fileNameStorage?: string;
	fileSizeBytes?: number;
}

export interface Automation {
	id: number;
	name?: string;
	conditions?: AutomationCondition[];
	actions?: AutomationAction[];
}

export interface AutomationCondition {
	id?: number;
	automationId?: number;
	field?: AutomationConditionType;
	operator?: AutomationOperator;
	value?: string;
}

export interface AutomationAction {
	id?: number;
	automationId?: number;
	action?: AutomationActionType;
	dataInt?: number;
	dataString?: string;
}

export interface CannedReply {
	id: number;
	name?: string;
	description?: string;
	note?: string;
	ownerAgentId?: number;
	allowOwner?: boolean;
	allowTeamId?: number;
	allowAll?: boolean;
	created?: string;
	modified?: string;
}

export interface Category {
	id: number;
	name?: string;
}

export interface Company {
	id: number;
	name?: string;
	disabled?: boolean;
	users?: User[];
	primaryUserId?: number;
	/** The agent assigned to look after this company. */
	accountManagerId?: number;
	description?: string;
	address_line1?: string;
	address_line2?: string;
	address_city?: string;
	address_region?: string;
	address_postcode?: string;
	ticketCount?: number;
	userCount?: number;
	profilePictureData?: string;
	profilePictureMimetype?: string;
	profilePictureFileName?: string;
	/** The domain that unseen email addresses can be matched to. */
	emailDomain?: string;

	customValues?: {
		[key: string]: string;
	};
}

export interface CompanyNote {
	id?: number;
	companyId: number;
	creatorId: number;
	note: string;
	created?: string;
	modified?: string;
}

export interface CustomField {
	id: number;
	name: string;
	entity: CustomFieldEntity;
	displayOrder: number;
	description?: string;
	tooltip?: string;
	label: string;
	isGlobalSearchable: boolean;
	mandatory: boolean;
	hidden: boolean;
	type: CustomFieldInputType;
	options?: CustomFieldOption[];
}

export interface CustomFieldOption {
	id: number;
	customFieldId: number;
	label: string;
}

export interface CustomFieldValuesCompany {
	entityId: number;
	customFieldName: string;
	Value?: string;
}

export interface Channel {
	id: number;
	name?: string;
	teamId?: number;
	disabled?: boolean;
}

export interface ChannelsEmail extends Channel {
	address?: string;
	isGodeskManaged?: boolean;

	imapServer?: string;
	imapPort?: number;
	imapSecurity?: EmailSecurity;
	imapUser?: string;
	/** Only for writing. */
	imapPass?: string;
	/** For reading after post. */
	hasImapPass?: boolean;

	smtpServer?: string;
	smtpPort?: number;
	smtpSecurity?: EmailSecurity;
	smtpUser?: string;
	/** Only for writing. */
	smtpPass?: string;
	/** For reading after post. */
	hasSmtpPass?: boolean;

	isGmail?: boolean;
	hasGmailTokens?: boolean;

	isMicrosoft?: boolean;
	hasMsTokens?: boolean;
	msSubscriptionId?: string;
	msSubscriptionExpires?: string;

	// TODO: Remove.
	/** @deprecated */
	gmailAccessToken?: string;
	/** @deprecated */
	gmailRefreshToken?: string;
	/** @deprecated */
	msAccessToken?: string;
	/** @deprecated */
	msRefreshToken?: string;
}

export interface Email {
	id: number;
	actionId?: number;
	fromPortal?: string;
	from?: string;
	replyToList?: string;
	toList?: string;
	ccList?: string;
	bccList?: string;
	subject?: string;
	messageHtml?: string;
	noteBody?: string;
	attachments?: Attachment[];
	email_message_id?: string;
	email_in_reply_to?: string;
	sendFailed?: boolean;
	sentDate?: string;
}

export interface EmailNotification {
	id: number;
	subject?: string;
	content?: string;
}

export interface FeedItem {
	id: number;
	ticketId: number;
	noteBody?: string;
	agentId?: number;
	userId?: number;
	description?: string;
	customCreated?: string;
}

export interface ReportDashboard {
	createdTickets?: number;
	resolvedTickets?: number;
	unresolvedTickets?: number;
	avgResponseTimeRawMins?: number;
	avgResponseTimeWorkingMins?: number;
	avgFirstResponseTimeRawMins?: number;
	avgFirstResponseTimeWorkingMins?: number;
	avgAgentResponsesPerClosedTicket?: number;
	oneTouchClosedTicketsPercent?: number;
	ticketHourGraph?: number[];
	ticketDayGraph?: number[];
	ticketStatus?: { [index: number]: number };
	ticketPriority?: { [index: number]: number };
}

export interface SimpleReportTable {
	data?: number[][];
}

export interface Kb {
	id: number;
	name: string;
	isPrivate: boolean;
	primaryLanguage: string;
	otherLanguages?: string[];
	faviconFileName?: string;
	faviconFileData?: string;
	faviconFileMimetype?: string;
	logoFileName?: string;
	logoFileData?: string;
	logoFileMimetype?: string;
	bannerFileName?: string;
	bannerFileData?: string;
	bannerFileMimetype?: string;
	sections?: KbFolder[];
	whitelistedCategoryIds?: number[];
	allowUserSignups: boolean;
	allowUserCreatedTickets: boolean;
}

export interface KbFolder {
	id: number;
	kbId: number;
	parentId?: number;
	childFolders?: KbFolder[];
	name: string;
	description?: string;
	articles: KbArticle[];
	pictureData?: string;
	pictureMimetype?: string;
	pictureFileName?: string;
}

export interface KbArticle {
	id: number;
	kbSectionId: number;
	published: boolean;
	/** Total views this article has received. */
	views?: number;
	/** Views this article has received in the last 30 days (including today). */
	viewsLast30Days?: number;
	upvotes?: number;
	downvotes?: number;
	myUpvote?: boolean;
	creatorId?: number;
	creatorName?: string;
	sectionName?: string;
	editorId?: number;
	modified?: string;
	kbId?: number;
	/** Temporary fix to get the article names in the FE. */
	defaultArticleTitle: string;
	articleContent?: kbArticleContent_list[];

	/**
	 * Used as a transient for the form when creating. DO NOT USE DIRECTLY.
	 * @deprecated
	 */
	name?: string;
	/**
	 * Used as a transient for the form when creating. DO NOT USE DIRECTLY.
	 * @deprecated
	 */
	body?: string;
}

interface kbArticleContentId {
	articleId: number;
	language: string;
}

export interface kbArticleContent_list {
	id: kbArticleContentId;
	title: string;
}

export interface kbArticleContent extends kbArticleContent_list {
	body: string;
}

export interface kbMissedSearch {
	term: string;
}

export interface Priority {
	id: number;
	name: string;
}

export interface Search {
	agents?: Agent[];
	agentsPagination?: Pagination;

	companies?: Company[];
	companiesPagination?: Pagination;

	tickets?: Ticket[];
	ticketsPagination?: Pagination;

	users?: User[];
	usersPagination?: Pagination;

	articles?: KbArticle[];
	articlesPagination?: Pagination;

	timeTakenMs: number;
}

export interface Setting {
	key: string;
	value?: string | null;
}

export interface Status {
	id: number;
	name: string;
}

export interface Team {
	id: number;
	name?: string;
	icon?: string;
	description?: string;
	disabled?: boolean;
	agents?: AgentTeams[];
	agentIds?: number[];
	ticketCount?: number;
	agentCount?: number;
	autoAssignMethod?: AutoAssignMethod | null;
	created?: string;
	modified?: string;
}

export interface Ticket {
	id: number;
	description?: string;
	statusId?: number;
	userId?: number;
	slaId?: number;
	responseDue?: string;
	resolutionDue?: string;
	priorityId?: number;
	teamId?: number;
	agentId?: number;
	categoryId?: number;
	channelId?: number;
	ticketread?: boolean;
	deleted?: boolean;
	actions?: Action[];
	cc?: string[];
	bcc?: string[];
	customCreated?: string;
	ticketHistory?: TicketHistory[];
	mergedIntoTicket?: Ticket;
	mergedTickets?: Ticket[];
	/** See Ticket_List in BE. */
	firstUserActionNote?: string;
	collisionNotes?: CollisionNote[];
	companyId?: number;
}

/** Unused. Should probably use this for type safety. */
export interface Ticket_List {
	id: number;
	agentId: number;
	categoryId: number;
	customCreated: string;
	description: string;
	priorityId: number;
	slaId: number;
	statusId: number;
	teamId: number;
	userId: number;
}

export interface TicketCounts {
	all: number;
	mine: number;
	unresolved: number;
	statusOpen: number;
	statusUser: number;
	statusClosed: number;
	agentCounts: { [key: number]: number };
	viewCounts: { [key: number]: number };
	teams: TeamTicketCounts[];
}

export interface TeamTicketCounts {
	teamId: number;
	total: number;
	unassigned: number;
	agentCounts: { [key: number]: number };
}

export interface TicketHistory {
	id: number;
	description?: string;
	userId?: number;
	statusId?: number;
	customCreated?: string;
}

export interface User {
	id: number;
	name?: string;
	email?: string;
	password?: string;
	companyId?: number;
	phoneNumber?: string;
	disabled?: boolean;
	deleted?: boolean;
	profilePictureData?: string;
	profilePictureMimetype?: string;
	profilePictureFileName?: string;
	portalUser?: boolean;
	portalInvitePending?: boolean;
	kbArticleFavourites?: KbArticleFavourite[];
	isAgent?: boolean;
	created?: string;
	modified?: string;
	isPrimaryContact?: boolean;
}

export interface KbArticleFavourite {
	userId: number;
	kbArticleId: number;
}

export type MetricEventProperties = { [key: string]: string | number | boolean | undefined | null };

/** This is used for the frontend to send the backend events for mixpanel. */
export interface MetricEvent {
	name: string;
	properties: string; // This will be deserialised into JSON in the BE.
}
