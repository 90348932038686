import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Stack } from "@mui/material";
import { Agent } from "@/Api/genApi.schemas";
import { getGetOneAgentsQueryKey, useUpdateAgents } from "@/Api/genApi";
import { SaveBar } from "@/Fragments/EntityViewer/SaveBar";
import { queryClient } from "@/Api/UseQueryProvider";
import { useAgent } from "@/Hooks/useAgent";
import { getImageUploadHandler } from "@/Components/FormComponents/HtmlEditor/imageUploadHandler";
import { useCache } from "@/Hooks/useCache";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { W_Tickbox } from "@shared/Components/FormComponents/Tickbox/W_Tickbox";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import { W_TextField } from "@shared/Components/FormComponents/W_TextField/W_TextField";

interface AgentViewerNewFormProps {
	id: number;
	defaultData: Agent;
}

export function AgentViewerNewForm(props: AgentViewerNewFormProps) {
	const updateAgentReq = useUpdateAgents();
	const agent = useAgent();
	const { cache } = useCache();

	const { control, handleSubmit, reset, formState, watch, setValue } = useForm<Agent>({ defaultValues: props.defaultData });

	const isAdmin = agent?.isAdmin ?? false;
	const canEdit = isAdmin || agent?.id == props.id;

	async function formSubmit(agent: Agent) {
		await updateAgentReq.mutateAsync({ id: props.id, data: agent }, {
			onSuccess: data => {
				toast.success("Updated.");

				if (data.data != null) {
					reset(data.data);
				}

				const queryKey = getGetOneAgentsQueryKey(props.id);
				queryClient.invalidateQueries({ queryKey: queryKey });
			},
			onError: error => toast.error("Could not update agent: " + error.errorMsg)
		});
	}

	return (
		<>
			<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={() => {}} />

			<div className="overflow-auto ">
				<FormParent
					title={props.defaultData.name}
					description="Agent settings"
				>

					{/* <FormError error={formError} /> */}

					<FormSection title="Details">
						<Stack spacing={3}>
							<W_TextField
								control={control}
								name="name"
								label="Name"
								required
							/>

							<W_TextField
								control={control}
								name="email"
								label="Email"
								autoComplete="off"
								required
							/>

							<W_TextField
								control={control}
								name="jobTitle"
								label="Job Title"
							/>

							<W_TextField
								control={control}
								name="phoneNumber"
								label="Phone number"
							/>
						</Stack>

						<W_TextInput
							control={control}
							dataname="name"
							label="Name"
							mandatory
							autoFocus
						/>
						<W_TextInput
							control={control}
							dataname="email"
							label="Email"
							mandatory
							type={InputTypes.Email}
							autocomplete="off"
						/>

						<W_TextInput control={control} dataname="jobTitle" label="Job Title" />
						<W_TextInput control={control} dataname="phoneNumber" label="Phone number" type={InputTypes.Tel} />
					</FormSection>

					{isAdmin &&
						<FormSection title="Permissions">
							<W_Tickbox control={control} dataname="isAdmin" valueName="isAdmin" label="Administrator" />
						</FormSection>}

					<FormSection
						title="Email greeting and signature"
						className="w-[50rem]"
					>
						<TextInput
							dataname=""
							label="Greeting"
							placeholder="Hi $name,"
							toolTip="This will form the first line of your email responses. Set to blank to skip this option. You can also use '$name' to pull in the user's name."
							value={watch("prefs.emailGreeting") === undefined ? "Hi $name," : watch("prefs.emailGreeting")}
							onChange={(value) => {setValue("prefs.emailGreeting", value);}}
						/>

						<W_HtmlEditor
							control={control}
							dataname="signature"
							label="Email Signature"
							agentFeatures
							handleCancel={() => {}}
							handleSubmit={(note) => {
								setValue("signature", note);
								handleSubmit(formSubmit)();
							}}
							cannedReplies={cache.CannedReplies}
							imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
						/>
					</FormSection>
				</FormParent>
			</div>

			<SaveBar formState={formState} onSave={async () => handleSubmit(formSubmit)()} onCancel={reset} />
		</>
	);
}
